import React, { useState, useEffect, useRef, useContext } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import AuthContext from "../AuthProvider";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProductService } from "../service/ProductService";

import { validateUrl } from "../service/rejexValidateion";
import { FileUpload } from "primereact/fileupload";
import { CSVLink } from "react-csv";
import axios from "axios";
import LoadingSpinner from "../service/LoadingSpinner";

const apikey = process.env.REACT_APP_KEY;

const SocialMediaList = () => {
    document.title = "Social Media";

    const { checkRoles, roles } = useContext(AuthContext);

    const [file, setFile] = useState("");
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");

    const [errFile, setErrFile] = useState("");
    const [errName, setErrName] = useState("");
    const [errUrl, setErrUrl] = useState("");

    const [updateId, setUpdateId] = useState("");
    const [updateFile, setUpdateFile] = useState("");
    const [updateName, setUpdateName] = useState("");
    const [updateUrl, setUpdateUrl] = useState("");

    const [errUpdateFile, setErrUpdateFile] = useState("");
    const [errUpdateName, setErrUpdateName] = useState("");
    const [errUpdateUrl, setErrUpdateUrl] = useState("");

    const [deleteId, setDeleteId] = useState("");

    const [deleteImgPath, setDeleteImgPath] = useState("");
    const [deleteUpdateImfPath, setDeleteUpdateImgPath] = useState("");

    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [updateProductDialog, setUpdateProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const [csvAllData, setCsvAllData] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // const productService = new ProductService();
        // productService.getProducts().then(data => setProducts(data));
        getSocialMediaList();
    }, []);

    const getSocialMediaList = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "walletSocialMedia", submethod: "get", key: apikey }).then((res) => {
            if (res?.data?.error) {
                // toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.error, life: 3000 });
            } else {
                // toast.current.show({ severity: 'success', summary: 'Successful', detail: res.data.success, life: 3000 });
                // console.log(res.data);
                setProducts(res.data);
                setLoading(false);
                var csvData = new Array();
                for (let i = 0; i < res.data.length; i++) {
                    csvData.push({ ind: res.data[i].id, logo: res.data[i].logo, name: res.data[i].name, url: res.data[i].url });
                }
                setCsvAllData(csvData);
            }
        });
    };

    const csvReport = {
        filename: "Scocial-Media-list.csv",
        data: csvAllData,
    };

    const handleUploadChange = (e) => {
        // console.log("e.target.files[0]", e.files[0].name);
        setFile(e.files[0]);
    };

    const handleEditUploadChange = (e) => {
        // console.log("e.target.files[0]", e.files[0].name);
        setUpdateFile(e.files[0]);
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };

    const hideDialog = () => {
        setName("");
        setUrl("");
        setErrName("");
        setErrUrl("");
        setSubmitted(false);
        setProductDialog(false);
    };

    const updateHideDialog = () => {
        setUpdateName("");
        setUpdateUrl("");
        setErrUpdateName("");
        setErrUpdateUrl("");
        setSubmitted(false);
        setUpdateProductDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = async () => {
        // setSubmitted(true);

        // if (product.name.trim()) {
        //     let _products = [...products];
        //     let _product = { ...product };
        //     if (product.id) {
        //         const index = findIndexById(product.id);

        //         _products[index] = _product;
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
        //     }
        //     else {
        //         _product.id = createId();
        //         _product.image = 'product-placeholder.svg';
        //         _products.push(_product);
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
        //     }

        //     setProducts(_products);
        //     setProductDialog(false);
        //     setProduct(emptyProduct);
        // }

        const formData = new FormData();
        //this will pass as file data
        //now all the remain data pass as body data
        formData.append("method", "walletSocialMedia");
        formData.append("submethod", "insert");
        formData.append("file", file);
        formData.append("name", name);
        formData.append("url", url);
        formData.append("key", apikey);

        let temp = 1;
        if (!validateUrl(url)) {
            setErrUrl("invalid url.");
            temp = 0;
        } else {
            setErrUrl("");
        }
        if (temp === 1) {
            await axios.post(process.env.REACT_APP_API_URL, formData).then((res) => {
                if (res.data.error) {
                    toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                } else if (res?.data?.success) {
                    setName("");
                    setUrl("");
                    getSocialMediaList();
                    setProductDialog(false);
                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
                }
            });
        }
    };
    const updateProduct = async () => {
        const formData = new FormData();
        //this will pass as file data
        //now all the remain data pass as body data
        formData.append("method", "walletSocialMedia");
        formData.append("submethod", "update");
        formData.append("id", updateId);
        formData.append("file", updateFile);
        formData.append("name", updateName);
        formData.append("url", updateUrl);
        formData.append("key", apikey);
        formData.append("deleteimg", deleteUpdateImfPath);
        let temp = 1;
        if (!validateUrl(updateUrl)) {
            setErrUpdateUrl("invalid url.");
            temp = 0;
        } else {
            setErrUpdateUrl("");
        }
        if (temp === 1) {
            await axios.post(process.env.REACT_APP_API_URL, formData).then((res) => {
                if (res?.data?.error) {
                    toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                } else if (res?.data?.success) {
                    setUpdateId("");
                    setUpdateName("");
                    setUpdateUrl("");
                    getSocialMediaList();
                    setUpdateProductDialog(false);
                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
                }
            });
        }
    };

    const editProduct = (rowData) => {
        //setProduct({ ...product });
        setUpdateId(rowData._id);
        setUpdateName(rowData.name);
        setUpdateUrl(rowData.url);
        setUpdateProductDialog(true);
        setDeleteUpdateImgPath(rowData.logo);
    };

    const confirmDeleteProduct = (rowData) => {
        // setProduct(product);
        setDeleteId(rowData._id);
        setDeleteProductDialog(true);
        setDeleteImgPath(rowData.logo);
    };

    const deleteProduct = async () => {
        // let _products = products.filter(val => val.id !== product.id);
        // setProducts(_products);
        // setDeleteProductDialog(false);
        // setProduct(emptyProduct);
        // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });

        await axios.post(process.env.REACT_APP_API_URL, { method: "walletSocialMedia", submethod: "delete", key: apikey, id: deleteId, image: deleteImgPath }).then((res) => {
            if (res?.data?.error) {
                toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
            } else if (res?.data?.success) {
                getSocialMediaList();
                setDeleteProductDialog(false);
                toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
            }
        });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Add Social Media" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.id}
            </>
        );
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={rowData.logo ? process.env.REACT_APP_SOCIAL_MEDIA_LOGO + rowData.logo : "public/images/users/user-6.jpg"} alt={rowData.logo} className="shadow-2 imground" width="50" />
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    };
    const urlBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                <a href={"https://" + rowData.url} target="_blank">
                    {"https://" + rowData.url}
                </a>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Social Media List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                {csvAllData ? (
                    <CSVLink className="p-button p-component p-button-secondary p-2 m-1" {...csvReport}>
                        .CSV
                    </CSVLink>
                ) : (
                    ""
                )}
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const updateProductDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={updateHideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return !checkRoles || checkRoles?.filter((item) => item.id === "0002_002").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <div className="grid SocialMediaList-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                        <DataTable
                            ref={dt}
                            value={products}
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={globalFilter}
                            emptyMessage={loading ? <LoadingSpinner /> : "data not found."}
                            header={header}
                            responsiveLayout="scroll"
                        >
                            <Column field="id" header="#" sortable body={codeBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column header="Logo" body={imageBodyTemplate} headerStyle={{ width: "14%", minWidth: "7rem" }}></Column>
                            <Column field="social_media" header="Social Media" sortable body={nameBodyTemplate} headerStyle={{ width: "14%", minWidth: "20rem" }}></Column>
                            <Column field="link" header="Link" sortable body={urlBodyTemplate} headerStyle={{ width: "14%", minWidth: "20rem" }}></Column>
                            <Column field="action" header="Action" body={actionBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        </DataTable>

                        {/* add social media dialog */}
                        <Dialog visible={productDialog} style={{ width: "450px" }} header="Social Media" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                            <div className="field">
                                <label htmlFor="image">Logo *</label>
                                <div className="card">
                                    {/* <FileUpload mode="basic" name="image" accept="image/*" maxFileSize={2000000} onUpload={onUpload} /> */}
                                    <FileUpload mode="basic" name="image" customUpload={true} url="./assets" auto="true" accept="image/*" maxFileSize={2000000} uploadHandler={handleUploadChange} />
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="name">Name *</label>
                                <InputText
                                    id="name"
                                    value={name}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                                {errName && <p className="p-1 err-mess">{errName}</p>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">Website *</label>
                                <InputText
                                    id="website"
                                    value={url}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setUrl(e.target.value);
                                    }}
                                />
                                {errUrl && <p className="p-1 err-mess">{errUrl}</p>}
                            </div>
                        </Dialog>

                        <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {product && <span>Are you sure you want to delete?</span>}
                            </div>
                        </Dialog>

                        {/* edit social media dialog */}
                        <Dialog visible={updateProductDialog} style={{ width: "450px" }} header="Edit Social Media" modal className="p-fluid" footer={updateProductDialogFooter} onHide={updateHideDialog}>
                            {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                            <div className="field">
                                <label htmlFor="image">Logo *</label>
                                <div className="card">
                                    {/* <FileUpload mode="basic" name="image" accept="image/*" maxFileSize={2000000} onUpload={onUpload} /> */}
                                    <FileUpload mode="basic" name="image" customUpload={true} url="./assets" auto="true" accept="image/*" maxFileSize={2000000} uploadHandler={handleEditUploadChange} />
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="name">Name *</label>
                                <InputText
                                    id="name"
                                    value={updateName}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setUpdateName(e.target.value);
                                    }}
                                />
                                {errUpdateName && <p className="p-1 err-mess">{errUpdateName}</p>}
                            </div>
                            <div className="field">
                                <label htmlFor="name">Website *</label>
                                <InputText
                                    id="website"
                                    value={updateUrl}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setUpdateUrl(e.target.value);
                                    }}
                                />
                                {errUpdateUrl && <p className="p-1 err-mess">{errUpdateUrl}</p>}
                            </div>
                        </Dialog>

                        <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {product && <span>Are you sure you want to delete?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SocialMediaList, comparisonFn);
