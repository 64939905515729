const validateUrl = (value) => {
    let urlformat = /(www\.)?[a-z0-9\-\.]{1,}\.[a-zA-Z1-9\_\-\:\\\/]{2,}$/;
    // let urlformat = /^(ftp|http|https?):\/\/+(www\.)?[a-z0-9\-\.]{2,}\.[a-zA-Z1-9\_\-\:\\]{2,}$/;
    return urlformat.test(value);
}

const validateEmail = (value) => {
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    return mailformat.test(value);
}

export {validateUrl, validateEmail};