import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";

const Pagination = ({ currentPage, setCurrentPage, pageSize, setPageSize, pages }) => {
    const [tabData, setTabData] = useState([
        { name: "10", value: 10 },
        { name: "25", value: 25 },
        { name: "50", value: 50 },
        { name: "100", value: 100 },
    ]);
    const [tabId, setTabId] = useState(10);
    return (
        <div className="pgain-container">
            <div className="pagination-container">
                <button
                    className="page-btn"
                    onClick={() => {
                        setCurrentPage(1);
                    }}
                    disabled={currentPage <= 1}
                >
                    <i class="pi pi-angle-double-left"></i>
                </button>
                <button
                    className="page-btn"
                    onClick={() => {
                        setCurrentPage(currentPage - 1);
                    }}
                    disabled={currentPage <= 1}
                >
                    <i class="pi pi-angle-left"></i>
                </button>
                <button className="page-btn-center">{currentPage}</button>
                <button
                    className="page-btn"
                    onClick={() => {
                        setCurrentPage(currentPage + 1);
                    }}
                    disabled={Number(pages) <= currentPage}
                >
                    <i class="pi pi-angle-right"></i>
                </button>
                <button
                    className="page-btn"
                    onClick={() => {
                        setCurrentPage(Number(pages));
                    }}
                    disabled={Number(pages) <= currentPage}
                >
                    <i class="pi pi-angle-double-right"></i>
                </button>
            </div>
            <div className="pagesize-container">
                <div>
                    <Dropdown
                        id="state"
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(e.value);
                        }}
                        options={tabData}
                        optionLabel="name"
                        optionValue="value"
                        placeholder="Select Token Type"
                    ></Dropdown>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
