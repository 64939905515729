import React, { useState, useEffect, useRef, useContext } from "react";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../AuthProvider";
import { TabView, TabPanel } from "primereact/tabview";
import { validateUrl, validateEmail } from "../service/rejexValidateion";
import axios from "axios";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import EmptyDataMessage from "./common/EmptyDataMessage";
import LoadingSpinner from "../service/LoadingSpinner";

const apikey = process.env.REACT_APP_KEY;

const PublicNameTagList = () => {
    document.title = "Public Name Tags";

    const { checkRoles, verifyAndGetUser } = useContext(AuthContext);
    const { encuserpass } = verifyAndGetUser();

    const [activeIndex, setActiveIndex] = useState(0);

    // Pubmic name tag data

    const [updateName, setUpdateName] = useState("");
    const [updateEmail, setUpdateEmail] = useState("");
    const [updateCompanyName, setUpdateCompanyName] = useState("");
    const [updateCompanyWebsite, setUpdateCompanyWebsite] = useState("");
    const [updateAddressStatus, setUpdateAddressStatus] = useState("");
    const [updateAddressName, setUpdateAddressName] = useState("");
    const [updateAdditionalComment, setUpdateAdditionalComment] = useState("");

    const [updateErrName, setUpdateErrName] = useState("");
    const [updateErrEmail, setUpdateErrEmail] = useState("");
    const [updateErrCompanyName, setUpdateErrCompanyName] = useState("");
    const [updateErrCompanyWebsite, setUpdateErrCompanyWebsite] = useState("");
    const [updateErrAddressStaus, setUpdateErrAddressStatus] = useState("");
    const [updateErrAddressName, setUpdateErrAddressName] = useState("");
    const [updateErrAdditionalComment, setUpdateErrAdditionalComment] = useState("");

    const [dialogMessage, setDialogMessage] = useState("");

    const [spinner, setSpinner] = useState(true);

    const [verified, setVerified] = useState(null);
    const [verifiedCurrentPage, setVerifiedCurrentPage] = useState(1);
    const [verifiedPageSize, setVerifiedPageSize] = useState(10);
    const [verifiedPages, setVerifiedPages] = useState(0);

    const [unverified, setUnverified] = useState([]);
    const [unverifiedCurrentPage, setUnverifiedCurrentPage] = useState(1);
    const [unverifiedPageSize, setUnverifiedPageSize] = useState(10);
    const [unverifiedPages, setUnverifiedPages] = useState(0);

    const [rejected, setRejected] = useState([]);
    const [rejectedCurrentpage, setRejectedCurrentpage] = useState(1);
    const [rejectedPagesize, setRejectedPagesize] = useState(10);
    const [rejectedPages, setRejectedPages] = useState(0);

    // name tag data
    const [contractAddress, setContractAddress] = useState("");
    const [nameTag, setNameTag] = useState("");
    const [website, setWebsite] = useState("");
    const [categoryLabel, setCategoryLabel] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [nameTagStatus, setNameTagStatus] = useState("");

    // name tag errors
    const [errContractAddress, setErrContractAddress] = useState("");
    const [errNameTag, setErrNameTag] = useState("");
    const [errWebsite, setErrWebsite] = useState("");
    const [errCategoryLabel, setErrCategoryLabel] = useState("");
    const [errShortDescription, setErrShortDescription] = useState("");
    const [errNameTagStatus, setErrNameTagStatus] = useState("");

    // update name tag data
    const [updateContractAddress, setUpdateContractAddress] = useState("");
    const [updateNameTag, setUpdateNameTag] = useState("");
    const [updateWebsite, setUpdateWebsite] = useState("");
    const [updateCategoryLabel, setUpdateCategoryLabel] = useState("");
    const [updateShortDescription, setUpdateShortDescription] = useState("");

    // update name tag errors
    const [errUpdateContractAddress, setErrUpdateContractAddress] = useState("");
    const [errUpdateNameTag, setErrUpdateNameTag] = useState("");
    const [errUpdateWebsite, setErrUpdateWebsite] = useState("");
    const [errUpdateCategoryLabel, setErrUpdateCategoryLabel] = useState("");
    const [errUpdateShortDescription, setErrUpdateShortDescription] = useState("");
    const [errUpdateNameTagStatus, setErrUpdateNameTagStatus] = useState("");

    // add name tag states
    // const [contractAddress, setContractAddress] = useState("");
    // const [nameTag]
    const [nameTagId, setNameTagId] = useState("");

    const [deleteId, setDeleteId] = useState("");

    const [actionId, setActionId] = useState("");
    const [deleteSubNameTagId, setDeleteSubNameTagId] = useState("");
    const [updateSubNameTagId, setUpdateSubNameTagId] = useState("");

    useEffect(() => {
        getNameTag();
    }, [verifiedCurrentPage, verifiedPageSize, unverifiedCurrentPage, unverifiedPageSize, rejectedCurrentpage, rejectedPagesize]);

    const getNameTag = () => {
        getVerifiedNameTag();
        getUnverifiedNameTag();
        getRejectedNameTag();
    };

    const getVerifiedNameTag = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/nametag`, {
                params: {
                    status: "verified",
                    limit: verifiedPageSize,
                    page: verifiedCurrentPage,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setVerified(res.data.items);
                    setVerifiedPages(res.data.pages);
                    setSpinner(false);
                }
            });
    };
    const getUnverifiedNameTag = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/nametag`, {
                params: {
                    status: "unverified",
                    limit: unverifiedPageSize,
                    page: unverifiedCurrentPage,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setUnverified(res.data.items);
                    setUnverifiedPages(res.data.pages);
                    setSpinner(false);
                }
            });
    };
    const getRejectedNameTag = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/nametag`, {
                params: {
                    status: "pending",
                    limit: rejectedPagesize,
                    page: rejectedCurrentpage,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setRejected(res.data.items);
                    setRejectedPages(res.data.pages);
                    setSpinner(false);
                }
            });
    };

    const getVerifiedSearch = async (val) => {
        // setSpinner(true);
        // await axios
        //     .get(`${process.env.REACT_APP_API_URL}admin/nametag`, {
        //         params: {
        //             status: "verified",
        //             limit: verifiedPageSize,
        //             page: verifiedCurrentPage,
        //         },
        //         headers: {
        //             etoken: encuserpass,
        //         },
        //     })
        //     .then((res) => {
        //         if (res.data.error) {
        //             console.log("error in getUserList : ", res.data.error);
        //         } else {
        //             setVerified(res.data.items);
        //             setVerifiedPages(res.data.pages);
        //             setSpinner(false);
        //         }
        //     });
    };

    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };

    const [saveProductDialog, setSaveProductDialog] = useState(false);
    const [updateProductDialog, setUpdateProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);

    const [nameTagDialog, setNameTagDialog] = useState(false);
    const [updateNameTagDialog, setUpdateNameTagDialog] = useState(false);

    const [productDialog2, setProductDialog2] = useState(false);
    const [deleteProductDialog2, setDeleteProductDialog2] = useState(false);
    const [deleteNameTagDialog, setDeleteNameTagDialog] = useState(false);

    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const [contractAddId, setContractAddId] = useState("");
    const [editID, seteditID] = useState("");

    const [VerifyStatus, setVerifyStatus] = useState(0);

    const openNew = (contractadd, status) => {
        setDialogMessage(status);
        setContractAddId(contractadd);
        setDeleteProductDialog(true);
    };

    const addressStatusData = [
        { name: "I'm the address owner", code: 1 },
        { name: "I'm a user", code: 2 },
    ];

    const nameTagHideDialog = () => {
        setContractAddress("");
        setNameTag("");
        setWebsite("");
        setCategoryLabel("");
        setShortDescription("");
        setErrContractAddress("");
        setErrNameTag("");
        setErrWebsite("");
        setErrCategoryLabel("");
        setErrShortDescription("");
        setNameTagDialog(false);
    };

    const updateNameTagHideDialog = () => {
        setUpdateContractAddress("");
        setUpdateNameTag("");
        setUpdateWebsite("");
        setUpdateCategoryLabel("");
        setUpdateShortDescription("");
        setErrUpdateContractAddress("");
        setErrUpdateNameTag("");
        setErrUpdateWebsite("");
        setErrUpdateCategoryLabel("");
        setErrUpdateShortDescription("");
        setUpdateNameTagDialog(false);
    };

    const updateHideDialog = () => {
        setUpdateErrName("");
        setUpdateErrEmail("");
        setUpdateErrCompanyName("");
        setUpdateErrCompanyWebsite("");
        setUpdateErrAddressStatus("");
        setUpdateErrAdditionalComment("");
        setUpdateProductDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setDeleteProductDialog2(false);
    };

    const hideDeleteNameTagDialog = () => {
        setDeleteNameTagDialog(false);
    };

    const addNameTag = async () => {
        let temp = 1;
        if (contractAddress.length > 46) {
            setErrContractAddress("Contract address contain lessthan 46 charachters.");
            temp = 0;
        } else {
            setErrContractAddress("");
        }
        if (!validateUrl(website)) {
            setErrWebsite("invalid url.");
            temp = 0;
        } else {
            setErrWebsite("");
        }
        if (temp === 1) {
            await axios
                .post(process.env.REACT_APP_API_URL, { method: "addressNameTag", submethod: "insertNewNameTag", key: apikey, id: nameTagId, nameTag: nameTag, contractAddress: contractAddress, website: website, categoryLevel: categoryLabel, shortDescription: shortDescription, status: 0 })
                .then((res) => {
                    if (res.data) {
                        if (res.data.success) {
                            toast.current.show({ severity: "success", summary: "Successful", detail: "Name Tab saved", life: 3000 });
                            setContractAddress("");
                            setNameTag("");
                            setWebsite("");
                            setCategoryLabel("");
                            setShortDescription("");
                            setErrContractAddress("");
                            setErrNameTag("");
                            setErrWebsite("");
                            setErrCategoryLabel("");
                            setErrShortDescription("");
                            nameTagHideDialog();
                        } else if (res.data.error) {
                            toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                        }
                        getNameTag();
                    }
                });
        }
    };

    const updateProduct = async () => {
        let temp = 1;
        if (!validateEmail(updateEmail)) {
            setUpdateErrEmail("invalid email address.");
            temp = 0;
        } else {
            setUpdateErrEmail("");
        }
        if (!validateUrl(updateCompanyWebsite)) {
            setUpdateErrCompanyWebsite("invalid url.");
            temp = 0;
        } else {
            setUpdateErrCompanyWebsite("");
        }
        if (temp === 1) {
            await axios
                .post(process.env.REACT_APP_API_URL, {
                    method: "addressNameTag",
                    submethod: "update",
                    key: apikey,
                    id: editID,
                    name: updateName,
                    email: updateEmail,
                    companyName: updateCompanyName,
                    companyWebsite: updateCompanyWebsite,
                    addressStaus: updateAddressStatus,
                    additionalComment: updateAdditionalComment,
                })
                .then((res) => {
                    if (res.data) {
                        if (res.data.success) {
                            toast.current.show({ severity: "success", summary: "Successful", detail: "Name Tab Updated", life: 3000 });
                            updateHideDialog();
                            setUpdateErrEmail("");
                            setUpdateErrCompanyName("");
                            setUpdateErrCompanyWebsite("");
                            setUpdateErrAddressStatus("");
                            setUpdateErrAdditionalComment("");
                            setUpdateErrName("");
                        } else if (res.data.error) {
                            toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                        }
                        getNameTag();
                    }
                });
        }
    };

    const updateNameTagProduct = async () => {
        let temp = 1;
        if (updateContractAddress.length > 46) {
            setErrUpdateContractAddress("Contract address contain lessthan 46 charachters.");
            temp = 0;
        } else {
            setErrUpdateContractAddress("");
        }
        if (!validateUrl(updateWebsite)) {
            setErrUpdateWebsite("invalid url.");
            temp = 0;
        } else {
            setErrUpdateWebsite("");
        }
        if (temp === 1) {
            await axios
                .post(process.env.REACT_APP_API_URL, {
                    method: "addressNameTag",
                    submethod: "updateNameTag",
                    key: apikey,
                    id: actionId,
                    subid: updateSubNameTagId,
                    nameTag: updateNameTag,
                    contractAddress: updateContractAddress,
                    website: updateWebsite,
                    categoryLevel: updateCategoryLabel,
                    shortDescription: updateShortDescription,
                })
                .then((res) => {
                    if (res.data) {
                        // console.log("res456 res", res.data);
                        if (res.data.success) {
                            toast.current.show({ severity: "success", summary: "Successful", detail: "Name Tab Updated", life: 3000 });
                            setUpdateContractAddress("");
                            setUpdateNameTag("");
                            setUpdateWebsite("");
                            setUpdateCategoryLabel("");
                            setUpdateShortDescription("");
                            setUpdateSubNameTagId("");
                            setUpdateNameTagDialog(false);
                        } else if (res.data.error) {
                            toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                        }
                        getNameTag();
                    }
                });
        }
    };

    const confirmDeleteProduct = async (status) => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/nametagstatus/${contractAddId}`, {
                params: {
                    status: status,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setDeleteProductDialog(false);
                    getVerifiedNameTag();
                    getUnverifiedNameTag();
                    getRejectedNameTag();
                }
            });
    };

    const deletePublicNameTag = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "addressNameTag", submethod: "delete", key: apikey, id: deleteId }).then((res) => {
            if (res?.data?.error) {
                toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
            } else {
                toast.current.show({ severity: "success", summary: "Success", detail: res.data.success, life: 3000 });
                // console.log(res)
                getNameTag();
                setDeleteProductDialog2(false);
            }
        });
    };

    const deleteNameTagProduct = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/deletenametag/${deleteId}`, {
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setDeleteNameTagDialog(false);
                    getVerifiedNameTag();
                    getUnverifiedNameTag();
                    getRejectedNameTag();
                }
            });
    };

    const verifyActionsBodyTemplate = (rowData) => {
        return (
            <div style={{ display: "flex" }}>
                <Button label="Unverify" className="p-button-danger mr-2" onClick={() => openNew(rowData.contractaddress, "unverify")} />
                <Button label="Pending" className="p-button-info mr-2" onClick={() => openNew(rowData.contractaddress, "pending")} />
            </div>
        );
    };

    const unverifyActionsBodyTemplate = (rowData) => {
        return (
            <div style={{ display: "flex" }}>
                <Button label="Verify" className="p-button-success mr-2" onClick={() => openNew(rowData.contractaddress, "verify")} />
                <Button label="Pending" className="p-button-info mr-2" onClick={() => openNew(rowData.contractaddress, "pending")} />
            </div>
        );
    };

    const rejectedActionsBodyTemplate = (rowData) => {
        return (
            <div style={{ display: "flex" }}>
                <Button label="Verify" className="p-button-success mr-2" onClick={() => openNew(rowData.contractaddress, "verify")} />
                <Button
                    label="Delete"
                    className="p-button-danger mr-2"
                    onClick={() => {
                        setDeleteId(rowData.contractaddress);
                        setDeleteNameTagDialog(true);
                    }}
                />
            </div>
        );
    };
    const userStatusBodyTemplate = (rowData) => {
        let status = rowData?.userstatus;
        return status === 1 || status === "1" ? "I'm the address owner" : status === 2 || status === "2" ? "I'm a user" : "Invalid user status.";
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Link to="/addnametag">
                    <Button label="Add Public Name Tag" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </Link>
            </React.Fragment>
        );
    };

    const nameTagDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={nameTagHideDialog} />
            <Button label="Add" icon="pi pi-check" className="p-button-text" onClick={addNameTag} />
        </>
    );

    const updateNameTagDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={updateNameTagHideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateNameTagProduct} />
        </>
    );
    const updateProductDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={updateHideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            {dialogMessage === "unverify" ? (
                <Button label="Unverify" icon="pi pi-check" className="p-button-text" onClick={() => confirmDeleteProduct("unverified")} />
            ) : dialogMessage === "verify" ? (
                <Button label="Verify" icon="pi pi-check" className="p-button-text" onClick={() => confirmDeleteProduct("verified")} />
            ) : dialogMessage === "pending" ? (
                <Button label="Pending" icon="pi pi-check" className="p-button-text" onClick={() => confirmDeleteProduct("pending")} />
            ) : null}
        </>
    );
    const deleteProductDialogFooter2 = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => deletePublicNameTag()} />
        </>
    );
    const deleteNameTagFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteNameTagDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => deleteNameTagProduct()} />
        </>
    );

    return !checkRoles ? (
        <LoadingSpinner />
    ) : checkRoles?.filter((item) => item.id === "0001_002").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <Toast ref={toast} />
            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Verified">
                    <div className="grid UsersList-demo">
                        <div className="col-12">
                            <div className="card">
                                <PageHeader title="Verified Tokens" handleSearch={getVerifiedSearch} />
                                <DataTable value={verified} dataKey="id" loading={spinner} className="datatable-responsive" emptyMessage="No data found." responsiveLayout="scroll">
                                    <Column field="ind" header="#" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="name" header="Name" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="email" header="Email" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="companyname" header="Company Name" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="companywebsite" header="Company Website" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column header="Address Status" body={userStatusBodyTemplate} headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="comment" header="Additional Comment" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="nametag" header="Name-Tag" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="contractaddress" header="Contract Address" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="website" header="Website" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="categorylabel" header="Category Label" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="shortdescription" header="Short Description" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column header="Actions" body={verifyActionsBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                </DataTable>
                                <Pagination currentPage={verifiedCurrentPage} setCurrentPage={setVerifiedCurrentPage} pageSize={verifiedPageSize} setPageSize={setVerifiedPageSize} pages={verifiedPages} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel className="p-0" header="Unverified">
                    <div className="grid UsersList-demo">
                        <div className="col-12">
                            <div className="card">
                                <PageHeader title="Unverified Tokens" />
                                {/* <div className="table-container">
                                    <table className="table" cellPadding={0} cellSpacing={0}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Company Name</th>
                                                <th>Company Website</th>
                                                <th>Address Status</th>
                                                <th>Additional Comment</th>
                                                <th>Name-Tag</th>
                                                <th>Contract Address</th>
                                                <th>Website</th>
                                                <th>Category Label</th>
                                                <th>Short Description</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {unverified && unverified?.length
                                                ? unverified?.map((item, index) => {
                                                    const ind = (unverifiedCurrentPage * unverifiedPageSize) - unverifiedPageSize + (index + 1);
                                                    return (
                                                        <tr>
                                                            <td>{ind}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.companyname}</td>
                                                            <td>{item.companywebsite}</td>
                                                            <td>{item.userstatus}</td>
                                                            <td>{item.additionalcomment}</td>
                                                            <td>{item.nametag}</td>
                                                            <td>{item.contractaddress}</td>
                                                            <td>{item.website}</td>
                                                            <td>{item.categorylabel}</td>
                                                            <td>{item.shortdescription}</td>
                                                            <td>
                                                                <div style={{ display: "flex" }}>
                                                                    <Button label="Verify" className="p-button-success mr-2" onClick={() => openNew(item.contractaddress, "verify")} />
                                                                    <Button label="Pending" className="p-button-info mr-2" onClick={() => openNew(item.contractaddress, "pending")} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : ""}
                                        </tbody>
                                    </table>
                                    {spinner ? <LoadingSpinner /> : unverified && unverified?.length ? "" : <EmptyDataMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />}
                                </div> */}
                                <DataTable value={unverified} dataKey="id" loading={spinner} className="datatable-responsive" emptyMessage="No data found." responsiveLayout="scroll">
                                    <Column field="ind" header="#" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="name" header="Name" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="email" header="Email" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="companyname" header="Company Name" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="companywebsite" header="Company Website" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column header="Address Status" body={userStatusBodyTemplate} headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="comment" header="Additional Comment" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="nametag" header="Name-Tag" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="contractaddress" header="Contract Address" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="website" header="Website" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="categorylabel" header="Category Label" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="shortdescription" header="Short Description" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column header="Actions" body={unverifyActionsBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                </DataTable>
                                <Pagination currentPage={unverifiedCurrentPage} setCurrentPage={setUnverifiedCurrentPage} pageSize={unverifiedPageSize} setPageSize={setUnverifiedPageSize} pages={unverifiedPages} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Pending">
                    <div className="grid UsersList-demo">
                        <div className="col-12">
                            <div className="card">
                                {/* <div className="table-container">
                                    <table className="table" cellPadding={0} cellSpacing={0}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Company Name</th>
                                                <th>Company Website</th>
                                                <th>Address Status</th>
                                                <th>Additional Comment</th>
                                                <th>Name-Tag</th>
                                                <th>Contract Address</th>
                                                <th>Website</th>
                                                <th>Category Label</th>
                                                <th>Short Description</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rejected && rejected?.length
                                                ? rejected?.map((item, index) => {
                                                    const ind = (rejectedCurrentpage * rejectedCurrentpage) - rejectedCurrentpage + (index + 1);
                                                    return (
                                                        <tr>
                                                            <td>{ind}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.companyname}</td>
                                                            <td>{item.companywebsite}</td>
                                                            <td>{item.userstatus}</td>
                                                            <td>{item.additionalcomment}</td>
                                                            <td>{item.nametag}</td>
                                                            <td>{item.contractaddress}</td>
                                                            <td>{item.website}</td>
                                                            <td>{item.categorylabel}</td>
                                                            <td>{item.shortdescription}</td>
                                                            <td>
                                                                <div style={{ display: "flex" }}>
                                                                    <Button label="Verify" className="p-button-success mr-2" onClick={() => openNew(item.contractaddress, "verify")} />
                                                                    <Button
                                                                        label="Delete"
                                                                        className="p-button-danger mr-2"
                                                                        onClick={() => {
                                                                            setDeleteId(item.contractaddress);
                                                                            setDeleteNameTagDialog(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : ""}
                                        </tbody>
                                    </table>
                                    {spinner ? <LoadingSpinner /> : rejected && rejected?.length ? "" : <EmptyDataMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />}
                                </div> */}
                                <PageHeader title="Rejected Tokns" />
                                <DataTable value={rejected} dataKey="id" loading={spinner} className="datatable-responsive" emptyMessage="No data found." responsiveLayout="scroll">
                                    <Column field="ind" header="#" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="name" header="Name" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="email" header="Email" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="companyname" header="Company Name" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="companywebsite" header="Company Website" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column header="Address Status" body={userStatusBodyTemplate} headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="comment" header="Additional Comment" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="nametag" header="Name-Tag" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="contractaddress" header="Contract Address" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="website" header="Website" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="categorylabel" header="Category Label" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="shortdescription" header="Short Description" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column header="Actions" body={rejectedActionsBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                </DataTable>
                                <Pagination currentPage={rejectedCurrentpage} setCurrentPage={setRejectedCurrentpage} pageSize={rejectedPagesize} setPageSize={setRejectedPagesize} pages={rejectedPages} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </TabView>

            <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Warning" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {/* {product && <span>Are you sure want to {verifySTATUS === "verified" ? "unverify" : "verify"} this Name Tag?</span>} */}
                    {dialogMessage === "verify"
                        ? `Are you sure want to ${dialogMessage} this Name Tag?`
                        : dialogMessage === "unverify"
                        ? `Are you sure want to ${dialogMessage} this Name Tag?`
                        : dialogMessage === "pending"
                        ? `Are you sure want to ${dialogMessage} this Name Tag?`
                        : `Are you sure want to put this Name Tag into ${dialogMessage} list?`}
                </div>
            </Dialog>

            <Dialog visible={deleteNameTagDialog} style={{ width: "450px" }} header="Warning" modal footer={deleteNameTagFooter} onHide={hideDeleteNameTagDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {product && <span>Are you sure want to delete this Name Tag?</span>}
                </div>
            </Dialog>

            {/* update name tag */}
            <Dialog visible={updateProductDialog} style={{ width: "450px" }} header="Public Name Tag" modal className="p-fluid" footer={updateProductDialogFooter} onHide={updateHideDialog}>
                {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                <div className="field">
                    <label htmlFor="name">Name *</label>
                    <InputText
                        id="name"
                        type="name"
                        value={updateName}
                        required
                        onChange={(e) => {
                            setUpdateName(e.target.value);
                        }}
                    />
                    {updateErrName && <p className="p-1 err-mess">{updateErrName}</p>}
                </div>
                <div className="field">
                    <label htmlFor="email">Email *</label>
                    <InputText
                        id="email"
                        type="text"
                        value={updateEmail}
                        required
                        onChange={(e) => {
                            setUpdateEmail(e.target.value);
                        }}
                    />
                    {updateErrEmail && <p className="p-1 err-mess">{updateErrEmail}</p>}
                </div>
                <div className="field">
                    <label htmlFor="company-name">Company Name *</label>
                    <InputText
                        id="company-name"
                        type="text"
                        value={updateCompanyName}
                        required
                        onChange={(e) => {
                            setUpdateCompanyName(e.target.value);
                        }}
                    />
                    {updateErrCompanyName && <p className="p-1 err-mess">{updateErrCompanyName}</p>}
                </div>
                <div className="field">
                    <label htmlFor="company-website">Company Website *</label>
                    <InputText
                        id="company-website"
                        type="text"
                        value={updateCompanyWebsite}
                        required
                        onChange={(e) => {
                            setUpdateCompanyWebsite(e.target.value);
                        }}
                    />
                    {updateErrCompanyWebsite && <p className="p-1 err-mess">{updateErrCompanyWebsite}</p>}
                </div>
                <div className="field">
                    <label htmlFor="state">Is this an address owner or a user submission? *</label>
                    <Dropdown
                        id="state"
                        value={updateAddressStatus}
                        onChange={(e) => {
                            setUpdateAddressStatus(e.value);
                            // setUpdateAddressName(e.value);
                        }}
                        options={addressStatusData}
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Select field"
                    ></Dropdown>
                    {/* <Dropdown id="state" value={city} onChange={(e) => setDropdownItem(e.value._id) } options={dropdownItems} optionLabel="name" placeholder="Select Token Type"></Dropdown> */}
                </div>
                <div className="field">
                    <label htmlFor="name">Additional Comment *</label>
                    <InputTextarea
                        rows={3}
                        cols={30}
                        value={updateAdditionalComment}
                        onChange={(e) => {
                            setUpdateAdditionalComment(e.target.value);
                        }}
                    />
                    {updateErrAdditionalComment && <p className="p-1 err-mess">{updateErrAdditionalComment}</p>}
                </div>
            </Dialog>

            <Dialog visible={deleteProductDialog2} style={{ width: "450px" }} header="Warning" modal footer={deleteProductDialogFooter2} onHide={hideDeleteProductDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {product && <span>Are you sure want to delete this Name Tag?</span>}
                </div>
            </Dialog>

            {/* -- add new public name tag dialog -- */}
            <Dialog visible={nameTagDialog} style={{ width: "450px" }} header="Add Name Tag" modal className="p-fluid" footer={nameTagDialogFooter} onHide={nameTagHideDialog}>
                {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                <div className="field">
                    <label htmlFor="contract-address">Contract Address *</label>
                    <InputText
                        id="contract-address"
                        type="text"
                        value={contractAddress}
                        required
                        onChange={(e) => {
                            setContractAddress(e.target.value);
                        }}
                    />
                    {errContractAddress && <p className="p-1 err-mess">{errContractAddress}</p>}
                </div>
                <div className="field">
                    <label htmlFor="name-tag">Name Tag *</label>
                    <InputText
                        id="name-tag"
                        type="text"
                        value={nameTag}
                        required
                        onChange={(e) => {
                            setNameTag(e.target.value);
                        }}
                    />
                    {errNameTag && <p className="p-1 err-mess">{errNameTag}</p>}
                </div>
                <div className="field">
                    <label htmlFor="website">Website *</label>
                    <InputText
                        id="website"
                        type="text"
                        value={website}
                        required
                        onChange={(e) => {
                            setWebsite(e.target.value);
                        }}
                    />
                    {errWebsite && <p className="p-1 err-mess">{errWebsite}</p>}
                </div>
                <div className="field">
                    <label htmlFor="category-label">Category Label *</label>
                    <InputText
                        id="category-label"
                        type="text"
                        value={categoryLabel}
                        required
                        onChange={(e) => {
                            setCategoryLabel(e.target.value);
                        }}
                    />
                    {errCategoryLabel && <p className="p-1 err-mess">{errCategoryLabel}</p>}
                </div>
                <div className="field">
                    <label htmlFor="name">Short Description *</label>
                    <InputTextarea
                        rows={3}
                        cols={30}
                        value={shortDescription}
                        onChange={(e) => {
                            setShortDescription(e.target.value);
                        }}
                    />
                    {errShortDescription && <p className="p-1 err-mess">{errShortDescription}</p>}
                </div>
            </Dialog>

            {/* -- update new public name tag dialog -- */}
            <Dialog visible={updateNameTagDialog} style={{ width: "450px" }} header="Edit Name Tag" modal className="p-fluid" footer={updateNameTagDialogFooter} onHide={updateNameTagHideDialog}>
                {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                <div className="field">
                    <label htmlFor="contract-address">Contract Address *</label>
                    <InputText
                        id="contract-address"
                        type="text"
                        value={updateContractAddress}
                        required
                        onChange={(e) => {
                            setUpdateContractAddress(e.target.value);
                        }}
                    />
                    {errUpdateContractAddress && <p className="p-1 err-mess">{errUpdateContractAddress}</p>}
                </div>
                <div className="field">
                    <label htmlFor="name-tag">Name Tag *</label>
                    <InputText
                        id="name-tag"
                        type="text"
                        value={updateNameTag}
                        required
                        onChange={(e) => {
                            setUpdateNameTag(e.target.value);
                        }}
                    />
                    {errUpdateNameTag && <p className="p-1 err-mess">{errUpdateNameTag}</p>}
                </div>
                <div className="field">
                    <label htmlFor="website">Website *</label>
                    <InputText
                        id="website"
                        type="text"
                        value={updateWebsite}
                        required
                        onChange={(e) => {
                            setUpdateWebsite(e.target.value);
                        }}
                    />
                    {errUpdateWebsite && <p className="p-1 err-mess">{errUpdateWebsite}</p>}
                </div>
                <div className="field">
                    <label htmlFor="category-label">Category Label *</label>
                    <InputText
                        id="category-label"
                        type="text"
                        value={updateCategoryLabel}
                        required
                        onChange={(e) => {
                            setUpdateCategoryLabel(e.target.value);
                        }}
                    />
                    {errUpdateCategoryLabel && <p className="p-1 err-mess">{errUpdateCategoryLabel}</p>}
                </div>
                <div className="field">
                    <label htmlFor="name">Short Description *</label>
                    <InputTextarea
                        rows={3}
                        cols={30}
                        value={updateShortDescription}
                        onChange={(e) => {
                            setUpdateShortDescription(e.target.value);
                        }}
                    />
                    {errUpdateShortDescription && <p className="p-1 err-mess">{errUpdateShortDescription}</p>}
                </div>
            </Dialog>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(PublicNameTagList, comparisonFn);
