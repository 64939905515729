import React, { useState, useEffect, useContext, useRef } from "react";
import AuthContext from "../AuthProvider";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProductService } from "../service/ProductService";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import { Paginator } from "primereact/paginator";
import { Image } from "primereact/image";
import axios from "axios";

const ChainList = () => {
    document.title = "Chain List";
    const { checkRoles, verifyAndGetUser, formatDate, formatHash, stringWihtDot } = useContext(AuthContext);
    const { encuserpass } = verifyAndGetUser();

    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);

    const [first, setFirst] = useState(0);
    // const [rows, setRows] = useState(10);

    const onPageChange = (event) => {
        setFirst(event.first);
        // setRows(event.rows);
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const [chainData, setChainData] = useState([]);
    const [spinner, setSpinner] = useState(true);

    const [statusValue, setStatusValue] = useState(false);

    const [deleteChainId, setDeleteChainId] = useState(null);
    const [deleteChainDialog, setDeleteChainDialog] = useState(false);

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [logo, setLogo] = useState("");
    const [name, setName] = useState("");
    const [symbol, setSymbol] = useState("");
    const [chainid, setChainid] = useState("");
    const [rpcurls, setRpcurls] = useState([""]);
    const [explorerurl, setExplorerurl] = useState("");
    const [status, setStatus] = useState("");

    const [rpcurlErr, setRpcurlErr] = useState([""]);
    const [rpcurlsError, setRpcurlsError] = useState("");

    const [selectedFile, setSelectedFile] = useState("");
    const [errorMsg, setErrorMsg] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [chainDialog, setChainDialog] = useState(false);

    const [editChainDialog, setEditChainDialog] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);
    const cancelOptions = { label: "Cancel", icon: "pi pi-times", className: "p-button-danger" };

    const getChainList = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/walletchain/getall`, {
                params: {
                    limit: pageSize,
                    page: currentPage,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getChainList : ", res.data.error);
                } else {
                    setChainData(res.data.items);
                    setPages(res.data.pages);
                    setTotalRecords(res.data.total);
                    setSpinner(false);
                }
            });
    };

    useEffect(() => {
        getChainList();
    }, [currentPage, pageSize]);

    useEffect(() => {}, []);

    const openNewChainDailog = () => {
        setErrorMsg("");
        setIsSubmit(false);
        setChainDialog(true);
    };

    const hideChainDialog = () => {
        setName("");
        setSymbol("");
        setChainid("");
        setRpcurls([""]);
        setExplorerurl("");
        setSelectedFile("");
        setIsSubmit(false);
        setChainDialog(false);
    };

    const openEditChainDailog = () => {
        setErrorMsg("");
        setSubmitted(false);
        setEditChainDialog(true);
    };

    const hideEditChainDialog = () => {
        setRpcurls([""]);
        setChainid("");
        setExplorerurl("");
        setName("");
        setSymbol("");
        setLogo("");
        setSubmitted(false);
        setEditChainDialog(false);
    };

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const validateSelectedFile = () => {
        // const MIN_FILE_SIZE = 1024 // 1MB
        // const MAX_FILE_SIZE = 5120 // 5MB
        const MAX_FILE_SIZE = 2048; // 2MB

        if (!selectedFile) {
            // setErrorMsg("Please choose a file");
            setIsSuccess(false);
            return;
        }

        const fileSizeKiloBytes = selectedFile.size / 1024;

        // if(fileSizeKiloBytes < MIN_FILE_SIZE){
        //   setErrorMsg("File size is less than minimum limit");
        //   setIsSuccess(false)
        //   return
        // }

        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
            setSelectedFile("");
            setErrorMsg("File size is greater than maximum limit");
            setIsSuccess(false);
            return;
        }

        setErrorMsg("");
        setIsSuccess(true);
    };

    const validateRpcurls = async () => {
        // const rpcurlErrors = [...rpcurlErr];
        // rpcurls.map((item, index) => {
        //     if (item == "") {
        //         rpcurlErrors[index] = "";
        //     } else {
        //         rpcurlErrors[index] = "Rpcurl is required";
        //     }
        // })
        // setRpcurlErr(rpcurlErrors);
    };

    const saveChain = async () => {
        let temp = 1;
        let rpcurlsValues = "";
        setIsSubmit(true);
        try {
            for (let index = 0; index < rpcurls.length; index++) {
                const element = rpcurls[index];
                if (element === "") {
                    rpcurlsValues = "";
                    break;
                } else {
                    rpcurlsValues = "set";
                }
            }
            // validateSelectedFile();

            const MAX_FILE_SIZE = 2048; // 2MB

            if (selectedFile) {
                const fileSizeKiloBytes = selectedFile.size / 1024;
                if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                    setErrorMsg("File size is greater than maximum limit");
                    temp = 0;
                } else {
                    setErrorMsg("");
                }
            } else {
                temp = 0;
            }

            if (!name) {
                temp = 0;
                console.log("name :- ", name);
            }
            if (!symbol) {
                temp = 0;
                console.log("symbol :- ", symbol);
            }
            if (!chainid) {
                temp = 0;
                console.log("chainid :- ", chainid);
            }

            if (!rpcurlsValues) {
                temp = 0;
                console.log("rpcurlsValues :- ", rpcurlsValues);
            }
            if (!explorerurl) {
                temp = 0;
                console.log("explorerurl :- ", explorerurl);
            }

            if (temp === 1) {
                console.log("rpcurls :- ", rpcurls);
                console.log("rpcurls :- ", JSON.stringify(rpcurls));
                let rpcurls_string = JSON.stringify(rpcurls);

                const formData = new FormData();
                formData.append("name", name);
                formData.append("symbol", symbol);
                formData.append("chainid", chainid);
                // formData.append("rpcurl", rpcurls)
                formData.append("rpcurl", rpcurls_string);

                formData.append("explorerurl", explorerurl);
                formData.append("file", selectedFile);

                await axios
                    .post(`${process.env.REACT_APP_API_URL}admin/walletchain/insert`, formData, {
                        headers: {
                            etoken: encuserpass,
                        },
                    })
                    .then((res) => {
                        console.log("res :- ", res);
                        if (res.data.error) {
                            toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                        } else {
                            toast.current.show({ severity: "success", summary: "Successful", detail: "Chain Saved", life: 3000 });
                            getChainList();
                            hideChainDialog();
                            setName("");
                            setSymbol("");
                            setChainid("");
                            setRpcurls([""]);
                            setExplorerurl("");
                            setSelectedFile("");
                        }
                    });
            }
        } catch (error) {
            console.log("error in saveChain (catch) :", error);
        }
    };

    const removeRpcurl = (index) => {
        const deleteVal = [...rpcurls];
        deleteVal.splice(index, 1);
        setRpcurls(deleteVal);
    };

    const addRpcurl = () => {
        const newRpcurl = [...rpcurls, ""];
        setRpcurls(newRpcurl);
    };

    const handleInputChange = (e, index) => {
        const inputData = [...rpcurls];
        inputData[index] = e.target.value;
        setRpcurls(inputData);
    };

    const editChain = async (data) => {
        console.log("editChain id :- ", data.chainid);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/walletchain/getchain/${data.chainid}`, {
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                console.log("res :- ", res);
                if (res.data.error) {
                    alert(res.data.error);
                    toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                } else {
                    console.log("data :- ", res.data);
                    setRpcurls(res.data.rpc_url);
                    setChainid(res.data.chainid);
                    setExplorerurl(res.data.explorer_url);
                    setName(res.data.name);
                    setSymbol(res.data.symbol);
                    setLogo(res.data.logo);

                    setEditChainDialog(true);
                }
            });
    };

    const updateChain = async () => {
        let temp = 1;
        let rpcurlsValues = "";
        setSubmitted(true);
        try {
            for (let index = 0; index < rpcurls.length; index++) {
                const element = rpcurls[index];
                if (element === "") {
                    console.log("item is null", index);
                    rpcurlsValues = "";
                    break;
                } else {
                    rpcurlsValues = "set";
                }
            }
            // validateSelectedFile();

            const MAX_FILE_SIZE = 2048; // 2MB

            if (selectedFile) {
                const fileSizeKiloBytes = selectedFile.size / 1024;
                if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                    setErrorMsg("File size is greater than maximum limit");
                    temp = 0;
                } else {
                    setErrorMsg("");
                }
            }

            if (!name) {
                temp = 0;
                console.log("name :- ", name);
            }
            if (!symbol) {
                temp = 0;
                console.log("symbol :- ", symbol);
            }
            if (!chainid) {
                temp = 0;
                console.log("chainid :- ", chainid);
            }

            if (!rpcurlsValues) {
                temp = 0;
                console.log("rpcurlsValues :- ", rpcurlsValues);
            }

            if (!explorerurl) {
                temp = 0;
                console.log("explorerurl :- ", explorerurl);
            }

            if (temp === 1) {
                console.log("rpcurls :- ", rpcurls);
                let rpcurls_string = JSON.stringify(rpcurls);

                const formData = new FormData();
                formData.append("name", name);
                formData.append("symbol", symbol);
                formData.append("chainid", chainid);
                formData.append("rpcurls", rpcurls_string);
                formData.append("explorerurl", explorerurl);
                if (selectedFile) {
                    formData.append("file", selectedFile);
                }

                await axios
                    .post(`${process.env.REACT_APP_API_URL}admin/walletchain/update`, formData, {
                        headers: {
                            etoken: encuserpass,
                        },
                    })
                    .then((res) => {
                        console.log("res :- ", res);
                        if (res.data.error) {
                            toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                        } else {
                            toast.current.show({ severity: "success", summary: "Successful", detail: "Chain Updated", life: 3000 });
                            getChainList();
                            hideEditChainDialog();
                            setSelectedFile("");
                        }
                    });
            }
        } catch (error) {
            console.log("error in updateChain (catch) :", error);
        }
    };

    const confirmDeleteChain = (data) => {
        // setProduct(data);
        setDeleteChainId(data.chainid);
        setDeleteChainDialog(true);
    };

    const deleteChain = async (chainId) => {
        console.log("ChainId :- ", chainId);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/walletchain/delete/${chainId}`, {
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                console.log("res :- ", res);
                if (res.data.error) {
                    alert(res.data.error);
                    toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                } else {
                    getChainList();
                    console.log(res.data);
                    setDeleteChainDialog(false);
                    setDeleteChainId("");
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Deleted", life: 3000 });
                }
            });
    };

    const changeStatus = async (data) => {
        console.log("data :- ", data);
        let updateStatus = true;
        if (data.status) {
            updateStatus = false;
        } else {
            updateStatus = true;
        }

        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/walletchain/changestatus/${data.chainid}`, {
                params: {
                    status: updateStatus,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                console.log("res :- ", res);
                if (res.data.error) {
                    alert(res.data.error);
                } else {
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Status Updated", life: 3000 });
                    getChainList();
                    console.log(res.data);
                }
            });
        console.log("verify data._id :- ", data._id);
    };

    const hideDeleteChainDialog = () => {
        setDeleteChainDialog(false);
        setDeleteChainId("");
    };

    const chainListLeftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNewChainDailog} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        );
    };

    const rpcUrlBodyTemplate = (rowData) => {
        // return <span className={`kyc-badge status-${rowData.status}`}>{rowData.status.toString()}</span>;
        console.log("rowData.rpcurl :- ", rowData.rpcurl);
        return rowData.rpcurl.map((item, index) => {
            return (
                <>
                    <span>{item}</span>
                    <br></br>
                </>
            );
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editChain(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteChain(rowData)} />
            </div>
        );
    };

    const statusActionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button label="Change" className="p-button-rounded p-button-info mr-2 mb-2" onClick={() => changeStatus(rowData)} />
            </div>
        );
    };
    const logoImageBodyTemplate = (rowData) => {
        return <Image src={`${process.env.REACT_APP_WALLET_CHAIN_LOGO_URL}${rowData.logo}`} onError={(e) => (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")} alt="logo" width={150} />;
    };

    const chainStatusBodyTemplate = (rowData) => {
        // return <span className={`kyc-badge status-${rowData.status}`}>{rowData.status.toString()}</span>;
        return rowData.status.toString();
    };

    const chainListTableHeader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Chain List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const chainDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideChainDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveChain} />
        </>
    );

    const editChainDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideEditChainDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateChain} />
        </>
    );

    const deleteChainDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteChainDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => deleteChain(deleteChainId)} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={chainListLeftToolbarTemplate}></Toolbar>

                    <PageHeader title="Manage Chain List" />
                    <DataTable value={chainData} dataKey="id" loading={spinner} className="datatable-responsive" globalFilter={globalFilter} emptyMessage="No data found." responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="ind" header="No." headerStyle={{ width: "8%", minWidth: "8rem" }}></Column>
                        <Column field="name" header="Name" headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>
                        <Column field="logo" header="Logo" body={logoImageBodyTemplate} headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>
                        <Column field="symbol" header="Symbol" headerStyle={{ width: "10%", minWidth: "8rem" }}></Column>
                        <Column field="chainid" header="Chain Id" headerStyle={{ width: "10%", minWidth: "8rem" }}></Column>
                        <Column field="rpcurl" header="RPC URL" body={rpcUrlBodyTemplate} headerStyle={{ width: "15%", minWidth: "12rem" }}></Column>
                        <Column field="explorerurl" header="Explorer url" headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>

                        <Column field="status" header="Status" body={chainStatusBodyTemplate} headerStyle={{ width: "10%", minWidth: "8rem" }}></Column>
                        <Column header="Action" body={actionBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column header="Status Change" body={statusActionBodyTemplate} headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>
                    </DataTable>
                    <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} pages={pages} />
                    {/* <Paginator first={first} rows={pageSize} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} /> */}

                    <Dialog visible={chainDialog} style={{ width: "450px" }} header="Chain Details" modal className="p-fluid" footer={chainDialogFooter} onHide={hideChainDialog}>
                        <div className="field">
                            <label htmlFor="name">Logo</label>
                            <div>
                                <input type="file" name="file" accept="image/jpeg, image/png, image/jpg" onChange={handleFileChange} />
                                <div className="space-between">
                                    {/* <p className="info-message">Min size: 1MB</p> */}
                                    <p className="info-message">Max size: 2MB</p>
                                </div>
                                {errorMsg && <p className="error-message">{errorMsg}</p>}
                                {isSubmit && !selectedFile && <small className="p-invalid">Logo is required.</small>}
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="name">Name</label>
                            {/* <InputText id="name" value={product.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.name })} /> */}
                            <InputText id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} required autoFocus className={classNames({ "p-invalid": isSubmit && !name })} />
                            {isSubmit && !name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Symbol</label>
                            <InputText id="name" type="text" value={symbol} onChange={(e) => setSymbol(e.target.value)} required className={classNames({ "p-invalid": isSubmit && !symbol })} />
                            {isSubmit && !symbol && <small className="p-invalid">Symbol is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Chain Id</label>
                            <InputText id="name" type="text" value={chainid} onChange={(e) => setChainid(e.target.value)} required className={classNames({ "p-invalid": isSubmit && !chainid })} />
                            {isSubmit && !chainid && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">RPC URLs</label>

                            {rpcurls.map((item, index) => {
                                return (
                                    <>
                                        <div className="col-12 mb-2 lg:col-8 lg:mb-0" style={{ display: "flex" }}>
                                            <InputText id="name" type="text" value={item} onChange={(e) => handleInputChange(e, index)} required className={classNames({ "p-invalid": isSubmit && !item })} />
                                            {index !== 0 ? (
                                                <Button icon="pi pi-minus" onClick={() => removeRpcurl(index)} className="p-button-rounded p-button-danger p-button-text ml-2 mr-2 mb-2" />
                                            ) : (
                                                <Button icon="pi pi-plus" onClick={addRpcurl} className="p-button-rounded p-button-text mr-2 mb-2" />
                                            )}
                                        </div>
                                        {isSubmit && !item && <small className="p-invalid">RPC url is required.</small>}
                                    </>
                                );
                            })}
                        </div>
                        <div className="field">
                            <label htmlFor="name"> Explorer Url</label>
                            <InputText id="name" type="text" value={explorerurl} onChange={(e) => setExplorerurl(e.target.value)} required className={classNames({ "p-invalid": isSubmit && !explorerurl })} />
                            {isSubmit && !explorerurl && <small className="p-invalid">Explorerurl is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={editChainDialog} style={{ width: "450px" }} header="Chain Details" modal className="p-fluid" footer={editChainDialogFooter} onHide={hideEditChainDialog}>
                        {/*logo && <Image src={`${process.env.REACT_APP_CHAIN_LOGO_URL}${logo}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt="logo" width={150} /> */}
                        {logo && <img src={`${process.env.REACT_APP_WALLET_CHAIN_LOGO_URL}${logo}`} alt={logo} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        <div className="field">
                            <label htmlFor="name">Logo</label>
                            <div>
                                <input type="file" name="file" accept="image/jpeg, image/png, image/jpg" onChange={handleFileChange} />
                                <div className="space-between">
                                    {/* <p className="info-message">Min size: 1MB</p> */}
                                    <p className="info-message">Max size: 2MB</p>
                                </div>
                                {errorMsg && <p className="error-message">{errorMsg}</p>}
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="name">Name</label>
                            {/* <InputText id="name" value={product.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.name })} /> */}
                            <InputText id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} required autoFocus className={classNames({ "p-invalid": submitted && !name })} />
                            {submitted && !name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Symbol</label>
                            <InputText id="name" type="text" value={symbol} onChange={(e) => setSymbol(e.target.value)} required className={classNames({ "p-invalid": submitted && !symbol })} />
                            {submitted && !symbol && <small className="p-invalid">Symbol is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Chain Id</label>
                            <InputText id="name" type="text" value={chainid} onChange={(e) => setChainid(e.target.value)} required className={classNames({ "p-invalid": submitted && !chainid })} disabled />
                            {submitted && !chainid && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">RPC URLs</label>

                            {rpcurls.map((item, index) => {
                                return (
                                    <>
                                        <div className="col-12 mb-2 lg:col-8 lg:mb-0" style={{ display: "flex" }}>
                                            <InputText id="name" type="text" value={item} onChange={(e) => handleInputChange(e, index)} required className={classNames({ "p-invalid": submitted && !item })} />
                                            {index !== 0 ? (
                                                <Button icon="pi pi-minus" onClick={() => removeRpcurl(index)} className="p-button-rounded p-button-danger p-button-text ml-2 mr-2 mb-2" />
                                            ) : (
                                                <Button icon="pi pi-plus" onClick={addRpcurl} className="p-button-rounded p-button-text mr-2 mb-2" />
                                            )}
                                        </div>
                                        {submitted && !item && <small className="p-invalid">RPC url is required.</small>}
                                    </>
                                );
                            })}
                        </div>
                        <div className="field">
                            <label htmlFor="name"> Explorer Url</label>
                            <InputText id="name" type="text" value={explorerurl} onChange={(e) => setExplorerurl(e.target.value)} required className={classNames({ "p-invalid": submitted && !explorerurl })} />
                            {submitted && !explorerurl && <small className="p-invalid">Explorerurl is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteChainDialog} style={{ width: "450px" }} header="Delete Confirmation" modal footer={deleteChainDialogFooter} onHide={hideDeleteChainDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {deleteChainDialog && <span>Do you want to delete this record? </span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ChainList, comparisonFn);
