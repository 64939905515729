import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { ColorPicker } from "primereact/colorpicker";
import { ProductService } from "../service/ProductService";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { useHistory } from "react-router-dom";
import AuthContext from "../AuthProvider";
import axios from "axios";
import { Toast } from "primereact/toast";
import LoadingSpinner from "../service/LoadingSpinner";
const apikey = process.env.REACT_APP_KEY;

const lineData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
        {
            label: "First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.4,
        },
        {
            label: "Second Dataset",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.4,
        },
    ],
};

const Dashboard = (props) => {
    const [switchValue, setSwitchValue] = useState(false);
    const [maintColor, setMaintColor] = useState("");
    const [maintText, setMaintText] = useState("");
    const [maintTextErr, setMaintTextErr] = useState("");
    const [tokenStatus, setTokenStatus] = useState(false);

    const { checkRoles, adminRoles, verifyAndGetUser, genTokenWithTstemp, verifyAdmin } = useContext(AuthContext);
    verifyAdmin();
    const { encuserpass } = verifyAndGetUser();

    const [spinner, setSpinner] = useState(false);
    const [dashboardData, setDashboardData] = useState(null);

    const history = useHistory();
    const [products, setProducts] = useState(null);
    const [lineOptions, setLineOptions] = useState(null);

    const toast = useRef(null);

    document.title = "Explorer Dashboard";

    useEffect(() => {
        getDashboardData();
        getSettingData();
    }, []);

    const getDashboardData = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/dashboard`, {
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setDashboardData(res.data);
                    setSpinner(false);
                }
            });
    };
    const getSettingData = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}setting/getsetting`, {
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setSwitchValue(res.data.maintnanceStatus);
                    setMaintColor(res.data.maintnanceBgColor);
                    setMaintText(res.data.maintnanceMessage);
                    setTokenStatus(res.data.tokenstatus);
                }
            });
    };

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#ebedef",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
                y: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };

    useEffect(() => {
        const productService = new ProductService();
        productService.getProductsSmall().then((data) => setProducts(data));
    }, []);

    useEffect(() => {
        if (props.colorMode === "light") {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);

    const updateMaintenanceData = async () => {
        const encbody = genTokenWithTstemp({ maintnanceMessage: maintText, maintnanceBgColor: maintColor, maintnanceStatus: switchValue, tokenStatus: tokenStatus });
        await axios
            .post(
                `${process.env.REACT_APP_API_URL}setting/maintanance`,
                { token: encbody },
                {
                    headers: {
                        etoken: encuserpass,
                    },
                }
            )
            .then((res) => {
                if (res.data.error) {
                    console.log("error in updateMaintenanceData (catch) : ", res.data.error);
                    toast.current.show({ severity: "error", summary: "Error!", detail: res.data.error, life: 3000 });
                } else {
                    toast.current.show({ severity: "success", summary: "Success!", detail: "Setting data updated successfuly.", life: 3000 });
                }
            })
            .catch((err) => console.log("error in updateMaintenanceData (catch) : ", err));
    };
    return !checkRoles ? (
        <LoadingSpinner />
    ) : checkRoles?.filter((item) => item.id === "0001_001").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <Toast ref={toast} />
            <title>Dashboard</title>
            <div className="grid">
                <div className="col-6">
                    <div className="col-12">
                        <h3 className="m-0 text-700">Users</h3>
                    </div>
                    <div className="grid">
                        <div className="col-6 lg:col-6 xl:col-6 mb-2">
                            <div className="card mb-0">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">Total Users</span>
                                        <div className="text-900 font-medium text-xl">{dashboardData?.totaluser}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                        <i className="pi pi-users text-blue-500 text-xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 lg:col-6 xl:col-6 mb-2">
                            <div className="card mb-0">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">Total Active Users</span>
                                        <div className="text-900 font-medium text-xl">{dashboardData?.activeuser}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                        <i className="pi pi-check text-orange-500 text-xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3 className="m-0 text-700">Tokens</h3>
                    </div>
                    <div className="grid">
                        <div className="col-12 lg:col-6 xl:col-6 mb-2">
                            <div className="card mb-0">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">Total Tokens</span>
                                        <div className="text-900 font-medium text-xl">{dashboardData?.tokens}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                        <i className="pi pi-check text-blue-500 text-xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 lg:col-6 xl:col-6 mb-2">
                            <div className="card mb-0">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">Verified Tokens</span>
                                        <div className="text-900 font-medium text-xl">{dashboardData?.verifiedtokens}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                        <i className="pi pi-times text-orange-500 text-xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-12">
                        <h3 className="m-0 text-700">Nodes</h3>
                    </div>
                    <div className="grid">
                        <div className="col-12 lg:col-6 xl:col-6 mb-2">
                            <div className="card mb-0">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">Total Nodes</span>
                                        <div className="text-900 font-medium text-xl">{dashboardData?.totalnodes}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                        <i className="pi pi-shopping text-blue-500 text-xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 lg:col-6 xl:col-6 mb-2">
                            <div className="card mb-0">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">Total Verified Nodes</span>
                                        <div className="text-900 font-medium text-xl">{dashboardData?.activenodes}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                        <i className="pi pi-map-marker text-orange-500 text-xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="col-6 mt-6">
                    <div className="card mb-0">
                        <div className="flex justify-content-between">
                            <div className="flex justify-content-between">
                                <div className="border">
                                    <div className="field mr-5">
                                        <label htmlFor="email">Under Maintenance : </label>&nbsp;
                                        <div className="flex">
                                            <InputSwitch checked={switchValue} className="mr-3" name="updateStatus" id="switch1" onChange={(e) => setSwitchValue(e.value)} />
                                            {switchValue ? (
                                                <h5 className="m-0" style={{ color: "#6ebe71", fontWeight: "bold" }}>
                                                    ON
                                                </h5>
                                            ) : (
                                                <h5 className="m-0" style={{ color: "#ff4032", fontWeight: "bold" }}>
                                                    OFF
                                                </h5>
                                            )}
                                        </div>
                                    </div>
                                    <div className="field mr-5">
                                        <label htmlFor="email">Maintenance Text * : </label>
                                        <br />
                                        <InputText id="email" placeholder="Message" value={maintText} name="website" type="text" required onChange={(event) => setMaintText(event.target.value)} />
                                        {maintTextErr && <p className="p-1 err-mess">{maintTextErr}</p>}
                                    </div>
                                    <div className="field mr-5">
                                        <label htmlFor="email">Maintenance Background Color : </label>
                                        <br />
                                        <ColorPicker
                                            value={maintColor}
                                            onChange={(e) => {
                                                setMaintColor(e.value);
                                            }}
                                            style={{ width: "2rem" }}
                                        />
                                        &nbsp;&nbsp;hex:{" "}
                                        <InputText
                                            placeholder="ffffff"
                                            value={maintColor}
                                            type="text"
                                            onChange={(e) => {
                                                setMaintColor(e.target.value);
                                            }}
                                        />
                                    </div>
                                    {/* <div className="field mr-5">
                                        <label htmlFor="email">Token Transactions : </label>&nbsp;
                                        <div className="flex">
                                            <InputSwitch checked={tokenStatus} className="mr-3" name="updateStatus" id="switch1" onChange={(e) => setTokenStatus(e.value)} />
                                            {tokenStatus ? (
                                                <h5 className="m-0" style={{ color: "#6ebe71", fontWeight: "bold" }}>
                                                    ON
                                                </h5>
                                            ) : (
                                                <h5 className="m-0" style={{ color: "#ff4032", fontWeight: "bold" }}>
                                                    OFF
                                                </h5>
                                            )}
                                        </div>
                                    </div> */}
                                    <div className="field mt-2">
                                        <Button label="Save" className="p-button-rounded" onClick={updateMaintenanceData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
