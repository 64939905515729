import React, { useState, useEffect, useRef, useContext } from "react";
import { Button } from "primereact/button";
import { useHistory, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";

import { validateEmail } from "../service/rejexValidateion";

import { InputText } from "primereact/inputtext";
import axios from "axios";
import AuthContext from "../AuthProvider";

const apikey = process.env.REACT_APP_KEY;

const Profile = () => {
    document.title = "Admin Profile";

    const { verifyAndGetUser, verifyAdmin } = useContext(AuthContext);
    verifyAdmin();
    const { username, encuserpass } = verifyAndGetUser();

    const history = useHistory();

    const [email, setEmail] = useState("");
    const [profileUsername, setProfileUsername] = useState("");
    const [password, setPassword] = useState("");
    const [adminRoles, setAdminRoles] = useState("");

    const [errPassword, setErrPassword] = useState("");

    const [errEmail, setErrEmail] = useState("");

    const toast = useRef(null);

    useEffect(() => {
        getAdminDetails();
    }, []);

    const getAdminDetails = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/adminuserdetails`, {
                params: {
                    adminuser: username,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setEmail(res?.data.email);
                    setProfileUsername(res?.data.username);
                    setAdminRoles(res?.data.roles);
                }
            });
    };

    const handelUpdate = async () => {
        let temp = 1;
        if (!validateEmail(email)) {
            temp = 0;
            setErrEmail("Invalid email address.");
        } else {
            setErrEmail("");
        }
        if (password.length < 8) {
            temp = 0;
            setErrPassword("Enter atleast 8 charachter in confirm password.");
        } else {
            setErrPassword("");
        }
        if (temp === 1) {
            // await axios.post(process.env.REACT_APP_API_URL, { method: "admin", submethod: "update", key: apikey, id: id, email: email, username: username, password: password }).then((res) => {
            //     if (res.data) {
            //         if (res.data.success) {
            //             // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Name Tab saved', life: 3000 });
            //             setEmail("");
            //             setUsername("");
            //             setPassword("");
            //             history.push("/dashboard");
            //         } else if (res.data.error) {
            //             toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
            //         }
            //     }
            // });
            try {
                await axios
                    .post(
                        `${process.env.REACT_APP_API_URL}admin/editadmin`,
                        {
                            uname: username,
                            pass: password,
                            email: email,
                            roles: adminRoles,
                        },
                        {
                            params: {
                                action: "edit",
                            },
                            headers: {
                                etoken: encuserpass,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data.error) {
                            toast.current.show({ severity: "error", summary: "Error!", detail: res.data.error, life: 3000 });
                            console.log("error in updating status : ", res.data.error);
                        } else {
                            toast.current.show({ severity: "success", summary: "Success!", detail: "Admin user update successfuly.", life: 3000 });
                        }
                    });
            } catch (err) {
                console.log("error in updating status (catch) :", err);
            }
        }
    };
    return (
        <>
            <Toast ref={toast} />
            <title>Profile</title>
            <div className="card mdb">
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card p-fluid">
                            <h5>
                                <b>Update Profile</b>
                            </h5>
                            <div className="field">
                                <label htmlFor="username">Username *</label>
                                <InputText id="username" value={profileUsername} type="email" required onChange={(e) => setProfileUsername(e.target.value)} disabled />
                            </div>
                            <div className="field">
                                <label htmlFor="email">Email *</label>
                                <InputText id="email" value={email} type="text" onChange={(e) => setEmail(e.target.value)} />
                                {errEmail && <p className="p-1 err-mess">{errEmail}</p>}
                            </div>
                            <div className="field">
                                <label htmlFor="password">New Password *</label>
                                <InputText id="password" value={password} type="password" onChange={(e) => setPassword(e.target.value)} />
                                {errPassword && <p className="p-1 err-mess">{errPassword}</p>}
                            </div>
                            <div className="field grid">
                                <div className="col-12 md:col-2">
                                    <Button type="button" label="Update" onClick={handelUpdate}></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Profile, comparisonFn);
