import React, { useState, useEffect, useRef, useContext } from "react";
import classNames from 'classnames';
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AuthContext from "../AuthProvider";
import { useHistory } from "react-router-dom";
import axios from "axios";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import { Paginator } from 'primereact/paginator';
import EmptyDataMessage from "./common/EmptyDataMessage";
import LoadingSpinner from "../service/LoadingSpinner";
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Withdrawal = () => {
    document.title = "Explorer Withdrawals";

    const history = useHistory();
    const { checkRoles, verifyAndGetUser, formatDate, formatHash, stringWihtDot } = useContext(AuthContext);
    const { encuserpass } = verifyAndGetUser();

    // const [email, setEmail] = useState("");
    // const [username, setUsername] = useState("");
    // const [password, setPassword] = useState("");

    // const [updateEmail, setUpdateEmail] = useState("");
    // const [UpdateUsername, setUpdateUsername] = useState("");
    // const [updatePassword, setUpdatePassword] = useState("");

    const [statusId, setStatusId] = useState("");
    const [status, setStatus] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);

    const [first, setFirst] = useState(0);
    // const [rows, setRows] = useState(10);

    const onPageChange = (event) => {
        setFirst(event.first);
        // setRows(event.rows);
        setPageSize(event.rows)
        setCurrentPage(event.page + 1);
    };

    const [csvAllData, setCsvAllData] = useState(null);

    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductDialog2, setdeleteProductDialog2] = useState(false);
    const toast = useRef(null);

    const [withdrawals, setWithdrawals] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [address, setAddress] = useState("");
    const [validatorDialog, setValidatorDialog] = useState(false);
    const [validator, setValidator] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const getWithdrawalList = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/withdrawal`, {
                params: {
                    limit: pageSize,
                    page: currentPage,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getWithdrawalList : ", res.data.error);
                } else {
                    setWithdrawals(res.data.items);
                    setPages(res.data.pages);
                    setTotalRecords(res.data.total);
                    setSpinner(false);
                }
            });
    };



    const editValidator = (address) => {
        setValidator("");
        setAddress(address);
        setValidatorDialog(true);
    }

    const hideValidatorDialog = () => {
        setSubmitted(false);
        setValidatorDialog(false);
        setAddress("");
        setValidator("");
    }
    const saveValidator = async () => {

        setSubmitted(true);

        console.log("Validator :- ", validator);
        console.log("address :- ", address);

        try {
            await axios
                .get(`${process.env.REACT_APP_API_URL}admin/updatevalidators/${address}`, {
                    params: {
                        validator: validator,
                    },
                    headers: {
                        etoken: encuserpass,
                    },
                })
                .then((res) => {
                    if (res.data.error) {
                        console.log("error in saveValidator : ", res.data.error);
                    } else {
                        setAddress("");
                        getWithdrawalList();
                        setValidatorDialog(false);
                        setSubmitted(false);
                    }
                });
        } catch (err) {
            console.log("error in updating status (catch) :", err);
        }
    }
    useEffect(() => {
        getWithdrawalList();
    }, [currentPage, pageSize]);

    const csvReport = {
        filename: "Explorer-Users-list.csv",
        data: csvAllData,
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setdeleteProductDialog2(false);
    };

    const deleteProduct = async (status) => {
        try {
            await axios
                .get(`${process.env.REACT_APP_API_URL}admin/blockuser/${statusId}`, {
                    params: {
                        action: status,
                    },
                    headers: {
                        etoken: encuserpass,
                    },
                })
                .then((res) => {
                    if (res.data.error) {
                        console.log("error in updating status : ", res.data.error);
                    } else {
                        // getUserList();
                        setDeleteProductDialog(false);
                    }
                });
        } catch (err) {
            console.log("error in updating status (catch) :", err);
        }
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <span>
                {rowData.status.toString()}
            </span>
        );
    }

    const hashBodyTemplate = (rowData) => {
        return <a href={`${process.env.REACT_APP_EXPLORER_URL}tx/${rowData.hash}`} target="_blank">{formatHash(rowData.hash)} </a>

    }
    const addressBodyTemplate = (rowData) => {
        return <a href={`${process.env.REACT_APP_EXPLORER_URL}address/${rowData.address}`} target="_blank"> {rowData.address}</a>
    }

    const timestampBodyTemplate = (rowData) => {
        return (
            <span>
                {formatDate(rowData.timestamp)}
            </span>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi " className="p-button-rounded p-button-info mr-2" label="Allocate Node" onClick={() => editValidator(rowData.address)} />
            </div>
        );
    }

    const headerWithdrawalTable = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Withdrawals</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const validatorDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideValidatorDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveValidator} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            {status ? (
                <Button label="Block" icon="pi pi-check" className="p-button-text" onClick={() => deleteProduct("block")} />
            ) : (
                <Button
                    label="Unblock"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={() => {
                        deleteProduct("unblock");
                    }}
                />
            )}
        </>
    );


    return !checkRoles ? (
        <LoadingSpinner />
    ) : checkRoles?.filter((item) => item.id === "0001_003").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <div className="grid UsersList-demo">
                {/* <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <PageHeader title="Withdrawals" />
                        <table className="table" cellPadding={0} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Hash</th>
                                    <th>Address</th>
                                    <th>Amount</th>
                                    <th>Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {withdrawals && withdrawals?.length
                                    ? withdrawals?.map((item, index) => {
                                        const ind = (currentPage * pageSize) - pageSize + (index + 1);
                                        return (
                                            <tr>
                                                <td>{ind}</td>
                                                <td><a href={`${process.env.REACT_APP_EXPLORER_URL}tx/${item.hash}`} target="_blank">{formatHash(item.hash)} </a></td>
                                                <td><a href={`${process.env.REACT_APP_EXPLORER_URL}address/${item.address}`} target="_blank"> {item.address}</a></td>
                                                <td>{item.capital}</td>
                                                <td>{formatDate(item.timestamp)}</td>
                                            </tr>
                                        );
                                    })
                                    : ""}
                            </tbody>
                        </table>
                        {spinner ? <LoadingSpinner /> : withdrawals && withdrawals?.length ? "" : <EmptyDataMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />}
                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} pages={pages} />
                    </div>
                </div> */}

                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        <PageHeader title="Withdrawals" />
                        <DataTable value={withdrawals} loading={spinner} responsiveLayout="scroll" className="mt-3"
                            dataKey="id" globalFilter={globalFilter} emptyMessage="No data found." >

                            <Column field="ind" header="No." headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                            <Column field="hash" header="Hash" body={hashBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="address" header="Address" body={addressBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="capital" header="Amount" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="timestamp" header="Timestamp" body={timestampBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        </DataTable>
                        {/* <Paginator first={first} rows={pageSize} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} /> */}
                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} pages={pages} />
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Withdrawal, comparisonFn);
