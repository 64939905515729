import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AuthContext from "../AuthProvider";
import { useHistory } from "react-router-dom";
import axios from "axios";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import EmptyDataMessage from "./common/EmptyDataMessage";
import LoadingSpinner from "../service/LoadingSpinner";
const UsersList = () => {
    document.title = "Explorer Users";

    const history = useHistory();
    const { checkRoles, verifyAndGetUser, stringWihtDot } = useContext(AuthContext);
    const { encuserpass } = verifyAndGetUser();

    // const [email, setEmail] = useState("");
    // const [username, setUsername] = useState("");
    // const [password, setPassword] = useState("");

    // const [updateEmail, setUpdateEmail] = useState("");
    // const [UpdateUsername, setUpdateUsername] = useState("");
    // const [updatePassword, setUpdatePassword] = useState("");

    const [statusId, setStatusId] = useState("");
    const [status, setStatus] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);

    const [csvAllData, setCsvAllData] = useState(null);

    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductDialog2, setdeleteProductDialog2] = useState(false);
    const toast = useRef(null);

    const [users, setUsers] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);

    const getUserList = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/listusers`, {
                params: {
                    limit: pageSize,
                    page: currentPage,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setUsers(res.data.items);
                    setPages(res.data.pages);
                    setSpinner(false);
                }
            });
    };

    useEffect(() => {
        getUserList();
    }, [currentPage, pageSize]);

    const csvReport = {
        filename: "Explorer-Users-list.csv",
        data: csvAllData,
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setdeleteProductDialog2(false);
    };

    const deleteProduct = async (status) => {
        try {
            await axios
                .get(`${process.env.REACT_APP_API_URL}admin/blockuser/${statusId}`, {
                    params: {
                        action: status,
                    },
                    headers: {
                        etoken: encuserpass,
                    },
                })
                .then((res) => {
                    if (res.data.error) {
                        console.log("error in updating status : ", res.data.error);
                    } else {
                        getUserList();
                        setDeleteProductDialog(false);
                    }
                });
        } catch (err) {
            console.log("error in updating status (catch) :", err);
        }
    };

    const passwordBodyTemplate = (rowData) => {
        return <span>{stringWihtDot(rowData.password, 16)}</span>;
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {!rowData.status ? (
                    <Button
                        type="button"
                        icon="pi pi-check mx-1"
                        className="p-button-success"
                        label="Unlock"
                        onClick={() => {
                            setStatusId(rowData.username);
                            setDeleteProductDialog(true);
                            setStatus(false);
                        }}
                    ></Button>
                ) : (
                    <Button
                        type="button"
                        icon="pi pi-times mx-1"
                        className="p-button-danger"
                        label="Block"
                        onClick={() => {
                            setStatusId(rowData.username);
                            setDeleteProductDialog(true);
                            setStatus(true);
                        }}
                    ></Button>
                )}
            </div>
        );
    };

    const deleteProductDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            {status ? (
                <Button label="Block" icon="pi pi-check" className="p-button-text" onClick={() => deleteProduct("block")} />
            ) : (
                <Button
                    label="Unblock"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={() => {
                        deleteProduct("unblock");
                    }}
                />
            )}
        </>
    );

    return !checkRoles ? (
        <LoadingSpinner />
    ) : checkRoles?.filter((item) => item.id === "0001_003").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <div className="grid UsersList-demo">
                {/* <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <PageHeader title="UserList" />
                        <table className="table" cellPadding={0} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Password</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users && users?.length
                                    ? users?.map((item, index) => {
                                        const ind = (currentPage * pageSize) - pageSize + (index + 1);
                                        return (
                                            <tr>
                                                <td>{ind}</td>
                                                <td>{item.username}</td>
                                                <td>{item.email}</td>
                                                <td>{stringWihtDot(item.password, 16)}</td>
                                                <td>
                                                    {!item.status ? (
                                                        <Button
                                                            type="button"
                                                            icon="pi pi-check mx-1"
                                                            className="p-button-success"
                                                            label="Unlock"
                                                            onClick={() => {
                                                                setStatusId(item.username);
                                                                setDeleteProductDialog(true);
                                                                setStatus(false);
                                                            }}
                                                        ></Button>
                                                    ) : (
                                                        <Button
                                                            type="button"
                                                            icon="pi pi-times mx-1"
                                                            className="p-button-danger"
                                                            label="Block"
                                                            onClick={() => {
                                                                setStatusId(item.username);
                                                                setDeleteProductDialog(true);
                                                                setStatus(true);
                                                            }}
                                                        ></Button>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : ""}
                            </tbody>
                        </table>
                        {spinner ? <LoadingSpinner /> : users && users?.length ? "" : <EmptyDataMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />}
                        <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Warning" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {users && <span>Are you sure you want to {status ? "Block?" : "Unblock?"}</span>}
                            </div>
                        </Dialog>
                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} pages={pages} />
                    </div>
                </div> */}

                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <PageHeader title="UserList" />
                        <DataTable value={users} loading={spinner} responsiveLayout="scroll" className="mt-3" dataKey="id" globalFilter={globalFilter} emptyMessage="No data found.">
                            <Column field="ind" header="No." headerStyle={{ width: "10%", minWidth: "8rem" }}></Column>
                            <Column field="username" header="Username" headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="email" header="Email" headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="password" header="Password" body={passwordBodyTemplate} headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>
                            <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        </DataTable>

                        <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Warning" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {users && <span>Are you sure you want to {status ? "Block?" : "Unblock?"}</span>}
                            </div>
                        </Dialog>
                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} pages={pages} />
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UsersList, comparisonFn);
