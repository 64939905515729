import React, { useState, useEffect, useRef, useContext } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProductService } from "../service/ProductService";
import { Dropdown } from "primereact/dropdown";
import AuthContext from "../AuthProvider";
import { FileUpload } from "primereact/fileupload";

import { CSVLink } from "react-csv";

import axios from "axios";
import LoadingSpinner from "../service/LoadingSpinner";

const apikey = process.env.REACT_APP_KEY;

const WalletChainTokenList = () => {
    document.title = "Wallet Tokens";

    const { checkRoles, roles } = useContext(AuthContext);

    // get tab data states
    const [tabData, setTabData] = useState([]);
    const [tabId, setTabId] = useState("");
    const [tabName, setTabName] = useState("");

    const [file, setFile] = useState("");

    const [editTokenLogo, setEditTokenLogo] = useState();
    const [editTokenName, setEditTokenName] = useState("");
    const [editTokenContractAddress, setEditTokenContractAddress] = useState("");
    const [edittokenTicker, setEditTokenTicker] = useState("");
    const [editDecimal, seteditDecimal] = useState("");
    const [editId, setEditId] = useState("");

    const [deleteId, setDeleteId] = useState("");
    const [deleteTokenName, setDeleteTokenName] = useState("");

    const [loading, setLoading] = useState(true);

    const [deleteImgPath, setDeleteImgPath] = useState("");
    const [deleteUpdateImfPath, setDeleteUpdateImgPath] = useState("");

    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const [csvAllData, setCsvAllData] = useState(null);

    useEffect(() => {
        getTokenList();
        getTabData();
    }, []);

    const getTokenList = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "chain", submethod: "get", key: apikey, request: "token" }).then((res) => {
            if (res.data.error) {
                // console.log(res.data.error);
            } else {
                setProducts(res.data);
                var csvData = new Array();
                for (let i = 0; i < res.data.length; i++) {
                    csvData.push({ ind: res.data[i].id, logo: res.data[i].logo, tokenname: res.data[i].tokenname, chainname: res.data[i].chainname, contractaddress: res.data[i].contractaddress, ticker: res.data[i].ticker, decimal: res.data[i].decimal, tabname: res.data[i].tabname });
                }
                setCsvAllData(csvData);
            }
        });
    };

    const getTabData = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "walletTabs", submethod: "get", key: apikey }).then((res) => {
            if (res?.data?.error) {
                // toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.error, life: 3000 });
            } else {
                // toast.current.show({ severity: 'success', summary: 'Successful', detail: res.data.success, life: 3000 });
                setTabData(res.data);
                setLoading(false);
            }
        });
    };

    const csvReport = {
        filename: "wallet-token-list.csv",
        data: csvAllData,
    };

    const handleUploadChange = (e) => {
        // console.log("e.target.files[0]", e.files[0].name);
        setFile(e.files[0]);
    };

    const onUpload = () => {
        toast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded", life: 3000 });
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const clearStates = () => {
        setEditTokenName("");
        setEditTokenContractAddress("");
        setEditTokenTicker("");
        setEditId("");
        setTabId("");
        setTabId("");
    };

    const saveProduct = async () => {
        // setSubmitted(true);

        // if (product.name.trim()) {
        //     let _products = [...products];
        //     let _product = { ...product };
        //     if (product.id) {
        //         const index = findIndexById(product.id);

        //         _products[index] = _product;
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
        //     }
        //     else {
        //         _product.id = createId();
        //         _product.image = 'product-placeholder.svg';
        //         _products.push(_product);
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
        //     }

        //     setProducts(_products);
        //     setProduct(emptyProduct);
        // }

        const formData = new FormData();
        formData.append("method", "chain");
        formData.append("submethod", "update");
        formData.append("request", "token");
        formData.append("file", file);
        formData.append("tokenname", editTokenName);
        formData.append("contractAddress", editTokenContractAddress);
        formData.append("tokenticker", edittokenTicker);
        formData.append("tabname", tabName);
        formData.append("tabid", tabId);
        formData.append("decimal", editDecimal);
        formData.append("id", editId);
        formData.append("key", apikey);
        formData.append("deleteimg", deleteUpdateImfPath);

        await axios.post(process.env.REACT_APP_API_URL, formData).then((res) => {
            if (res?.data?.error) {
                toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
            } else {
                getTokenList();
                clearStates();
                setProductDialog(false);
                toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
            }
        });
    };

    const editProduct = (product) => {
        setEditId(product._id);
        setEditTokenName(product.tokenname);
        setEditTokenContractAddress(product.contractaddress);
        setEditTokenTicker(product.ticker);
        setTabName(product.tabname);
        setTabId(product.tabid);
        seteditDecimal(product.decimal);
        setProductDialog(true);
        setDeleteUpdateImgPath(product.logo);
    };

    const confirmDeleteProduct = (product) => {
        // setProduct(product);
        setDeleteId(product._id);
        setDeleteTokenName(product.tokenname);
        setDeleteProductDialog(true);
        setDeleteImgPath(product.logo);
    };

    const deleteProduct = async () => {
        // let _products = products.filter(val => val.id !== product.id);
        // setProducts(_products);
        // setProduct(emptyProduct);
        // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
        await axios.post(process.env.REACT_APP_API_URL, { method: "chain", submethod: "delete", key: apikey, request: "token", id: deleteId, tokenname: deleteTokenName, image: deleteImgPath }).then((res) => {
            if (res?.data?.error) {
                toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
            } else if (res?.data?.success) {
                setDeleteId("");
                setDeleteTokenName("");
                getTokenList();
                setDeleteProductDialog(false);
                toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
            }
        });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    };

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.tokenname}
            </>
        );
    };

    const chainNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.chainname}
            </>
        );
    };
    const contractAddressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.contractaddress}
            </>
        );
    };
    const tokenTickerBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.ticker}
            </>
        );
    };

    const decimalBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.decimal}
            </>
        );
    };
    const tabNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.tabname}
            </>
        );
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={rowData.logo ? process.env.REACT_APP_WALLET_TOKEN_LOGO + rowData.logo : "assets/images/users/user-6.jpg"} alt={rowData.logo} className="shadow-2 imground" width="50" />
            </>
        );
    };

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    };

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    };

    const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                <Rating value={rowData.rating} readonly cancel={false} />
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Chain Token List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                {csvAllData ? (
                    <CSVLink className="p-button p-component p-button-secondary p-2 m-1" {...csvReport}>
                        .CSV
                    </CSVLink>
                ) : (
                    ""
                )}
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return !checkRoles || checkRoles?.filter((item) => item.id === "0002_004").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <div className="grid WalletChainTokenList-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        <DataTable
                            ref={dt}
                            value={products}
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={globalFilter}
                            emptyMessage={loading ? <LoadingSpinner /> : "data not found."}
                            header={header}
                            responsiveLayout="scroll"
                        >
                            <Column field="id" header="#" sortable body={codeBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column header="Logo" body={imageBodyTemplate} headerStyle={{ width: "14%", minWidth: "7rem" }}></Column>
                            <Column field="name" header="Name" sortable body={nameBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="chain_name" header="Chain Name" sortable body={chainNameBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="contract_address" header="Contract Address" sortable body={contractAddressBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="ticker" header="Ticker" sortable body={tokenTickerBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="decimal" header="Decmal" sortable body={decimalBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="tab_name" header="Tab Name" sortable body={tabNameBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>

                            <Column field="action" header="Action" body={actionBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        </DataTable>

                        <Dialog visible={productDialog} style={{ width: "450px" }} header="Edit Chain Tokens" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            <div className="field">
                                <label htmlFor="email">Token Logo *</label>
                                <div className="card">
                                    {/* <FileUpload mode="basic" name="image" accept="image/*" maxFileSize={2000000} onUpload={onUpload} /> */}
                                    <FileUpload mode="basic" name="image" customUpload={true} url="./assets" auto="true" accept="image/*" maxFileSize={2000000} uploadHandler={handleUploadChange} />
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="tokenname">Token Name *</label>
                                <InputText
                                    id="tokenname"
                                    value={editTokenName}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setEditTokenName(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="contractaddress">Contract Address *</label>
                                <InputText
                                    id="contractaddress"
                                    value={editTokenContractAddress}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setEditTokenContractAddress(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="tokenticker">Token Ticker *</label>
                                <InputText
                                    id="tokenticker"
                                    value={edittokenTicker}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setEditTokenTicker(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="decimal">Decimal *</label>
                                <InputText
                                    id="decimal"
                                    type="text"
                                    required
                                    value={editDecimal}
                                    onChange={(e) => {
                                        seteditDecimal(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="tokentype">Token type *</label>
                                <Dropdown
                                    id="state"
                                    value={tabId}
                                    onChange={(e) => {
                                        setTabId(e.value);
                                        // setTabName(e.value);
                                    }}
                                    options={tabData}
                                    optionLabel="name"
                                    optionValue="_id"
                                    placeholder="Select Token Type"
                                ></Dropdown>
                                {/* <Dropdown id="state" value={city} onChange={(e) => setDropdownItem(e.value._id) } options={dropdownItems} optionLabel="name" placeholder="Select Token Type"></Dropdown> */}
                            </div>
                        </Dialog>

                        <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {product && <span>Are you sure you want to delete?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(WalletChainTokenList, comparisonFn);
