import React, { useState, useEffect, useRef, useContext } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import AuthContext from "../AuthProvider";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";

import { InputTextarea } from "primereact/inputtextarea";

// import {InputTextarea} from 'primereact/InputTextarea';

const apikey = process.env.REACT_APP_KEY;

const BlockAddress = () => {
    document.title = "Blocked Address";

    const { checkRoles, verifyAndGetUser, genTokenWithTstemp } = useContext(AuthContext);
    const { encuserpass } = verifyAndGetUser();

    const [spinner, setSpinner] = useState(true);

    const [address, setAddress] = useState("");
    const [note, setNote] = useState("");

    const [errAddress, setErrAddress] = useState("");
    const [errNote, setErrNote] = useState("");

    const [updateId, setUpdateId] = useState("");
    const [updateAddress, setUpdateAddress] = useState("");
    const [updateNote, setUpdateNote] = useState("");

    const [errUpdateAddress, setErrUpdateAddress] = useState("");
    const [errUpdateNote, setErrUpdateNote] = useState("");

    const [deleteAddress, setdeleteAddress] = useState("");

    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };

    const [addressess, setAddressess] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(10);

    const [productDialog, setProductDialog] = useState(false);
    const [updateProductDialog, setUpdateProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        getBlockedAddress();
    }, [currentPage, pageSize]);

    const getBlockedAddress = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}/admin/blockaddress`, {
                params: {
                    limit: pageSize,
                    page: currentPage,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setAddressess(res.data.items);
                    setPages(res.data.pages);
                    setSpinner(false);
                }
            });
    };

    const openNew = () => {
        setProductDialog(true);
    };

    const hideDialog = () => {
        setAddress("");
        setNote("");
        setErrAddress("");
        setErrNote("");
        setProductDialog(false);
    };

    const updateHideDialog = () => {
        setUpdateAddress("");
        setUpdateNote("");
        setErrUpdateAddress("");
        setErrUpdateNote("");
        setUpdateProductDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setdeleteAddress("");
        setDeleteProductDialog(false);
    };

    const saveProduct = async () => {
        try {
            let temp = 1;
            if (!address) {
                setErrAddress("Address is require");
                temp = 0;
            } else {
                setErrAddress("");
            }
            if (!note) {
                setErrNote("Note is require");
                temp = 0;
            } else {
                setErrNote("");
            }
            if (temp) {
                const encbody = genTokenWithTstemp({ address: address, note: note });
                await axios
                    .post(
                        `${process.env.REACT_APP_API_URL}admin/blockaddress/insert`,
                        { token: encbody },
                        {
                            headers: {
                                etoken: encuserpass,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data.error) {
                            console.log("error in updateMaintenanceData (catch) : ", res.data.error);
                            toast.current.show({ severity: "error", summary: "Error!", detail: res.data.error, life: 3000 });
                        } else {
                            toast.current.show({ severity: "success", summary: "Success!", detail: res.data.message, life: 3000 });
                            setProductDialog(false);
                            setAddress("");
                            setNote("");
                            setErrAddress("");
                            setErrNote("");
                            getBlockedAddress();
                        }
                    })
                    .catch((err) => console.log("error in updateMaintenanceData (catch) : ", err));
            }
        } catch (err) {
            console.log("error : ", err);
        }
    };
    const updateProduct = async () => {
        try {
            let temp = 1;
            if (!updateAddress) {
                setErrUpdateAddress("Address is require");
                temp = 0;
            } else {
                setErrUpdateAddress("");
            }
            if (!updateNote) {
                setErrUpdateNote("Note is require");
                temp = 0;
            } else {
                setErrUpdateNote("");
            }
            if (temp) {
                const encbody = genTokenWithTstemp({ address: updateAddress, note: updateNote });
                await axios
                    .post(
                        `${process.env.REACT_APP_API_URL}admin/blockaddress/update`,
                        { token: encbody },
                        {
                            headers: {
                                etoken: encuserpass,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data.error) {
                            console.log("error in updateMaintenanceData (catch) : ", res.data.error);
                            toast.current.show({ severity: "error", summary: "Error!", detail: res.data.error, life: 3000 });
                        } else {
                            toast.current.show({ severity: "success", summary: "Success!", detail: res.data.message, life: 3000 });
                            setUpdateAddress("");
                            setUpdateNote("");
                            setErrUpdateAddress("");
                            setErrUpdateNote("");
                            getBlockedAddress();
                            setUpdateProductDialog(false);
                        }
                    })
                    .catch((err) => console.log("error in updateMaintenanceData (catch) : ", err));
            }
        } catch (err) {
            console.log("error : ", err);
        }
    };

    const editProduct = (rowData) => {
        setUpdateAddress(rowData.address);
        setUpdateNote(rowData.note);
        setUpdateProductDialog(true);
    };

    const confirmDeleteProduct = (rowData) => {
        setdeleteAddress(rowData.address);
        setDeleteProductDialog(true);
    };

    const deleteProduct = async () => {
        try {
            if (deleteAddress) {
                const encbody = genTokenWithTstemp({ address: deleteAddress });
                await axios
                    .post(
                        `${process.env.REACT_APP_API_URL}admin/blockaddress/delete`,
                        { token: encbody },
                        {
                            headers: {
                                etoken: encuserpass,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data.error) {
                            console.log("error in updateMaintenanceData (catch) : ", res.data.error);
                            toast.current.show({ severity: "error", summary: "Error!", detail: res.data.error, life: 3000 });
                        } else {
                            toast.current.show({ severity: "success", summary: "Success!", detail: res.data.message, life: 3000 });
                            setdeleteAddress("");
                            getBlockedAddress();
                            setDeleteProductDialog(false);
                        }
                    })
                    .catch((err) => console.log("error in updateMaintenanceData (catch) : ", err));
            } else {
                toast.current.show({ severity: "error", summary: "Error!", detail: "Delete address not found.", life: 3000 });
            }
        } catch (err) {
            console.log("error", err);
        }
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Add Block Address " icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };

    const datetimeBodyTemplate = (rowData) => {
        return new Date(rowData.createdAt).toLocaleString();
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const updateProductDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={updateHideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );

    return !checkRoles || checkRoles?.filter((item) => item.id === "0005_001").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <Toast ref={toast} />
            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
            <div className="grid UsersList-demo">
                <div className="col-12">
                    <div className="card">
                        <PageHeader title="Blocked Address" search={false} />
                        <DataTable value={addressess} dataKey="id" loading={spinner} className="datatable-responsive" emptyMessage="No data found." responsiveLayout="scroll">
                            <Column field="ind" header="#" headerStyle={{ minWidth: "8rem" }}></Column>
                            <Column field="address" header="Address" headerStyle={{ minWidth: "8rem" }}></Column>
                            <Column header="Date Time" body={datetimeBodyTemplate} headerStyle={{ minWidth: "8rem" }}></Column>
                            <Column field="note" header="Note" headerStyle={{ minWidth: "8rem" }}></Column>
                            <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        </DataTable>
                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} pages={pages} />
                    </div>
                </div>
            </div>
            {/* add social media dialog */}
            <Dialog visible={productDialog} style={{ width: "450px" }} header="Add Block Address" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="name">Address</label>
                    <InputText
                        id="name"
                        value={address}
                        type="text"
                        required
                        onChange={(e) => {
                            setAddress(e.target.value);
                        }}
                    />
                    {errAddress && <p className="p-1 err-mess">{errAddress}</p>}
                </div>
                <div className="field">
                    <label htmlFor="address">Note</label>
                    <InputTextarea id="address" value={note} rows="4" onChange={(e) => setNote(e.target.value)} />
                    {errNote && <p className="p-1 err-mess">{errNote}</p>}
                </div>
            </Dialog>

            {/* edit social media dialog */}
            <Dialog visible={updateProductDialog} style={{ width: "450px" }} header="Edit Block Address List" modal className="p-fluid" footer={updateProductDialogFooter} onHide={updateHideDialog}>
                <div className="field">
                    <label htmlFor="name">Address</label>
                    <InputText id="name" value={updateAddress} type="text" required disabled />
                    {errUpdateAddress && <p className="p-1 err-mess">{errUpdateAddress}</p>}
                </div>
                <div className="field">
                    <label htmlFor="address">Note</label>
                    <InputTextarea id="address" value={updateNote} rows="4" onChange={(e) => setUpdateNote(e.target.value)} />
                    {errUpdateNote && <p className="p-1 err-mess">{errUpdateNote}</p>}
                </div>
            </Dialog>

            <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {<span>Are you sure you want to delete?</span>}
                </div>
            </Dialog>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BlockAddress, comparisonFn);
