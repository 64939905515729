import React, { useState, useEffect, useRef, useContext } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProductService } from '../service/ProductService';
import { useHistory } from 'react-router-dom';
import AuthContext from '../AuthProvider';
import axios from 'axios'
import LoadingSpinner from '../service/LoadingSpinner';
import { CSVLink } from 'react-csv';
const apikey = process.env.REACT_APP_KEY;   

const NodesList = () => {

    const history = useHistory();
    const {roles} = useContext(AuthContext);

    // if(!roles || roles?.filter(item => item.id === "0001_005").length === 0){
    //     history.push("/unauthorized");
    // }

    const [name, setName] = useState("");
    const [ownerName, setOwnerName] =  useState("");
    const [address, setAddress] = useState("");
    const [ecode, setEcode] = useState("");
    
    const [updateName, setUpdateName] = useState("");
    const [updateOwnername, setUpdateOwnerName] =  useState("");
    const [updateAddress, setUpdateAddress] = useState("");
    const [updateEcode, setUpdateEcode] = useState("");

    const [deleteId, setDeleteId] = useState("");

    const [verificationId, setVerificationId] = useState("");
    const [verifyStatus, setVerifyStatus] = useState("");

    const [statusId, setStatusId] = useState("");
    const [status, setStatus] = useState("");

    let emptyProduct = {
        id: null,
        name: '',
        image: null,
        description: '',
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: 'INSTOCK'
    };

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductDialog2, setdeleteProductDialog2] = useState(false);
    const [deleteProductDialog3, setDeleteProductDialog3] = useState(false);
    const [deleteProductDialog4, setdeleteProductDialog4] = useState(false);

    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [startdate, setStartDate] = useState('');
    const [enddate, setEndDate] = useState('');

    const [csvAllData, setCsvAllData] = useState(null);
    
    const [loading, setLoading] = useState(true);

    const toast = useRef(null);
    const dt = useRef(null);
    
    // const [BlockStatus, setBlockStatus] = useState(0);
    // const [VerifyStatus, setVerifyStatus] = useState(0);

    useEffect(() => {
        // const productService = new ProductService();
        // productService.getProducts().then(data => setProducts(data));
        getNodesList();
    }, []);


    const getNodesList = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "explorerNodes", submethod: "get", key: apikey })
            .then(res => {
                if(res?.data.error){
                    // toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.error, life: 3000 });
                    // console.log(res.data);
                } else {
                    // toast.current.show({ severity: 'success', summary: 'Successful', detail: res.data.success, life: 3000 });
                    // console.log(res.data);
                    setProducts(res.data);
                    setLoading(false);
                    var csvData = new Array();
                    for(let i =0 ;i<res.data.length;i++){
                        csvData.push({ind: res.data[i].id, nodename: res.data[i].name, ownername: res.data[i].ownername, address: res.data[i].address, status: res.data[i].status, varification: res.data[i].verification})
                    }
                    setCsvAllData(csvData);
                }
            })
	}

    const csvReport  = {
        filename: "Explorer-Node-list.csv",
        data: csvAllData
    }

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setName("");
        setOwnerName("");
        setAddress("");
        setEcode("");
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setdeleteProductDialog2(false);
        setDeleteProductDialog3(false);
        setdeleteProductDialog4(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const saveProduct = async () => {
        // setSubmitted(true);

        // if (product.name.trim()) {
        //     let _products = [...products];
        //     let _product = { ...product };
        //     if (product.id) {
        //         const index = findIndexById(product.id);

        //         _products[index] = _product;
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
        //     }
        //     else {
        //         _product.id = createId();
        //         _product.image = 'product-placeholder.svg';
        //         _products.push(_product);
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
        //     }

        //     setProducts(_products);
        //     setProductDialog(false);
        //     setProduct(emptyProduct);
        // }

        await axios.post(process.env.REACT_APP_API_URL, {method: "explorerNodes", submethod: "insert", key: apikey, name: name, ownername: ownerName, address: address, ecode, ecode})
            .then(res => {
                if (res?.data?.error) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.error, life: 3000 });
                }
                else if (res?.data) {
                    setName("");
                    setOwnerName("");
                    setAddress('');
                    setEcode("");
                    setProductDialog(false);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: res.data.success, life: 3000 });
                    getNodesList();
                }
            })
    }

    const editProduct = (product) => {
        //setProduct({ ...product });
        setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setStatus(product.status);
        setStatusId(product._id);
        // setProduct(product);
        setDeleteProductDialog(true);
    }

    const deleteConfirmProduct = (product) => {
        setDeleteId(product._id);
        setdeleteProductDialog4(true);
    }

    const confirmDeleteProduct2 = (product) => {
        setProduct(product);
        setdeleteProductDialog2(true);
    }

    const confirmDeleteProduct3 = (product) => {
        // console.log(product.verification);
        setVerificationId(product._id);
        setVerifyStatus(product.verification);
        setDeleteProductDialog3(true);
    }

    const confirmDeleteProduct4 = (product) => {
        setProduct(product);
        setdeleteProductDialog4(true);
    }

    const deleteProduct = () => {
        let _products = products.filter(val => val.id !== product.id);
        setProducts(_products);
        setDeleteProductDialog(false);
        // setBlockStatus(1);
    }

    const deleteProduct2 = () => {
        let _products = products.filter(val => val.id !== product.id);
        setProducts(_products);
        setdeleteProductDialog2(false);
        // setBlockStatus(0);
    }

    const changeVerificationStatus = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "explorerNodes", submethod: "verify", key: apikey, id: verificationId, verification: verifyStatus })
        .then(res => {
            if (res.data.error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.error, life: 3000 });
            } else {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.success, life: 3000 });
                setVerificationId("");
                setVerifyStatus("");
                getNodesList();
                setDeleteProductDialog3(false);
            }
        })
    }

    const updateStatus = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "explorerNodes", submethod: "updateStatus", key: apikey, id: statusId, status: status })
            .then(res => {
                if (res.data.error) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.error, life: 3000 });
                } else {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.success, life: 3000 });
                    setStatusId("");
                    setStatus("");
                    getNodesList();
                    setDeleteProductDialog(false);
                }
            })
    }
    const deleteNode = async () => {
        // console.log("delete");
        await axios.post(process.env.REACT_APP_API_URL, { method: "explorerNodes", submethod: "delete", key: apikey, id: deleteId})
            .then(res => {
                if (res.data.error) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.error, life: 3000 });
                } else {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.success, life: 3000 });
                    setDeleteId("");
                    setdeleteProductDialog4(false);
                    getNodesList();
                }
            })
    }

    const deleteProduct4 = () => {
        let _products = products.filter(val => val.id !== product.id);
        setProducts(_products);
        setdeleteProductDialog4(false);
        setVerifyStatus(0);
    }


    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }


    const deleteSelectedProducts = () => {
        let _products = products.filter(val => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.id}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    }
    
    
    const ownerNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.ownername}
            </>
        );
    }
    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.address}
            </>
        );
    }
    const ecodeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.ecode}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mx-2" onClick={() => deleteConfirmProduct(rowData)} />
                {rowData.verification === "unverified" ? <Button type="button" className='p-button-success' label="Verify" onClick={() => confirmDeleteProduct3(rowData)}></Button> : <Button type="button" className='p-button p-component p-button-danger' label="Unverify" onClick={() => confirmDeleteProduct3(rowData)}></Button>}       
                &nbsp;
                {rowData.status === true ? <Button type="button"  icon="pi pi-times" className='p-button-danger' label="Block" onClick={() => confirmDeleteProduct(rowData)}></Button> : <Button type="button"  icon="pi pi-check" className='p-button p-component p-button-success' label="Unblock" onClick={() => confirmDeleteProduct(rowData)}></Button>}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Nodes List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                {
                    csvAllData ? <CSVLink className='p-button p-component p-button-secondary p-2 m-1' {...csvReport}>.CSV</CSVLink> : ""
                }
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Add Node" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </React.Fragment>
        )   
    }

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const productDialogFooter2 = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const deleteProductDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            {
                status ? <Button label="Block" icon="pi pi-check" className="p-button-text" onClick={updateStatus} /> :<Button label="Unblock" icon="pi pi-check" className="p-button-text" onClick={updateStatus} />
            }
            

        </>
    );

    const deleteProductDialogFooter2 = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="UnBlock" icon="pi pi-check" className="p-button-text" onClick={deleteProduct2} />

        </>
    );

    const deleteProductDialogFooter3 = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            {
                verifyStatus === "verified" ? <Button label="Unverify" icon="pi pi-check" className="p-button-text" onClick={changeVerificationStatus} />
                : <Button label="Verify" icon="pi pi-check" className="p-button-text" onClick={changeVerificationStatus} />

            } 
            
        </>
    );

    const deleteProductDialogFooter4 = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Delete" icon="pi pi-check" className="p-button-text" onClick={deleteNode} />

        </>
    );

    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return !roles || roles?.filter(item => item.id === "0001_005").length === 0 ? (<h4>You don't have access to this resource.</h4>) : (

        <>


            <div className="grid NodesList-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                        <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={globalFilter} emptyMessage={loading ? <LoadingSpinner /> : "data not found."} header={header} responsiveLayout="scroll">

                            <Column field="id" header="Sr No." sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="nodename" header="Node Name" sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="ownername" header="Owner Name" sortable body={ownerNameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="address" header="Address" sortable body={addressBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="endcode" header="Encode" sortable body={ecodeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                          
                            <Column field="action" header="Action" body={actionBodyTemplate} headerStyle={{ width: '100%', minWidth: '20rem' }}></Column>
                        </DataTable>

                        <Dialog visible={productDialog} style={{ width: '450px' }} header="Nodes" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            <div className="field">
                                <label htmlFor="nodename">Node Name *</label>
                                <InputText id="nodename" value={name} type="text" required onChange={e => setName(e.target.value)} />
                            </div>
                            <div className="field">
                                <label htmlFor="ownername">Owner Name *</label>
                                <InputText id="ownername" value={ownerName} type="text" required onChange={e => setOwnerName(e.target.value)} />
                            </div>
                            <div className="field">
                                <label htmlFor="address">Address *</label>
                                <InputText id="address" value={address} type="text" required onChange={e => setAddress(e.target.value)} />
                            </div>
                            <div className="field">
                                <label htmlFor="encode">Encode *</label>
                                <InputText id="encode" value={ecode} type="text" required onChange={e => setEcode(e.target.value)} />
                            </div>
                        </Dialog>

                        <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Warning" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {product && <span>Are you sure you want to {status ? "Block" : "Unblock"}?</span>}
                            </div>
                        </Dialog>

                        <Dialog visible={deleteProductDialog2} style={{ width: '450px' }} header="Warning" modal footer={deleteProductDialogFooter2} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {product && <span>Are you sure you want to unblock?</span>}
                            </div>
                        </Dialog>

                        <Dialog visible={deleteProductDialog3} style={{ width: '450px' }} header="Warning" modal footer={deleteProductDialogFooter3} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {product && <span>Are you sure want to {verifyStatus === "unverified" ? "verify" : "Unverify"} this Node?</span>}
                            </div>
                        </Dialog>

                        <Dialog visible={deleteProductDialog4} style={{ width: '450px' }} header="Warning" modal footer={deleteProductDialogFooter4} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {product && <span>Are you sure want to delete this Node?</span>}
                            </div>
                        </Dialog>


                    </div>
                </div>
            </div>

        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(NodesList, comparisonFn);