import React, { useState, useEffect, useContext, useRef } from 'react';
import AuthContext from "../AuthProvider";
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProductService } from '../service/ProductService';
import PageHeader from "./common/PageHeader";
import Pagination from './common/Pagination';
import { Paginator } from 'primereact/paginator';
import { Image } from "primereact/image";
import axios from "axios";

const WalletTokenList = () => {

    document.title = "Wallet Token List";
    const { checkRoles, verifyAndGetUser, formatDate, formatHash, stringWihtDot } = useContext(AuthContext);
    const { encuserpass } = verifyAndGetUser();

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);

    const [first, setFirst] = useState(0);
    // const [rows, setRows] = useState(10);

    const onPageChange = (event) => {
        setFirst(event.first);
        // setRows(event.rows);
        setPageSize(event.rows)
        setCurrentPage(event.page + 1);


    };

    const [walletTokenData, setWalletTokenData] = useState([]);
    const [spinner, setSpinner] = useState(true);

    const [statusValue, setStatusValue] = useState(false);


    const [deleteChainId, setDeleteChainId] = useState(null);
    const [deleteWalletTokenDialog, setDeleteWalletTokenDialog] = useState(false);

    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);

    const [logo, setLogo] = useState("");
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [symbol, setSymbol] = useState("");
    const [decimals, setDecimals] = useState("");
    const [totalsupply, setTotalsupply] = useState("");
    const [chainid, setChainid] = useState("");
    const [abi, setAbi] = useState("");
    const [status, setStatus] = useState("");

    const [selectedFile, setSelectedFile] = useState('');
    const [errorMsg, setErrorMsg] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [walletTokenDialog, setWalletTokenDialog] = useState(false);

    const [editWalletTokenDialog, setEditWalletTokenDialog] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    const getWalletTokenList = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/wallettoken/getall`, {
                params: {
                    limit: pageSize,
                    page: currentPage,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getWalletTokenList : ", res.data.error);
                } else {
                    setWalletTokenData(res.data.items);
                    setPages(res.data.pages);
                    setTotalRecords(res.data.total);
                    setSpinner(false);
                }
            });
    };

    useEffect(() => {
        getWalletTokenList();
    }, [currentPage, pageSize]);

    useEffect(() => {
    }, []);

    const openNewWalletTokenDailog = () => {
        setErrorMsg("");
        setIsSubmit(false);
        setWalletTokenDialog(true);
    }

    const clearFields = () => {
        setName("")
        setAddress("")
        setSymbol("")
        setDecimals("")
        setTotalsupply("")
        setChainid("")
        setAbi("")
        setSelectedFile("")
    }

    const hideWalletTokenDialog = () => {
        clearFields()
        setIsSubmit(false);
        setWalletTokenDialog(false);
    }

    const openEditWalletTokenDailog = () => {
        setErrorMsg("");
        setSubmitted(false)
        setEditWalletTokenDialog(true);
    }

    const hideEditWalletTokenDialog = () => {

        setChainid("")

        clearFields()
        setSubmitted(false)
        setEditWalletTokenDialog(false);
    }

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }

    };

    const validateSelectedFile = () => {
        // const MIN_FILE_SIZE = 1024 // 1MB
        // const MAX_FILE_SIZE = 5120 // 5MB
        const MAX_FILE_SIZE = 2048 // 2MB

        if (!selectedFile) {
            // setErrorMsg("Please choose a file");
            setIsSuccess(false)
            return
        }

        const fileSizeKiloBytes = selectedFile.size / 1024

        // if(fileSizeKiloBytes < MIN_FILE_SIZE){
        //   setErrorMsg("File size is less than minimum limit");
        //   setIsSuccess(false)
        //   return
        // }

        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
            setSelectedFile("")
            setErrorMsg("File size is greater than maximum limit");
            setIsSuccess(false)
            return
        }

        setErrorMsg("")
        setIsSuccess(true)
    };



    const saveWalletToken = async () => {

        let temp = 1;

        setIsSubmit(true);
        try {


            const MAX_FILE_SIZE = 2048 // 2MB

            if (selectedFile) {
                const fileSizeKiloBytes = selectedFile.size / 1024
                if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                    setErrorMsg("File size is greater than maximum limit");
                    temp = 0;
                } else {
                    setErrorMsg("")
                }
            } else {
                temp = 0;
            }

            if (!name) {
                temp = 0;
                console.log("name :- ", name);
            }
            if (!address) {
                temp = 0;
                console.log("address :- ", address);
            }
            if (!symbol) {
                temp = 0;
                console.log("symbol :- ", symbol);
            }
            if (!decimals) {
                temp = 0;
                console.log("decimals :- ", decimals);
            }
            if (!totalsupply) {
                temp = 0;
                console.log("totalsupply :- ", totalsupply);
            }
            if (!chainid) {
                temp = 0;
                console.log("chainid :- ", chainid);
            }
            if (!abi) {
                temp = 0;
                console.log("abi :- ", abi);
            }



            if (temp === 1) {

                const formData = new FormData()

                formData.append("name", name)
                formData.append("address", address)
                formData.append("symbol", symbol)
                formData.append("decimals", decimals)
                formData.append("totalsupply", totalsupply)
                formData.append("chainid", chainid)
                formData.append("abi", abi)

                formData.append("file", selectedFile)

                await axios.post(`${process.env.REACT_APP_API_URL}admin/wallettoken/insert`, formData, {
                    headers: {
                        etoken: encuserpass,
                    }
                }).then((res) => {
                    console.log("res :- ", res);
                    if (res.data.error) {
                        toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                    } else {
                        toast.current.show({ severity: "success", summary: "Successful", detail: "Token Saved", life: 3000 });
                        getWalletTokenList();
                        hideWalletTokenDialog();
                        clearFields()


                    }
                });
            }

        } catch (error) {
            console.log("error in saveWalletToken (catch) :", error);
        }

    }




    const editWalletToken = async (data) => {
        console.log("editWalletToken id :- ", data.chainid);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/wallettoken/gettoken/${data.address}`, {
                headers: {
                    etoken: encuserpass,
                },
            })
            .then(res => {
                console.log("res :- ", res);
                if (res.data.error) {
                    alert(res.data.error)
                    toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                }
                else {

                    console.log("data :- ", res.data);
                    setChainid(res.data.chainid)

                    setName(res.data.name)
                    setAddress(res.data.address)
                    setSymbol(res.data.symbol)
                    setDecimals(res.data.decimals)
                    setTotalsupply(res.data.totalsupply)
                    setLogo(res.data.logo)
                    setAbi(res.data.abi)



                    setEditWalletTokenDialog(true);

                }
            })
    }

    const updateWalletToken = async () => {

        let temp = 1;
        setSubmitted(true)
        try {



            const MAX_FILE_SIZE = 2048 // 2MB

            if (selectedFile) {
                const fileSizeKiloBytes = selectedFile.size / 1024
                if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                    setErrorMsg("File size is greater than maximum limit");
                    temp = 0;
                } else {
                    setErrorMsg("")
                }
            }

            if (!name) {
                temp = 0;
                console.log("name :- ", name);
            }
            if (!address) {
                temp = 0;
                console.log("address :- ", address);
            }
            if (!symbol) {
                temp = 0;
                console.log("symbol :- ", symbol);
            }
            if (!decimals) {
                temp = 0;
                console.log("decimals :- ", decimals);
            }
            if (!totalsupply) {
                temp = 0;
                console.log("totalsupply :- ", totalsupply);
            }
            if (!chainid) {
                temp = 0;
                console.log("chainid :- ", chainid);
            }
            if (!abi) {
                temp = 0;
                console.log("abi :- ", abi);
            }



            if (temp === 1) {


                const formData = new FormData()
                formData.append("name", name)
                formData.append("address", address)
                formData.append("symbol", symbol)
                formData.append("decimals", decimals)
                formData.append("totalsupply", totalsupply)
                formData.append("chainid", chainid)
                formData.append("abi", abi)

                if (selectedFile) {
                    formData.append("file", selectedFile)
                }

                await axios.post(`${process.env.REACT_APP_API_URL}admin/wallettoken/update`, formData, {
                    headers: {
                        etoken: encuserpass,
                    }
                }).then((res) => {
                    console.log("res :- ", res);
                    if (res.data.error) {
                        toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                    } else {
                        toast.current.show({ severity: "success", summary: "Successful", detail: "Token Updated", life: 3000 });
                        getWalletTokenList();
                        hideEditWalletTokenDialog();
                        setSelectedFile("")
                    }
                });
            }

        } catch (error) {
            console.log("error in updateWalletToken (catch) :", error);
        }

    }

    const confirmDeleteWalletToken = (data) => {
        // setProduct(data);
        setDeleteChainId(data.address);
        setDeleteWalletTokenDialog(true);
    }

    const deleteWalletToken = async (chainId) => {
        console.log("ChainId :- ", chainId);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/wallettoken/delete/${chainId}`, {
                headers: {
                    etoken: encuserpass,
                },
            })
            .then(res => {
                console.log("res :- ", res);
                if (res.data.error) {
                    alert(res.data.error)
                    toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                }
                else {
                    getWalletTokenList();
                    console.log(res.data);
                    setDeleteWalletTokenDialog(false);
                    setDeleteChainId("");
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Deleted', life: 3000 });
                }
            })

    }

    const changeStatus = async (data) => {
        console.log("data :- ", data);
        let updateStatus = true;
        if (data.status) {
            updateStatus = false;
        } else {
            updateStatus = true;
        }

        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/wallettoken/changestatus/${data.address}`, {
                params: {
                    status: updateStatus,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then(res => {
                console.log("res :- ", res);
                if (res.data.error) {
                    alert(res.data.error)
                }
                else {
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Status Updated", life: 3000 });
                    getWalletTokenList();
                    console.log(res.data);
                }
            })
        console.log("verify data._id :- ", data._id);
    }

    const hideDeleteWalletTokenDialog = () => {
        setDeleteWalletTokenDialog(false);
        setDeleteChainId("");
    }



    const walletTokenListLeftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNewWalletTokenDailog} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const addressBodyTemplate = (rowData) => {
        // return <span className={`kyc-badge status-${rowData.status}`}>{rowData.status.toString()}</span>;
        return <a href={`${process.env.REACT_APP_EXPLORER_URL}address/${rowData.address}`} target="_blank"> {rowData.address}</a>
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editWalletToken(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteWalletToken(rowData)} />
            </div>
        );
    }

    const statusActionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button label="Change" className="p-button-rounded p-button-info mr-2 mb-2" onClick={() => changeStatus(rowData)} />
            </div>
        );
    }
    const logoImageBodyTemplate = (rowData) => {
        return <Image src={`${process.env.REACT_APP_WALLET_TOKEN_LOGO_URL}${rowData.logo}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt="logo" width={150} />
    }



    const walletTokenStatusBodyTemplate = (rowData) => {
        // return <span className={`kyc-badge status-${rowData.status}`}>{rowData.status.toString()}</span>;
        return rowData.status.toString();
    }

    const walletTokenListTableHeader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Token List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const walletTokenDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideWalletTokenDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveWalletToken} />
        </>
    );

    const editWalletTokenDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideEditWalletTokenDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateWalletToken} />
        </>
    );

    const deleteWalletTokenDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteWalletTokenDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => deleteWalletToken(deleteChainId)} />
        </>
    );

    return (
        <div className="grid crud-demo">

            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={walletTokenListLeftToolbarTemplate} ></Toolbar>

                    <PageHeader title="Manage Token List" />
                    <DataTable value={walletTokenData} dataKey="id" loading={spinner} className="datatable-responsive"
                        globalFilter={globalFilter} emptyMessage="No data found." responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="ind" header="No." headerStyle={{ minWidth: '8rem' }}></Column>

                        <Column field="name" header="Name" headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                        <Column field="logo" header="Logo" body={logoImageBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                        <Column field="address" header="Address" body={addressBodyTemplate} headerStyle={{ minWidth: '8rem' }}></Column>
                        <Column field="symbol" header="Symbol" headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                        <Column field="decimals" header="Decimals" headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                        <Column field="totalsupply" header="Total Supply" headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                        <Column field="chainid" header="Chain Id" headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                        <Column field="status" header="Status" body={walletTokenStatusBodyTemplate} headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                        <Column header="Action" body={actionBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} ></Column>
                        <Column header="Status Change" body={statusActionBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }} ></Column>

                    </DataTable>
                    <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} pages={pages} />
                    {/* <Paginator first={first} rows={pageSize} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} /> */}

                    <Dialog visible={walletTokenDialog} style={{ width: '450px' }} header="Token Details" modal className="p-fluid" footer={walletTokenDialogFooter} onHide={hideWalletTokenDialog}>
                        <div className="field">
                            <label htmlFor="name">Logo</label>
                            <div>
                                <input type="file" name='file' accept="image/jpeg, image/png, image/jpg" onChange={handleFileChange} />
                                <div className="space-between">
                                    {/* <p className="info-message">Min size: 1MB</p> */}
                                    <p className="info-message">Max size: 2MB</p>
                                </div>
                                {errorMsg && <p className="error-message">{errorMsg}</p>}
                                {isSubmit && !selectedFile && <small className="p-invalid">Logo is required.</small>}
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="name">Name</label>
                            {/* <InputText id="name" value={product.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.name })} /> */}
                            <InputText id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} required autoFocus className={classNames({ 'p-invalid': isSubmit && !name })} />
                            {isSubmit && !name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="address">Address</label>
                            <InputText id="address" type="text" value={address} onChange={(e) => setAddress(e.target.value)} required className={classNames({ 'p-invalid': isSubmit && !address })} />
                            {isSubmit && !address && <small className="p-invalid">Address is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Symbol</label>
                            <InputText id="name" type="text" value={symbol} onChange={(e) => setSymbol(e.target.value)} required className={classNames({ 'p-invalid': isSubmit && !symbol })} />
                            {isSubmit && !symbol && <small className="p-invalid">Symbol is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="decimals">Decimals</label>
                            <InputText id="decimals" type="text" value={decimals} onChange={(e) => setDecimals(e.target.value)} required className={classNames({ 'p-invalid': isSubmit && !decimals })} />
                            {isSubmit && !decimals && <small className="p-invalid">Decimals is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="totalsupply">Total Supply</label>
                            <InputText id="totalsupply" type="text" value={totalsupply} onChange={(e) => setTotalsupply(e.target.value)} required className={classNames({ 'p-invalid': isSubmit && !totalsupply })} />
                            {isSubmit && !totalsupply && <small className="p-invalid">Total Supply is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Chain Id</label>
                            <InputText id="name" type="text" value={chainid} onChange={(e) => setChainid(e.target.value)} required className={classNames({ 'p-invalid': isSubmit && !chainid })} />
                            {isSubmit && !chainid && <small className="p-invalid">Chain Id is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="abi">Abi</label>
                            <InputTextarea id="abi" value={abi} onChange={(e) => setAbi(e.target.value)} required rows={3} cols={20} className={classNames({ 'p-invalid': isSubmit && !abi })} />
                            {isSubmit && !abi && <small className="p-invalid">Abi is required.</small>}
                        </div>

                    </Dialog>

                    <Dialog visible={editWalletTokenDialog} style={{ width: '450px' }} header="Chain Details" modal className="p-fluid" footer={editWalletTokenDialogFooter} onHide={hideEditWalletTokenDialog}>
                        {/*logo && <Image src={`${process.env.REACT_APP_CHAIN_LOGO_URL}${logo}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt="logo" width={150} /> */}
                        {logo && <img src={`${process.env.REACT_APP_WALLET_TOKEN_LOGO_URL}${logo}`} alt={logo} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        <div className="field">
                            <label htmlFor="name">Logo</label>
                            <div>
                                <input type="file" name='file' accept="image/jpeg, image/png, image/jpg" onChange={handleFileChange} />
                                <div className="space-between">
                                    {/* <p className="info-message">Min size: 1MB</p> */}
                                    <p className="info-message">Max size: 2MB</p>
                                </div>
                                {errorMsg && <p className="error-message">{errorMsg}</p>}
                            </div>



                        </div>

                        <div className="field">
                            <label htmlFor="name">Name</label>
                            {/* <InputText id="name" value={product.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.name })} /> */}
                            <InputText id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} required autoFocus className={classNames({ 'p-invalid': submitted && !name })} />
                            {submitted && !name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="address">Address</label>
                            <InputText id="address" type="text" value={address} onChange={(e) => setAddress(e.target.value)} required className={classNames({ 'p-invalid': submitted && !address })} disabled />
                            {submitted && !address && <small className="p-invalid">Address is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Symbol</label>
                            <InputText id="name" type="text" value={symbol} onChange={(e) => setSymbol(e.target.value)} required className={classNames({ 'p-invalid': submitted && !symbol })} />
                            {submitted && !symbol && <small className="p-invalid">Symbol is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="decimals">Decimals</label>
                            <InputText id="decimals" type="text" value={decimals} onChange={(e) => setDecimals(e.target.value)} required className={classNames({ 'p-invalid': submitted && !decimals })} disabled />
                            {submitted && !decimals && <small className="p-invalid">Decimals is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="totalsupply">Total Supply</label>
                            <InputText id="totalsupply" type="text" value={totalsupply} onChange={(e) => setTotalsupply(e.target.value)} required className={classNames({ 'p-invalid': submitted && !totalsupply })} />
                            {submitted && !totalsupply && <small className="p-invalid">Total Supply is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Chain Id</label>
                            <InputText id="name" type="text" value={chainid} onChange={(e) => setChainid(e.target.value)} required className={classNames({ 'p-invalid': submitted && !chainid })} disabled />
                            {submitted && !chainid && <small className="p-invalid">Chain Id is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="abi">Abi</label>
                            <InputTextarea id="abi" value={abi} onChange={(e) => setAbi(e.target.value)} required rows={3} cols={20} className={classNames({ 'p-invalid': submitted && !abi })} />
                            {submitted && !abi && <small className="p-invalid">Abi is required.</small>}
                        </div>

                    </Dialog>

                    <Dialog visible={deleteWalletTokenDialog} style={{ width: '450px' }} header="Delete Confirmation" modal footer={deleteWalletTokenDialogFooter} onHide={hideDeleteWalletTokenDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {deleteWalletTokenDialog && <span>Do you want to delete this record? </span>}
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(WalletTokenList, comparisonFn);