import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";

import { Button } from "primereact/button";

import { InputText } from "primereact/inputtext";
import { ProductService } from "../service/ProductService";

import { TabMenu } from "primereact/tabmenu";
import { Route, useHistory, useLocation, useParams } from "react-router-dom";
import AuthContext from "../AuthProvider";
import { PersonalDemo } from "../components/menu/PersonalDemo";
import { ConfirmationDemo } from "../components/menu/ConfirmationDemo";
import { PaymentDemo } from "../components/menu/PaymentDemo";
import { SeatDemo } from "../components/menu/SeatDemo";

import BlockViewer from "../BlockViewer";

import axios from "axios";
import LoadingSpinner from "../service/LoadingSpinner";

const apikey = process.env.REACT_APP_KEY;

const TokenDetails = () => {
    const history = useHistory();
    const location = useLocation();
    const { id } = useParams();

    const { checkRoles, verifyAndGetUser, stringWihtDot } = useContext(AuthContext);
    const { encuserpass } = verifyAndGetUser();

    const [getDataId, setGetDataId] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [reload, setReload] = useState(false);
    const [spinner, setSpinner] = useState(true);

    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };

    const wizardItems = [
        { label: "Personal", command: () => history.push("/menu") },
        { label: "Seat", command: () => history.push("/menu/seat") },
        { label: "Payment", command: () => history.push("/menu/payment") },
        { label: "Confirmation", command: () => history.push("/menu/confirmation") },
    ];

    const [products, setProducts] = useState(null);
    const [addressDetails, setAddressDetails] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

    useEffect(() => {
        getVerifiedToken();
        getAddressData();
    }, [reload]);

    const getVerifiedToken = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/requestedtokendetails/${id}`, {
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setProducts(res.data);
                    setSpinner(false);
                }
            });
    };
    const getAddressData = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/addressdetails/${id}`, {
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setAddressDetails(res.data);
                }
            });
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Token Details</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    return !checkRoles ? (
        <LoadingSpinner />
    ) : checkRoles?.filter((item) => item.id === "0001_006").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <div className="grid">
                <div className="col-12 md:col-4">
                    <div className="card p-fluid">
                        <img src={process.env.REACT_APP_IMAGE_TOKENLOGO + products?.logo} alt="bamboo-watch.jpg" className="shadow-2" width="100%" />
                    </div>

                    <div className="card p-fluid">
                        {/* <h5><b>Ignite (IGT)</b></h5> */}
                        <h5>
                            <b>{`${products && products?.name} (${products && products?.symbol})`}</b>
                        </h5>
                        {/* <h6>{`${products && products[0].name} (${products && products[0].symbol})`}</h6> */}
                        <h5>
                            <b>Total Supply</b>
                        </h5>
                        <h6>{products && products?.totalSupplyNumber}</h6>
                        {/* <h5>
                            <b>Holders</b>
                        </h5>
                        <h6>{products && products?.holders}</h6>
                        <h5>
                            <b>Total Transfers</b>
                        </h5>
                        <h6>{products && products?.txCount}</h6>
                        <h5>
                            <b>Market Cap</b>
                        </h5>
                        <h6>
                            <i className="pi pi-dollar"></i>
                            {products && products[0].marketCap}
                        </h6> */}
                    </div>
                </div>

                <div className="col-12 md:col-8">
                    <BlockViewer data={products} setReload={setReload} reload={reload} header="Token Details" containerClassName="surface-section px-4 py-5 md:px-6 lg:px-8" btnhide={true}>
                        <div className="surface-section">
                            <ul className="list-none p-0 m-0">
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Website</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                        <a href={products && products?.info?.official_project_Website} target="_blank">
                                            {products && products?.info?.official_project_Website}
                                        </a>
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Requester Name</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">{products && products?.info?.requester_name}</div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Email</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">{products && products?.info?.official_project_email_address}</div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Contract Address</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                        <a href={`${process.env.REACT_APP_ADDRESS_BASE_URL}address/${products && products?.address}`} target="_blank">
                                            {products && products?.address}
                                        </a>
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Token Creator</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                        <a href={`${process.env.REACT_APP_ADDRESS_BASE_URL}address/${addressDetails && addressDetails?.contractCreater}`} target="_blank">
                                            {addressDetails && addressDetails?.contractCreater}
                                        </a>
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Hash #</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                        <a href={`${process.env.REACT_APP_ADDRESS_BASE_URL}tx/${addressDetails && addressDetails.contractHash}`} target="_blank">
                                            {addressDetails && addressDetails?.contractHash}
                                        </a>
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Decimal</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{products && products?.decimals}</div>
                                </li>
                                {/* <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Solidity Compiler Version</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">{products && products.compilerVersion}</div>
                                </li> */}
                                {/* <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Price</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">0</div>
                                </li> */}
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Message</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">{products && products?.info?.comment_or_message}</div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Requester Email Address</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">{products && products?.info?.requester_email_address}</div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Project Sector</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">{products && products?.info?.project_sector}</div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Project Description</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">{products && products?.info?.project_description}</div>
                                </li>
                                {/* <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Is Code Verified?</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">Yes{products && products[0].codeVerified ? "Yes" : "No"}</div>
                                </li> */}
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Whitepaper</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                        <a href={products && products?.info?.social_whitepaper} target="_blank">
                                            {products && products?.info?.social_whitepaper}
                                        </a>
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Github</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                        <a href={products && products?.info?.social_github} target="_blank">
                                            {products && products?.info?.social_github}
                                        </a>
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Twitter</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                        <a href={products && products?.info?.social_twitter} target="_blank">
                                            {products && products?.info?.social_twitter}
                                        </a>
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Facebook</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                        <a href={products && products?.info?.social_facebook} target="_blank">
                                            {products && products?.info?.social_facebook}
                                        </a>
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Telegram</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                        <a href={products && products?.info?.social_telegram} target="_blank">
                                            {products && products?.info?.social_telegram}
                                        </a>
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Reddit</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                        <a href={products && products?.info?.social_reddit} target="_blank">
                                            {products && products?.info?.social_reddit}
                                        </a>
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Medium</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                        <a href={products && products?.info?.social_medium} target="_blank">
                                            {products && products?.info?.social_medium}
                                        </a>
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">CoinMarketCap</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                        <a href={products && products?.info?.coinmarketcap_ticker} target="_blank">
                                            {products && products?.info?.coinmarketcap_ticker}
                                        </a>
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">CoinGecko</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                        <a href={products && products?.info?.coingecko_ticker} target="_blank">
                                            {products && products?.info?.coingecko_ticker}
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </BlockViewer>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TokenDetails, comparisonFn);
