import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { useHistory } from "react-router-dom";
import moment from "moment";

const apikey = process.env.REACT_APP_KEY;

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [roles, setRoles] = useState([]);
    const [adminRoles, setAdminRoles] = useState(null);
    const [checkRoles, setCheckRoles] = useState(null);

    const history = useHistory();

    const verifyAndGetUser = () => {
        if (localStorage.getItem("auda5s21samn") && localStorage.getItem("aeda4sd4famm") && localStorage.getItem("apdn4sec3f1ms")) {
            const username = CryptoJS.AES.decrypt(localStorage.getItem("auda5s21samn"), process.env.REACT_APP_PASSWORD).toString(CryptoJS.enc.Utf8);
            const email = CryptoJS.AES.decrypt(localStorage.getItem("aeda4sd4famm"), process.env.REACT_APP_PASSWORD).toString(CryptoJS.enc.Utf8);
            const password = CryptoJS.AES.decrypt(localStorage.getItem("apdn4sec3f1ms"), process.env.REACT_APP_PASSWORD).toString(CryptoJS.enc.Utf8);
            const encusername = CryptoJS.AES.encrypt(JSON.stringify({ username: username }), process.env.REACT_APP_PASSWORD).toString();
            const encuserpass = CryptoJS.AES.encrypt(JSON.stringify({ username: username, password: password }), process.env.REACT_APP_PASSWORD).toString();
            return { isLogin: true, username, email, password, encusername, encuserpass };
        } else {
            localStorage.removeItem("auda5s21samn");
            localStorage.removeItem("aeda4sd4famm");
            localStorage.removeItem("apdn4sec3f1ms");
            return { isLogin: false };
        }
    };

    const verifyAdmin = async () => {
        const { username, password } = verifyAndGetUser();
        var encbody = CryptoJS.AES.encrypt(JSON.stringify({ username: username, password: password }), process.env.REACT_APP_PASSWORD).toString();
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}admin/signin`, { token: encbody }).then((res) => {
                if (res?.data?.error) {
                    console.log("error in verifyAdmin : ", res.data.error);
                    logout();
                } else {
                }
            });
        } catch (err) {
            console.log("error in handleAdminLogin() (catch)", err);
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);

    const stringWihtDot = (str, val) => {
        let substr = str.substr(0, val);
        return substr + "...";
    };

    const formatHash = (hash) => {
        return hash.substr(0, 5) + "..." + hash.substr(-5, 5)
    }

    const formatDate = (val) => {
        return moment(val).format("DD-MM-YYYY hh:mm:ss")
    };

    const genTokenWithTstemp = (dataObj) => {
        return CryptoJS.AES.encrypt(
            JSON.stringify({
                ...dataObj,
                timestemp: Math.round(Date.now() / 1000),
            }),
            process.env.REACT_APP_PASSWORD
        ).toString();
    };

    const logout = () => {
        localStorage.removeItem("auda5s21samn");
        localStorage.removeItem("aeda4sd4famm");
        localStorage.removeItem("apdn4sec3f1ms");
        history.push("/");
    };

    return <AuthContext.Provider value={{ roles, setRoles, adminRoles, setAdminRoles, verifyAndGetUser, stringWihtDot, formatHash, genTokenWithTstemp, logout, verifyAdmin, formatDate, checkRoles, setCheckRoles }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
