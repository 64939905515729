import React, { useState, useEffect, useRef, useContext } from "react";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProductService } from "../service/ProductService";
import EmptyDataMessage from "./common/EmptyDataMessage";

// import { UserRoles } from '../App';
import { FileUpload } from "primereact/fileupload";
import { Checkbox } from "primereact/checkbox";

import { Dropdown } from "primereact/dropdown";

import axios from "axios";
import LoadingSpinner from "../service/LoadingSpinner";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../AuthProvider";
import { CSVLink } from "react-csv";

const apikey = process.env.REACT_APP_KEY;

const AdminList = () => {
    document.title = "Sub Admin";

    const history = useHistory();
    const { checkRoles, verifyAndGetUser, stringWihtDot } = useContext(AuthContext);
    const { encuserpass } = verifyAndGetUser();

    // if(!roles || roles?.filter(item => item.id === "0003_001").length === 0){
    //     history.push("/unauthorized");
    // }

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [deleteId, setDeleteId] = useState("");

    const [adminList, setAdminList] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);

    const [dispPass, setDispPass] = useState("");

    const [adminRoles, setAdminRoles] = useState("");

    const [csvAllData, setCsvAllData] = useState(null);

    const [dropdownItem, setDropdownItem] = useState(null);
    const dropdownItems = [
        { name: "Assets", code: "Assets" },
        { name: "NFT", code: "NFT" },
    ];

    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [EnabledProductDialog, setEnabledProductDialog] = useState(false);
    const [rolesDialog, setRolesDialog] = useState(false);

    const [productDialog2, setProductDialog2] = useState(false);

    const [startdate, setStartDate] = useState("");
    const [enddate, setEndDate] = useState("");

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        getAdminList();
    }, [currentPage, pageSize]);

    const getAdminList = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/adminuserlist`, {
                params: {
                    limit: pageSize,
                    page: currentPage,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setAdminList(res.data.items);
                    setPages(res.data.pages);
                    setSpinner(false);
                }
            });
    };

    const csvReport = {
        filename: "Subadmin-list.csv",
        data: csvAllData,
    };

    const onUpload = () => {
        toast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded", life: 3000 });
    };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };

    const openNew2 = () => {
        // setProduct(emptyProduct);
        // setSubmitted(false);
        setProductDialog2(true);
    };

    const hideDialog = () => {
        setUsername("");
        setPassword("");
        setSubmitted(false);
        setProductDialog(false);
        setProductDialog2(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setEnabledProductDialog(false);
    };
    const hideRolesDialog = () => {
        setRolesDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = async () => {
        // await axios.post(process.env.REACT_APP_API_URL, { method: "admin", submethod: "insert", key: apikey, username: username, password: password, access: adminCheckRole }).then((res) => {
        //     if (res.data.error) {
        //         toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
        //     } else if (res.data.success) {
        //         setUsername("");
        //         setPassword("");
        //         setProductDialog(false);
        //         getAdminList();
        //         toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
        //     }
        // });
    };

    const editProduct = () => {};

    const confirmDeleteProduct = () => {
        setDeleteProductDialog(true);
    };

    const confirmEnabledProduct = (product) => {
        setProduct(product);
        setEnabledProductDialog(true);
    };

    const deleteProduct = async () => {
        try {
            await axios
                .post(
                    `${process.env.REACT_APP_API_URL}admin/editadmin`,
                    { uname: deleteId },
                    {
                        params: {
                            action: "delete",
                        },
                        headers: {
                            etoken: encuserpass,
                        },
                    }
                )
                .then((res) => {
                    if (res.data.error) {
                        console.log("error in updating status : ", res.data.error);
                    } else {
                        getAdminList();
                        setDeleteProductDialog(false);
                    }
                });
        } catch (err) {
            console.log("error in updating status (catch) :", err);
        }
    };
    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    };

    const passwordBodyTemplate = (rowData) => {
        return (
            <>
                <div className="flex">
                    {/* <Button icon="pi pi-bookmark text-2xl" className="p-button-rounded p-button-secondary p-button-text" aria-label="Bookmark" /> */}
                    {dispPass != rowData._id ? (
                        <Button icon="pi pi-eye text-2xl" className="p-button-rounded p-button-secondary p-button-text mr-2" onClick={() => setDispPass(rowData._id)} />
                    ) : (
                        <Button icon="pi pi-eye-slash text-2xl" className="p-button-rounded p-button-secondary p-button-text mr-2" onClick={() => setDispPass("")} />
                    )}
                    {/* <span className="p-column-title">Name</span>     */}
                    <div className="flex align-items-center ml-1">{dispPass != rowData._id ? "*".repeat(rowData.password.length) : rowData.password}</div>
                </div>
            </>
        );
    };

    const pwdBodyTemplate = (rowData) => {
        return stringWihtDot(rowData.password, 16);
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Link to={`/edit-admin/${rowData._id}`}>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1" />
                </Link>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mx-1" onClick={() => confirmDeleteProduct(rowData)} />
                <Button label="Roles" className="p-button p-button-primary mx-1" onClick={() => setRolesDialog(true)} />
            </div>
        );
    };

    const actionsBodyTemplate = (rowData) => {
        return rowData?.superadmin ? (
            ""
        ) : (
            <div className="actions">
                <Link to={`/edit-admin/${rowData.username}`}>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1" />
                </Link>
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger mx-1"
                    onClick={() => {
                        confirmDeleteProduct();
                        setDeleteId(rowData.username);
                    }}
                />
                {/* <Button label="Roles" className="p-button p-button-primary mx-1" onClick={() => setRolesDialog(true)} /> */}
            </div>
        );
    };

    const enabledactionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-check" className="p-button-rounded p-button-success mr-2" onClick={() => confirmEnabledProduct(rowData)} />
            </div>
        );
    };

    const addToken = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew2} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Sub Admin List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                {csvAllData ? (
                    <CSVLink className="p-button p-component p-button-secondary p-2 m-1" {...csvReport}>
                        .CSV
                    </CSVLink>
                ) : (
                    ""
                )}
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const rolesDialogFooter = (
        <>
            <Button label="Ok" className="p-button-text" onClick={hideRolesDialog} />
        </>
    );
    const deleteProductDialogFooter2 = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Disable" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );

    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return !checkRoles ? (
        <LoadingSpinner />
    ) : checkRoles?.filter((item) => item.id === "0003_001").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <div className="grid AdminList-demo">
                {/* <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toast ref={toast} />
                        <PageHeader title="UserList" />
                        <table className="table" cellPadding={0} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Password</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {adminList && adminList?.length
                                    ? adminList?.map((item, index) => {
                                        const ind = currentPage * (pageSize - pageSize) + (index + 1);
                                        return (
                                            <tr>
                                                <td>{ind}</td>
                                                <td>{item.username}</td>
                                                <td>{item.email}</td>
                                                <td>{stringWihtDot(item.password, 16)}</td>
                                                <td>
                                                    <div className="actions">
                                                        <Link to={`/edit-admin/${item.username}`}>
                                                            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1" />
                                                        </Link>
                                                        <Button
                                                            icon="pi pi-trash"
                                                            className="p-button-rounded p-button-danger mx-1"
                                                            onClick={() => {
                                                                confirmDeleteProduct();
                                                                setDeleteId(item.username);
                                                            }}
                                                        />
                                                        <Button label="Roles" className="p-button p-button-primary mx-1" onClick={() => setRolesDialog(true)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : ""}
                            </tbody>
                        </table>
                        {spinner ? <LoadingSpinner /> : adminList && adminList?.length ? "" : <EmptyDataMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />}
                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} pages={pages} />

                        <Dialog visible={productDialog} style={{ width: "450px" }} header="Admin" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            <div className="field">
                                <label htmlFor="username">User Name *</label>
                                <InputText
                                    id="username"
                                    value={username}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="password">Password *</label>
                                <InputText
                                    id="password"
                                    value={password}
                                    type="password"
                                    required
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>
                            <label>Allowed Field</label>
                        </Dialog>

                        <Dialog visible={productDialog2} style={{ width: "450px" }} header="Tokens" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            <div className="field">
                                <label htmlFor="image">Logo *</label>
                                <div className="card">
                                    <FileUpload mode="basic" name="image" accept="image/*" maxFileSize={2000000} onUpload={onUpload} />
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="chain_name">Chain Name *</label>
                                <InputText id="chain_name" type="text" required />
                            </div>
                            <div className="field">
                                <label htmlFor="contract_address">Contract Address *</label>
                                <InputText id="contract_address" type="text" required />
                            </div>
                            <div className="field">
                                <label htmlFor="token_name">Token Name *</label>
                                <InputText id="token_name" type="text" required />
                            </div>
                            <div className="field">
                                <label htmlFor="token_ticker">Token Ticker *</label>
                                <InputText id="token_ticker" type="text" required />
                            </div>
                            <div className="field">
                                <label htmlFor="decimal">Decimal *</label>
                                <InputText id="decimal" type="text" required />
                            </div>
                            <div className="field">
                                <label htmlFor="abi">decimal *</label>
                                <InputText id="abi" type="text" required />
                            </div>
                            <div className="field">
                                <label htmlFor="url">Token type *</label>
                                <Dropdown id="state" value={dropdownItem} onChange={(e) => setDropdownItem(e.value)} options={dropdownItems} optionLabel="name" placeholder="Select Token Type"></Dropdown>
                            </div>
                        </Dialog>

                        <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {product && <span>Are you sure you want to delete?</span>}
                            </div>
                        </Dialog>

                        <Dialog visible={rolesDialog} style={{ width: "450px" }} header="Warning" modal footer={rolesDialogFooter} onHide={hideRolesDialog}>
                            <div className="flex">
                                <ol>
                                    {adminRoles &&
                                        adminRoles.map((item, ind) => {
                                            return <li key={ind}>{item.name}</li>;
                                        })}
                                </ol>
                            </div>
                        </Dialog>
                    </div>
                </div> */}

                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        <PageHeader title="UserList" />
                        <DataTable value={adminList} dataKey="id" loading={spinner} className="datatable-responsive" globalFilter={globalFilter} emptyMessage="No data found." responsiveLayout="scroll">
                            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}

                            <Column field="ind" header="No" headerStyle={{ width: "10%", minWidth: "8rem" }}></Column>
                            <Column field="username" header="Username" headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>
                            <Column field="email" header="Email" headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>
                            <Column field="password" header="Password" body={pwdBodyTemplate} headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>

                            <Column header="Actions" body={actionsBodyTemplate} headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>
                            {/* <Column header="Status Change" body={statusActionBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }} ></Column> */}
                        </DataTable>
                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} pages={pages} />
                        {/* <Paginator first={first} rows={pageSize} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} /> */}

                        <Dialog visible={productDialog} style={{ width: "450px" }} header="Admin" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            <div className="field">
                                <label htmlFor="username">User Name *</label>
                                <InputText
                                    id="username"
                                    value={username}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="password">Password *</label>
                                <InputText
                                    id="password"
                                    value={password}
                                    type="password"
                                    required
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>
                            <label>Allowed Field</label>
                        </Dialog>

                        <Dialog visible={productDialog2} style={{ width: "450px" }} header="Tokens" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            <div className="field">
                                <label htmlFor="image">Logo *</label>
                                <div className="card">
                                    <FileUpload mode="basic" name="image" accept="image/*" maxFileSize={2000000} onUpload={onUpload} />
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="chain_name">Chain Name *</label>
                                <InputText id="chain_name" type="text" required />
                            </div>
                            <div className="field">
                                <label htmlFor="contract_address">Contract Address *</label>
                                <InputText id="contract_address" type="text" required />
                            </div>
                            <div className="field">
                                <label htmlFor="token_name">Token Name *</label>
                                <InputText id="token_name" type="text" required />
                            </div>
                            <div className="field">
                                <label htmlFor="token_ticker">Token Ticker *</label>
                                <InputText id="token_ticker" type="text" required />
                            </div>
                            <div className="field">
                                <label htmlFor="decimal">Decimal *</label>
                                <InputText id="decimal" type="text" required />
                            </div>
                            <div className="field">
                                <label htmlFor="abi">decimal *</label>
                                <InputText id="abi" type="text" required />
                            </div>
                            <div className="field">
                                <label htmlFor="url">Token type *</label>
                                <Dropdown id="state" value={dropdownItem} onChange={(e) => setDropdownItem(e.value)} options={dropdownItems} optionLabel="name" placeholder="Select Token Type"></Dropdown>
                            </div>
                        </Dialog>

                        <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {product && <span>Are you sure you want to delete?</span>}
                            </div>
                        </Dialog>

                        <Dialog visible={rolesDialog} style={{ width: "450px" }} header="Warning" modal footer={rolesDialogFooter} onHide={hideRolesDialog}>
                            <div className="flex">
                                <ol>
                                    {adminRoles &&
                                        adminRoles.map((item, ind) => {
                                            return <li key={ind}>{item.name}</li>;
                                        })}
                                </ol>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AdminList, comparisonFn);
