import React, { useState, useRef, useContext } from "react";
import axios from "axios";
import { validateUrl, validateEmail } from "../service/rejexValidateion";
import PageHeader from "./common/PageHeader";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import AuthContext from "../AuthProvider";
import LoadingSpinner from "../service/LoadingSpinner";

const AddNameTag = () => {
    const { checkRoles } = useContext(AuthContext);
    const history = useHistory();
    const toast = useRef(null);

    const [tabData, setTabData] = useState([
        { name: "Please select the field", code: 0 },
        { name: "I'm the address owner", code: 1 },
        { name: "I'm a user", code: 2 },
    ]);
    const [tabId, setTabId] = useState("");
    const [tabName, setTabName] = useState("");

    const [reCaptchaToken, setReCaptchaToken] = useState(false);
    const [reCaptchaTokenErr, setReCaptchaTokenErr] = useState("");
    const contact_url = window.location.search;
    const add = new URLSearchParams(contact_url).get("a");

    const [nameTags, setNameTags] = useState([
        {
            id: 0,
            contractaddress: "",
            nametag: "",
            website: "",
            categorylabel: "",
            shortdescription: "",
            errcontractaddress: "",
            errnametag: "",
            errwebsite: "",
            errcategoryLabel: "",
            errshortdescription: "",
        },
    ]);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [addressStatus, setAddressStatus] = useState(0);
    const [additionComment, setAdditionalComment] = useState("");
    const [nameTagID, setNameTagID] = useState(1);

    const [errname, setErrName] = useState("");
    const [erremail, setErrEmail] = useState("");
    const [errcompanyName, setErrCompanyName] = useState("");
    const [errcompanyWebsite, setErrCompanyWebsite] = useState("");
    const [erraddressStatus, setErrAddressStatus] = useState("");

    const [showSuccessMsg, setShowSuccessMsg] = useState(false);

    const handleRedirect = () => {
        if (localStorage.name && localStorage.password) {
            window.location.assign("/tokenupdate");
        } else {
            window.location.assign("/login");
        }
    };

    const handleSelectBox = (e) => {
        setAddressStatus(e.target.value);
    };

    const handleSubmit = () => {
        var temp = 1;
        let nameTagArr = [];
        if (name === "") {
            setErrName("Name is require.");
            temp = 0;
        } else {
            setErrName("");
        }
        if (email === "") {
            setErrEmail("Email address is require.");
            temp = 0;
        } else {
            if (!validateEmail(email)) {
                setErrEmail("Enter the valid email.");
                temp = 0;
            } else {
                setErrEmail("");
            }
        }
        if (companyName === "") {
            setErrCompanyName("Company Name is require.");
            temp = 0;
        } else {
            setErrCompanyName("");
        }
        if (companyWebsite === "") {
            setErrCompanyWebsite("Company website is require.");
            temp = 0;
        } else {
            if (!validateUrl(companyWebsite)) {
                setErrCompanyWebsite("Invalid website url.");
                temp = 0;
            } else {
                setErrCompanyWebsite("");
            }
        }
        if (addressStatus === 0) {
            setErrAddressStatus("Please select the field.");
            temp = 0;
        } else {
            setErrAddressStatus("");
        }
        nameTags.map((item) => {
            var errcontractaddress = "";
            var errnametag = "";
            var errwebsite = "";
            var errcategoryLabel = "";
            var errshortdescription = "";
            if (item.contractaddress === "") {
                errcontractaddress = "Contract address is require.";
                temp = 0;
            } else {
                if (item.contractaddress.length > 44) {
                    errcontractaddress = "Contract address contain lessthan 44 charachters.";
                    temp = 0;
                } else {
                    errcontractaddress = "";
                }
            }
            if (item.nametag === "") {
                errnametag = "Name tag is require.";
                temp = 0;
            } else {
                if (item.nametag.includes(" ")) {
                    errnametag = "Whitespace is not allowed in the suggested name tag.";
                    temp = 0;
                } else {
                    errnametag = "";
                }
            }
            if (item.website === "") {
                errwebsite = "Website is require.";
                temp = 0;
            } else {
                if (!validateUrl(item.website)) {
                    errwebsite = "Invalid website url.";
                    temp = 0;
                } else {
                    errwebsite = "";
                }
            }
            if (item.categorylabel === "") {
                errcategoryLabel = "Category Label is require.";
                temp = 0;
            } else {
                errcategoryLabel = "";
            }
            if (item.shortdescription === "") {
                errshortdescription = "Short Description is require.";
                temp = 0;
            } else {
                errshortdescription = "";
            }
            nameTagArr.push({
                id: item.id,
                contractaddress: item.contractaddress,
                nametag: item.nametag,
                website: item.website,
                categorylabel: item.categorylabel,
                shortdescription: item.shortdescription,
                errcontractaddress: errcontractaddress,
                errnametag: errnametag,
                errwebsite: errwebsite,
                errcategoryLabel: errcategoryLabel,
                errshortdescription: errshortdescription,
            });
        });
        if (temp === 1) {
            let newNameTagData = [];
            nameTags.map((item, index) => {
                newNameTagData.push({
                    id: index + 1,
                    contractaddress: item.contractaddress,
                    nametag: item.nametag,
                    website: item.website,
                    categorylabel: item.categorylabel,
                    shortdescription: item.shortdescription,
                    status: 0,
                });
            });
            addNameTag(newNameTagData);
        } else {
            setNameTags(nameTagArr);
        }
    };

    const addNameTag = async (nameTags1) => {
        await axios
            .post(`${process.env.REACT_APP_API_URL}publicnametag`, {
                method: "insert",
                name: name,
                email: email,
                companyname: companyName,
                companywebsite: companyWebsite,
                userstatus: addressStatus,
                nametags: nameTags1,
                comment: additionComment,
            })
            .then((res) => {
                if (!res.data.success) {
                    if (res.data?.erraddress && res.data?.erraddress?.length) {
                        let errfromback = res.data?.erraddress;
                        let errNameTage = nameTags;
                        let newErrArr = [];
                        for (let i = 0; i < errfromback?.length; i++) {
                            let findAdd = errNameTage.find((item) => item.contractaddress === errfromback[i]);
                            findAdd.errcontractaddress = "Contract address alerady in use.";
                            findAdd.errnametag = "";
                            findAdd.errwebsite = "";
                            findAdd.errcategoryLabel = "";
                            findAdd.errshortdescription = "";
                            newErrArr.push(findAdd);
                        }
                        setNameTags(newErrArr);
                    }
                } else {
                    toast.current.show({ severity: "success", summary: "Success", detail: "All nametag are inserted successfuly.", life: 3000 });
                    history.push("/public-name-tag-list");
                }
            })
            .catch((err) => console.log("error in addNameTag : ", err));
    };

    const handleInputChange = (index, event) => {
        const values = [...nameTags];
        const updatedValue = event.target.name;
        values[index][updatedValue] = event.target.value;

        setNameTags(values);
    };

    const handleInput = (e) => {
        setNameTags((prev) => [
            ...prev,
            {
                id: nameTagID,
                contractaddress: "",
                nametag: "",
                website: "",
                categorylabel: "",
                shortescription: "",
                errcontractaddress: "",
                errnametag: "",
                errwebsite: "",
                errcategoryLabel: "",
                errshortdescription: "",
            },
        ]);
        setNameTagID((prev) => prev + 1);
    };

    const handleInputClose = (index) => {
        const data = nameTags.filter((item) => item.id !== index);
        setNameTags(data);
    };

    const handleRedirectNameTag = () => {
        window.location.assign("/");
    };
    return !checkRoles ? (
        <LoadingSpinner />
    ) : checkRoles?.filter((item) => item.id === "0001_002").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <div className="grid AdminList-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <PageHeader title="Add Name Tag" />
                        <div className="nametag-container">
                            <div className="nametag-row">
                                <div className="field mr-5">
                                    <label htmlFor="email">Name *</label>
                                    <br />
                                    <InputText id="email" value={name} type="text" required onChange={(e) => setName(e.target.value)} />
                                    {errname && <p className="p-1 err-mess">{errname}</p>}
                                </div>
                                <div className="field mr-5">
                                    <label htmlFor="email">Email Address *</label>
                                    <br />
                                    <InputText id="email" value={email} type="text" required onChange={(e) => setEmail(e.target.value)} />
                                    {erremail && <p className="p-1 err-mess">{erremail}</p>}
                                </div>
                            </div>
                            <div className="nametag-row">
                                <div className="field mr-5">
                                    <label htmlFor="email">Company Name *</label>
                                    <br />
                                    <InputText id="email" value={companyName} type="text" required onChange={(e) => setCompanyName(e.target.value)} />
                                    {errcompanyName && <p className="p-1 err-mess">{errcompanyName}</p>}
                                </div>
                                <div className="field mr-5">
                                    <label htmlFor="email">Company Website *</label>
                                    <br />
                                    <InputText id="email" value={companyWebsite} type="text" required onChange={(e) => setCompanyWebsite(e.target.value)} />
                                    {errcompanyWebsite && <p className="p-1 err-mess">{errcompanyWebsite}</p>}
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="email">Is this an address owner or a user submission? *</label>
                                <br />
                                <Dropdown
                                    id="state"
                                    value={tabName}
                                    onChange={(e) => {
                                        setTabId(e.value.code);
                                        setTabName(e.value);
                                        setAddressStatus(e.value.code);
                                    }}
                                    options={tabData}
                                    optionLabel="name"
                                    placeholder="Select Token Type"
                                ></Dropdown>
                                {erraddressStatus && <p className="p-1 err-mess">{erraddressStatus}</p>}
                            </div>
                            {nameTags.map((item, index) => (
                                <div className="subnametag-container mb-2">
                                    {index !== 0 && (
                                        <div className="close-btn-container">
                                            <Button icon="pi pi-trash" className="p-button-text p-button-danger p-button-icon-only" style={{ padding: "2px" }} onClick={() => handleInputClose(item.id)} />
                                        </div>
                                    )}
                                    <div className="nametag-row">
                                        <div className="field mr-5">
                                            <label htmlFor="email">Smart Contract / Address *</label>
                                            <br />
                                            <InputText id="email" value={item.contractaddress} name="contractaddress" placeholder="0x..." type="text" required onChange={(event) => handleInputChange(index, event)} />
                                            {item.errcontractaddress && <p className="p-1 err-mess">{item.errcontractaddress}</p>}
                                        </div>
                                        <div className="field mr-5">
                                            <label htmlFor="email">Suggested Name Tag *</label>
                                            <br />
                                            <InputText id="email" value={item.nametag} type="text" name="nametag" required onChange={(event) => handleInputChange(index, event)} />
                                            {item.errnametag && <p className="p-1 err-mess">{item.errnametag}</p>}
                                        </div>
                                    </div>
                                    <div className="nametag-row">
                                        <div className="field mr-5">
                                            <label htmlFor="email">Website *</label>
                                            <br />
                                            <InputText id="email" value={item.website} name="website" type="text" required onChange={(event) => handleInputChange(index, event)} />
                                            {item.errwebsite && <p className="p-1 err-mess">{item.errwebsite}</p>}
                                        </div>
                                        <div className="field mr-5">
                                            <label htmlFor="email">Category Label *</label>
                                            <br />
                                            <InputText id="email" value={item.categorylabel} name="categorylabel" type="text" required onChange={(event) => handleInputChange(index, event)} />
                                            {item.errcategoryLabel && <p className="p-1 err-mess">{item.errcategoryLabel}</p>}
                                        </div>
                                    </div>
                                    <div className="nametag-row">
                                        <div className="field mr-5">
                                            <label htmlFor="email">Short Description *</label>
                                            <br />
                                            <InputText id="email" value={item.shortdescription} name="shortdescription" type="text" required onChange={(event) => handleInputChange(index, event)} />
                                            {item.errshortdescription && <p className="p-1 err-mess">{item.errshortdescription}</p>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <Button label="Add name tag" className="p-button-text p-button-primary" style={{ padding: "2px" }} onClick={handleInput} />
                            <div className="nametag-row mt-3">
                                <div className="field">
                                    <label htmlFor="email">Additional Comment</label>
                                    <br />
                                    <InputText id="email" value={additionComment} type="text" required onChange={(e) => setAdditionalComment(e.target.value)} />
                                </div>
                            </div>
                            <div className="mt-2" style={{ display: "flex", justifyContent: "center" }}>
                                <Button label="Submit" className="p-button-rounded p-button-primary" onClick={handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddNameTag;
