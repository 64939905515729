import React, { useState, useEffect, useRef, useContext } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProductService } from "../service/ProductService";
import { useHistory } from "react-router-dom";
import AuthContext from "../AuthProvider";
import axios from "axios";
const apikey = process.env.REACT_APP_KEY;

// import { UserRoles } from '../App';

const lineData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
        {
            label: "First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.4,
        },
        {
            label: "Second Dataset",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.4,
        },
    ],
};

const WalletDashboard = (props) => {
    document.title = "Wallet Dashboard";

    const [totalUsers, setTotalUsers] = useState(0);
    const [totalActiveUsers, setTotalActiveUsers] = useState(0);

    const [totalTokens, setTotalTokens] = useState(0);
    const [totalVerifiedTokens, setTotalVerifiedTokens] = useState(0);
    const [totalUnverifiedTokens, setTotalUnverifiedTokens] = useState(0);

    const [totalNodes, setTotalNodes] = useState(0);
    const [totalActiveNodes, setTotalActiveNodes] = useState(0);

    const { checkRoles, roles } = useContext(AuthContext);

    const history = useHistory();
    const [products, setProducts] = useState(null);
    const menu1 = useRef(null);
    const menu2 = useRef(null);
    const [lineOptions, setLineOptions] = useState(null);
    useEffect(() => {
        getUserData();
        getTokesData();
        getNodesData();
    }, []);

    const getUserData = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "usersList", submethod: "get", key: apikey }).then((res) => {
            if (res?.data.error) {
                // console.log("error ",res.data.error);
            } else {
                for (let i = 0; i < res.data.length; i++) {
                    setTotalUsers((prev) => prev + 1);
                    if (res.data[i].status) {
                        setTotalActiveUsers((prev) => prev + 1);
                    }
                }
            }
        });
    };

    const getTokesData = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "explorerToken", submethod: "getVerifiedList", key: apikey }).then((res) => {
            if (res?.data.error) {
                // console.log("error ",res.data.error);
            } else {
                setTotalVerifiedTokens(res.data.length);
            }
        });
        await axios.post(process.env.REACT_APP_API_URL, { method: "explorerToken", submethod: "getUnverifiedList", key: apikey }).then((res) => {
            if (res?.data.error) {
                // console.log("error ",res.data.error);
            } else {
                setTotalUnverifiedTokens(res.data.length);
            }
        });
    };
    const getNodesData = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "explorerNodes", submethod: "get", key: apikey }).then((res) => {
            if (res?.data.error) {
                // console.log("error ",res.data.error);
            } else {
                for (let i = 0; i < res.data.length; i++) {
                    setTotalNodes((prev) => prev + 1);
                    if (res.data[i].verification === "verified") {
                        setTotalActiveNodes((prev) => prev + 1);
                    }
                }
            }
        });
    };

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#ebedef",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
                y: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };

    useEffect(() => {
        const productService = new ProductService();
        productService.getProductsSmall().then((data) => setProducts(data));
    }, []);

    useEffect(() => {
        if (props.colorMode === "light") {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    };

    return !checkRoles || checkRoles?.filter((item) => item.id === "0002_008").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <title>Dashboard</title>

            <div className="grid">
                <div className="col-12">
                    <h3 className="m-0 text-700">Users</h3>
                </div>
                <div className="col-12 lg:col-6 xl:col-3 mb-2">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Total Users</span>
                                <div className="text-900 font-medium text-xl">{totalUsers}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-users text-blue-500 text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3 mb-2">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Total Active Users</span>
                                <div className="text-900 font-medium text-xl">{totalActiveUsers}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-check text-orange-500 text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(WalletDashboard, comparisonFn);
