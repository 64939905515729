import React, { useState, useEffect, useRef, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import classNames from "classnames";
import { Route, useLocation, useHistory } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "../assets/demo/flags/flags.css";
import "../assets/demo/Demos.scss";
import "../assets/layout/layout.scss";
import { Toast } from "primereact/toast";
import axios from "axios";
import { CLIENT_RENEG_LIMIT } from "tls";
import AuthContext from "../AuthProvider";
import CryptoJS from "crypto-js";

const apikey = process.env.REACT_APP_KEY;

const Home = (props) => {
    const { setAdminRoles } = useContext(AuthContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const toast = useRef(null);
    const history = useHistory();

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    const handleAdminLogin = async (e) => {
        e.preventDefault();
        var encbody = CryptoJS.AES.encrypt(JSON.stringify({ username: username, password: password }), process.env.REACT_APP_PASSWORD).toString();
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}admin/signin`, { token: encbody }).then((res) => {
                if (res?.data?.error) {
                    toast.current.show({ severity: "error", summary: "Error!", detail: res.data.error, life: 3000 });
                } else {
                    localStorage.setItem("auda5s21samn", CryptoJS.AES.encrypt(res.data.username, process.env.REACT_APP_PASSWORD).toString());
                    localStorage.setItem("aeda4sd4famm", CryptoJS.AES.encrypt(res.data.email, process.env.REACT_APP_PASSWORD).toString());
                    localStorage.setItem("apdn4sec3f1ms", CryptoJS.AES.encrypt(password.trim(), process.env.REACT_APP_PASSWORD).toString());
                    window.location.assign("/#/dashboard");
                    window.location.reload();
                }
            });
        } catch (err) {
            console.log("error in handleAdminLogin() (catch)", err);
            toast.current.show({ severity: "error", summary: "Error!", detail: err.message, life: 3000 });
        }
    };

    return (
        <>
            {/* <Toast ref={toast} />

            <div>
                <div className="flex align-items-center justify-content-center">
                    <div style={{ borderRadius: "56px", padding: "0.3rem", background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)" }}>
                        <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
                            <div className="text-center mb-5">
                                <div className="text-900 text-3xl font-medium mb-3">Welcome</div>
                            </div>

                            <div>
                                <label htmlFor="uname1" className="block text-900 text-xl font-medium mb-2">
                                    Username
                                </label>
                                <InputText
                                    inputid="uname1"
                                    type="text"
                                    placeholder="User Name"
                                    required
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                    }}
                                    className="w-full md:w-30rem mb-5"
                                    style={{ padding: "1rem" }}
                                />

                                <label htmlFor="password1" className="block text-900 font-medium text-xl mb-2">
                                    Password
                                </label>
                                <InputText
                                    id="password"
                                    type="password"
                                    placeholder="Password"
                                    required
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    className="w-full mb-3"
                                />

                                <Button label="Sign In" className="w-full p-3 text-xl" onClick={(e) => handleAdminLogin(e)}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Toast ref={toast} />
            <title>Login</title>
            <div className="flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                <div className="grid">
                    <div className="col-12">
                        <div className="card p-fluid">
                            <h5>
                                <b>Login</b>
                            </h5>
                            <div className="field">
                                <label htmlFor="email">Username *</label>
                                <InputText
                                    id="email"
                                    type="email"
                                    required
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="password">Password *</label>
                                <InputText
                                    id="password"
                                    type="password"
                                    required
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>

                            <div className="field grid">
                                <div className="ml-2">
                                    <Button type="button" label="Login" onClick={handleAdminLogin}></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
