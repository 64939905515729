import React, { useState, useEffect, useRef, useContext } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
// import { UserRoles } from '../App';
import { useHistory } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AuthContext from "../AuthProvider";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";

import axios from "axios";

const apikey = process.env.REACT_APP_KEY;

const APIKeyList = () => {
    document.title = "Api Keys";

    const history = useHistory();
    const { checkRoles, roles } = useContext(AuthContext);
    // if(!roles || roles?.filter(item => item.id === "0004_001").length === 0){
    //     history.push("/unauthorized");
    // }

    const [apiId, setApiId] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [updateApiId, setUpdateApiId] = useState("");
    const [updateApiKey, setUpdateApiKey] = useState("");

    const [spinner, setSpinner] = useState(true);

    const [dropdownItem, setDropdownItem] = useState(null);
    const dropdownItems = [
        { name: "Assets", code: "Assets" },
        { name: "NFT", code: "NFT" },
    ];

    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };
    const [apikeysData, setApikeysData] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(10);

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [updateProductDialog, setUpdateProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [EnabledProductDialog, setEnabledProductDialog] = useState(false);

    const [productDialog2, setProductDialog2] = useState(false);

    const [startdate, setStartDate] = useState("");
    const [enddate, setEndDate] = useState("");

    const toast = useRef(null);
    const dt = useRef(null);

    // useEffect(() => {
    //     const productService = new ProductService();
    //     productService.getProducts().then(data => setProducts(data));
    // }, []);

    useEffect(() => {
        getApiKey();
    }, []);

    const getApiKey = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "api_key", submethod: "get", key: apikey }).then((res) => {
            if (res.data) {
                setProducts(res.data);
            }
        });
    };

    const onUpload = () => {
        toast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded", life: 3000 });
    };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };

    const openNew2 = () => {
        // setProduct(emptyProduct);
        // setSubmitted(false);
        setProductDialog2(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setProductDialog2(false);
    };

    const hideUpdateDialog = () => {
        setSubmitted(false);
        setUpdateProductDialog(false);
        setProductDialog2(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setEnabledProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = async () => {
        if (apiKey) {
            if (apiKey) {
                await axios.post(process.env.REACT_APP_API_URL, { method: "api_key", submethod: "insert", apikey: apiKey }).then((res) => {
                    if (res?.data?.error) {
                        toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                    } else {
                        setProductDialog(false);
                        getApiKey();
                        setApiId("");
                        setApiKey("");
                        getApiKey();
                        toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
                    }
                });
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "provide require fields", life: 3000 });
        }
    };

    const updateProduct = async () => {
        if (updateApiKey) {
            await axios.post(process.env.REACT_APP_API_URL, { method: "api_key", submethod: "update", key: apikey, id: updateApiId, apikey: updateApiKey }).then((res) => {
                if (res.data) {
                    setUpdateProductDialog(false);
                    setUpdateApiId("");
                    setUpdateApiKey("");
                    getApiKey();
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Api Key updated!", life: 3000 });
                }
            });
        }
    };

    const editProduct = (product) => {
        //setProduct({ ...product });
        setUpdateApiId(product._id);
        setUpdateApiKey(product.apikey);
        setUpdateProductDialog(true);
    };

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    };

    const confirmEnabledProduct = (product) => {
        setProduct(product);
        setEnabledProductDialog(true);
    };

    const deleteProduct = () => {
        let _products = products.filter((val) => val.id !== product.id);
        setProducts(_products);
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Product Deleted", life: 3000 });
    };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Add API Key" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
            </div>
        );
    };

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const updateProductDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideUpdateDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateProduct} />
        </>
    );

    return !checkRoles || checkRoles?.filter((item) => item.id === "0004_001").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <Toast ref={toast} />
            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
            <div className="grid UsersList-demo">
                <div className="col-12">
                    <div className="card">
                        <PageHeader title="Apikeys" search={false} />
                        <DataTable value={apikeysData} dataKey="id" loading={spinner} className="datatable-responsive" emptyMessage="No data found." responsiveLayout="scroll">
                            <Column field="ind" header="#" headerStyle={{ minWidth: "8rem" }}></Column>
                            <Column field="key" header="Key" headerStyle={{ minWidth: "8rem" }}></Column>
                            <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        </DataTable>
                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} pages={pages} />
                    </div>
                </div>
            </div>
            <Dialog visible={productDialog} style={{ width: "450px" }} header="API Key" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="key">Key *</label>
                    <InputText
                        id="key"
                        type="text"
                        value={apiKey}
                        required
                        onChange={(e) => {
                            setApiKey(e.target.value);
                        }}
                    />
                </div>
            </Dialog>

            <Dialog visible={updateProductDialog} style={{ width: "450px" }} header="API Key" modal className="p-fluid" footer={updateProductDialogFooter} onHide={hideUpdateDialog}>
                <div className="field">
                    <label htmlFor="key">Key *</label>
                    <InputText
                        id="key"
                        type="text"
                        value={updateApiKey}
                        required
                        onChange={(e) => {
                            setUpdateApiKey(e.target.value);
                        }}
                    />
                </div>
            </Dialog>

            <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Generate Key" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {product && <span>Are you sure you want to delete?</span>}
                </div>
            </Dialog>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(APIKeyList, comparisonFn);
