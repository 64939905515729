import React from "react";
import { InputText } from "primereact/inputtext";

const PageHeader = ({ title, search, handleSearch }) => {
    return (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center py-3 px-2 mb-3 bg-secondary" style={{ backgroundColor: "var(--surface-d)" }}>
            <h5 className="m-0">{title}</h5>
            {search ? (
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        placeholder="Search..."
                        onChange={(e) => {
                            handleSearch(e.target.value);
                        }}
                    />
                </span>
            ) : (
                ""
            )}
        </div>
    );
};

export default PageHeader;
