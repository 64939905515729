import React, { useState, useEffect, useRef, useContext } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProductService } from "../service/ProductService";

// import { UserRoles } from '../App';

import { Calendar } from "primereact/calendar";

import { FileUpload } from "primereact/fileupload";

import { validateUrl } from "../service/rejexValidateion";
import axios from "axios";
import LoadingSpinner from "../service/LoadingSpinner";
import { useHistory } from "react-router-dom";
import AuthContext from "../AuthProvider";
import { CSVLink } from "react-csv";

const apikey = process.env.REACT_APP_KEY;

const NewsList = () => {
    document.title = "News";

    const { checkRoles, roles } = useContext(AuthContext);

    // const history = useHistory();
    // // const roles = useContext(UserRoles);

    // if(!roles || roles?.filter(item => item.id === "0002_005").length === 0){
    //     history.push("/unauthorized");
    // }

    const [file, setFile] = useState("");
    const [category, setCategory] = useState("");
    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [url, setUrl] = useState("");

    //err message states
    const [errUrl, setErrUrl] = useState("");

    const [updateId, setUpdateID] = useState("");
    const [updateFile, setUpdateFile] = useState("");
    const [updateCategory, setUpdateCategory] = useState("");
    const [updateTitle, setUpdateTitle] = useState("");
    const [updateSubtitle, setUpdateSubtitle] = useState("");
    const [updateUrl, setUpdateUrl] = useState("");

    // update err message states
    const [updateErrUrl, setErrUpdateUrl] = useState("");

    const [csvAllData, setCsvAllData] = useState(null);

    const [deleteId, setDeleteId] = useState("");

    const [deleteImgPath, setDeleteImgPath] = useState("");
    const [deleteUpdateImfPath, setDeleteUpdateImgPath] = useState("");

    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [editProductDialog, setEditProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [startdate, setStartDate] = useState("");
    const [enddate, setEndDate] = useState("");

    const [loading, setLoading] = useState(true);

    const toast = useRef(null);
    const dt = useRef(null);

    // useEffect(() => {
    //     const productService = new ProductService();
    //     productService.getProducts().then(data => setProducts(data));
    // }, []);

    useEffect(() => {
        // const productService = new ProductService();
        // productService.getProducts().then(data => setProducts(data));

        getNewsList();
    }, []);

    const getNewsList = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "news", submethod: "get", key: apikey }).then((res) => {
            if (res?.data) {
                // console.log(res.data);
                setProducts(res.data);
                setLoading(false);
                var csvData = new Array();
                for (let i = 0; i < res.data.length; i++) {
                    csvData.push({ ind: res.data[i].id, image: res.data[i].image, category: res.data[i].category, title: res.data[i].title, subtitle: res.data[i].subtitle, url: res.data[i].url, createdat: res.data[i].CreatedAt });
                }
                setCsvAllData(csvData);
            }
        });
    };

    const csvReport = {
        filename: "News-list.csv",
        data: csvAllData,
    };

    const handleUploadChange = (e) => {
        // console.log("e.target.files[0]", e.files[0].name);
        setFile(e.files[0]);
    };

    const handleEditUploadChange = (e) => {
        // console.log("e.target.files[0]", e.files[0].name);
        setUpdateFile(e.files[0]);
    };

    const onUpload = () => {
        toast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded", life: 3000 });
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };

    const hideDialog = () => {
        setErrUrl("");
        setSubmitted(false);
        setProductDialog(false);
    };

    const editHideDialog = () => {
        setErrUpdateUrl("");
        setSubmitted(false);
        setEditProductDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = async () => {
        // setSubmitted(true);

        // if (product.name.trim()) {
        //     let _products = [...products];
        //     let _product = { ...product };
        //     if (product.id) {
        //         const index = findIndexById(product.id);

        //         _products[index] = _product;
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
        //     }
        //     else {
        //         _product.id = createId();
        //         _product.image = 'product-placeholder.svg';
        //         _products.push(_product);
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
        //     }

        //     setProducts(_products);
        //     setProductDialog(false);
        //     setProduct(emptyProduct);
        // }

        const formData = new FormData();
        //this will pass as file data
        formData.append("file", file);
        //now all the remain data pass as body data
        formData.append("method", "news");
        formData.append("submethod", "insert");
        formData.append("title", title);
        formData.append("category", category);
        formData.append("url", url);
        formData.append("subtitle", subtitle);
        formData.append("key", apikey);
        let temp = 1;
        if (!validateUrl(url)) {
            setErrUrl("invalid url.");
            temp = 0;
        } else {
            setErrUrl("");
        }
        if (temp === 1) {
            await axios.post(process.env.REACT_APP_API_URL, formData).then((res) => {
                if (res.data) {
                    if (res?.data?.error) {
                        toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                    } else if (res?.data?.success) {
                        setTitle("");
                        setCategory("");
                        setUrl("");
                        setSubtitle("");
                        setProductDialog(false);
                        getNewsList();
                        toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
                    }
                }
            });
        }
    };

    const updateProduct = async () => {
        // setSubmitted(true);

        // if (product.name.trim()) {
        //     let _products = [...products];
        //     let _product = { ...product };
        //     if (product.id) {
        //         const index = findIndexById(product.id);

        //         _products[index] = _product;
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
        //     }
        //     else {
        //         _product.id = createId();
        //         _product.image = 'product-placeholder.svg';
        //         _products.push(_product);
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
        //     }

        //     setProducts(_products);
        //     setProductDialog(false);
        //     setProduct(emptyProduct);
        // }

        const formData = new FormData();
        //this will pass as file data
        formData.append("file", updateFile);
        //now all the remain data pass as body data
        formData.append("method", "news");
        formData.append("submethod", "update");
        formData.append("id", updateId);
        formData.append("title", updateTitle);
        formData.append("category", updateCategory);
        formData.append("url", updateUrl);
        formData.append("subtitle", updateSubtitle);
        formData.append("key", apikey);
        formData.append("deleteimg", deleteUpdateImfPath);
        let temp = 1;
        if (!validateUrl(updateUrl)) {
            setErrUpdateUrl("invalid url.");
            temp = 0;
        } else {
            setErrUpdateUrl("");
        }
        if (temp === 1) {
            await axios.post(process.env.REACT_APP_API_URL, formData).then((res) => {
                if (res.data) {
                    if (res?.data?.error) {
                        toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                    } else if (res?.data?.success) {
                        setTitle("");
                        setCategory("");
                        setUrl("");
                        setSubtitle("");
                        setEditProductDialog(false);
                        getNewsList();
                        toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
                    }
                }
            });
        }
    };

    const editProduct = (rowData) => {
        //setProduct({ ...product });
        setUpdateID(rowData._id);
        setUpdateCategory(rowData.category);
        setUpdateTitle(rowData.title);
        setUpdateUrl(rowData.url);
        setUpdateSubtitle(rowData.subtitle);
        setEditProductDialog(true);
        setDeleteUpdateImgPath(rowData.image);
    };

    const confirmDeleteProduct = (rowData) => {
        // setProduct(product);
        setDeleteId(rowData._id);
        setDeleteProductDialog(true);
        setDeleteImgPath(rowData.image);
    };

    const deleteProduct = async () => {
        // let _products = products.filter(val => val.id !== product.id);
        // setProducts(_products);
        // setDeleteProductDialog(false);
        // setProduct(emptyProduct);
        // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
        await axios.post(process.env.REACT_APP_API_URL, { method: "news", submethod: "delete", id: deleteId, key: apikey, image: deleteImgPath }).then((res) => {
            if (res.data) {
                if (res?.data?.error) {
                    toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                } else if (res?.data?.success) {
                    setDeleteId("");
                    setDeleteProductDialog(false);
                    getNewsList();
                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
                }
            }
        });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Add News" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.cate}
            </>
        );
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={rowData.image ? process.env.REACT_APP_NEWS_IMAGES + rowData.image : "public/images/users/user-6.jpg"} alt={rowData.image} className="shadow-2 imground" width="50" />
            </>
        );
    };

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.title)}
            </>
        );
    };

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    };
    const subtitleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.subtitle}
            </>
        );
    };
    const urlBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                <a href={"https://" + rowData.url} target="_blank">
                    {"https://" + rowData.url}
                </a>
            </>
        );
    };
    const CreatedAtBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.CreatedAt}
            </>
        );
    };

    const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                <Rating value={rowData.rating} readonly cancel={false} />
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">News List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                {csvAllData ? (
                    <CSVLink className="p-button p-component p-button-secondary p-2 m-1" {...csvReport}>
                        .CSV
                    </CSVLink>
                ) : (
                    ""
                )}
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const editProductDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={editHideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return !checkRoles || checkRoles?.filter((item) => item.id === "0002_006").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <div className="grid NewsList-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                        <DataTable
                            ref={dt}
                            value={products}
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={globalFilter}
                            emptyMessage={loading ? <LoadingSpinner /> : "data not found."}
                            header={header}
                            responsiveLayout="scroll"
                        >
                            <Column field="id" header="#" sortable body={codeBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column header="Thumbnail" body={imageBodyTemplate} headerStyle={{ width: "14%", minWidth: "7rem" }}></Column>
                            <Column field="category" header="Category" sortable body={categoryBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="title" header="Title" body={titleBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>
                            <Column field="subtitle" header="Subtitle" body={subtitleBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>
                            <Column field="url" header="Url" body={urlBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>
                            <Column field="created_at" header="Created At" body={CreatedAtBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>
                            <Column field="action" header="Action" body={actionBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        </DataTable>

                        {/* add news dialo */}
                        <Dialog visible={productDialog} style={{ width: "450px" }} header="News" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            <div className="field">
                                <label htmlFor="image">Thumbnail *</label>
                                <div className="card">
                                    {/* <FileUpload mode="basic" name="image" accept="image/*" maxFileSize={2000000} onUpload={onUpload} /> */}
                                    <FileUpload mode="basic" name="image" customUpload={true} url="./assets" auto="true" accept="image/*" maxFileSize={2000000} uploadHandler={handleUploadChange} />
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="category">Category *</label>
                                <InputText
                                    id="category"
                                    value={category}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setCategory(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="title">Title *</label>
                                <InputText
                                    id="title"
                                    value={title}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="subtitle">Subtitle *</label>
                                <InputText
                                    id="subtitle"
                                    value={subtitle}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setSubtitle(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="url">Url *</label>
                                <InputText
                                    id="url"
                                    value={url}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setUrl(e.target.value);
                                    }}
                                />
                                {errUrl && <p className="p-1 err-mess">{errUrl}</p>}
                            </div>
                        </Dialog>

                        {/* edit news dialog */}
                        <Dialog visible={editProductDialog} style={{ width: "450px" }} header="Edit News" modal className="p-fluid" footer={editProductDialogFooter} onHide={editHideDialog}>
                            <div className="field">
                                <label htmlFor="image">Thumbnail *</label>
                                <div className="card">
                                    {/* <FileUpload mode="basic" name="image" accept="image/*" maxFileSize={2000000} onUpload={onUpload} /> */}
                                    <FileUpload mode="basic" name="image" customUpload={true} url="./assets" auto="true" accept="image/*" maxFileSize={2000000} uploadHandler={handleEditUploadChange} />
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="category">Category *</label>
                                <InputText
                                    id="category"
                                    value={updateCategory}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setUpdateCategory(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="title">Title *</label>
                                <InputText
                                    id="title"
                                    value={updateTitle}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setUpdateTitle(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="subtitle">Subtitle *</label>
                                <InputText
                                    id="subtitle"
                                    value={updateSubtitle}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setUpdateSubtitle(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="url">Url *</label>
                                <InputText
                                    id="url"
                                    value={updateUrl}
                                    type="text"
                                    required
                                    onChange={(e) => {
                                        setUpdateUrl(e.target.value);
                                    }}
                                />
                                {updateErrUrl && <p className="p-1 err-mess">{updateErrUrl}</p>}
                            </div>
                        </Dialog>

                        <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {product && <span>Are you sure you want to delete?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(NewsList, comparisonFn);
