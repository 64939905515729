import React, { useState, useEffect, useRef, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import AuthContext from '../AuthProvider';
import axios from 'axios';
const apikey = process.env.REACT_APP_KEY;

// import { UserRoles } from '../App';

const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860',
            tension: .4
        },
        {
            label: 'Second Dataset',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e',
            tension: .4
        }
    ]
};

const Maintenance = (props) => {

    const { roles } = useContext(AuthContext);

    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [data, setData] = useState([]);
    const [statusId, setStatusId] = useState('');
    const [status, setStatus] = useState(null);

    useEffect(()=> {
        getMaintenanceData();
    }, [])

    const getMaintenanceData = async () => {
        // await axios.post(process.env.REACT_APP_API_URL, {method: "maintenanceStatus"})
        // .then(res => {
        //     if (res.data.error) {
        //         toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.error, life: 3000 });
        //     }
        //     else if (res?.data?.success) {
        //         setName("");
        //         setNote("");
        //         getBlockedAddress();
        //         setProductDialog(false);
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: res.data.success, life: 3000 });
        //     }
        // })
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const updateStatus = async () => {

    }

    const handleClick = () =>{
        setDeleteProductDialog(true)
    }

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={updateStatus} />
        </>
    );

    return !roles || roles?.filter(item => item.id === "0001_007").length === 0 ? (<h4>You don't have access to this resource.</h4>) : (
        <>
            <title>Dashboard</title>

            <div className="grid">
                <div className="col-12 lg:col-6 xl:col-12">
                    <div className="card mb-0">
                        <div className="flex justify-content-between">
                            <div className="field flex justify-content-between align-item-center m-0">
                                <h4 className='m-0 p-0 h-auto' >Under Maintenance : </h4>
                                <Button type="button" className='p-button-success mx-3' label="ON" onClick={handleClick}></Button>
                                <Button type="button" className='p-button-danger mx-3' label="OFF" onClick={handleClick}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {<span>Are you sure you want to Turnon aintenance?</span>}
                </div>
            </Dialog>

        </>

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Maintenance, comparisonFn);
