import React, { useState, useEffect, useRef, useContext } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProductService } from "../service/ProductService";
import AuthContext from "../AuthProvider";
import { FileUpload } from "primereact/fileupload";
import axios from "axios";
import { ConnectedOverlayScrollHandler } from "primereact/utils";
import LoadingSpinner from "../service/LoadingSpinner";

import { CSVLink } from "react-csv";

const apikey = process.env.REACT_APP_KEY;

const WalletTabsList = () => {
    document.title = "Wallet Tabs";

    const { checkRoles, roles } = useContext(AuthContext);

    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [updateProductDialog, setUpdateProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const [csvAllData, setCsvAllData] = useState(null);

    const [tabId, setTabId] = useState("");
    const [tabName, setTabName] = useState("");

    const [updateTabId, setUpdateTabId] = useState("");
    const [updateTabName, setUpdateTabName] = useState("");

    const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     const productService = new ProductService();
    //     productService.getProducts().then(data => setProducts(data));
    // }, []);

    useEffect(() => {
        getTabList();
    }, []);

    const getTabList = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "walletTabs", submethod: "get", key: apikey }).then((res) => {
            if (res?.data?.error) {
                // toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.error, life: 3000 });
            } else {
                // toast.current.show({ severity: 'success', summary: 'Successful', detail: res.data.success, life: 3000 });
                // console.log(res.data);
                setProducts(res.data);
                setLoading(false);
                var csvData = new Array();
                for (let i = 0; i < res.data.length; i++) {
                    csvData.push({ ind: res.data[i].id, name: res.data[i].name, status: res.data[i].status });
                }
                setCsvAllData(csvData);
            }
        });
    };

    const csvReport = {
        filename: "Wallet-tab-list.csv",
        data: csvAllData,
    };

    const onUpload = () => {
        toast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded", life: 3000 });
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };

    const hideDialog = () => {
        setTabName("");
        setSubmitted(false);
        setProductDialog(false);
    };

    const updateHideDialog = () => {
        setSubmitted(false);
        setUpdateProductDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = async () => {
        // setSubmitted(true);

        // if (product.name.trim()) {
        //     let _products = [...products];
        //     let _product = { ...product };
        //     if (product.id) {
        //         const index = findIndexById(product.id);

        //         _products[index] = _product;
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
        //     }
        //     else {
        //         _product.id = createId();
        //         _product.image = 'product-placeholder.svg';
        //         _products.push(_product);
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
        //     }

        //     // setProducts(_products);
        //     // setProduct(emptyProduct);
        // }
        if (tabName) {
            if (tabName) {
                await axios.post(process.env.REACT_APP_API_URL, { method: "walletTabs", submethod: "insert", key: apikey, tabname: tabName }).then((res) => {
                    if (res?.data?.error) {
                        toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                    } else {
                        setTabId("");
                        setTabName("");
                        getTabList();
                        setProductDialog(false);
                        toast.current.show({ severity: "success", summary: "Success", detail: res.data.success, life: 3000 });
                    }
                });
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "provide require fields.", life: 3000 });
        }
    };

    const updateProduct = async () => {
        // setSubmitted(true);

        // if (product.name.trim()) {
        //     let _products = [...products];
        //     let _product = { ...product };
        //     if (product.id) {
        //         const index = findIndexById(product.id);

        //         _products[index] = _product;
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
        //     }
        //     else {
        //         _product.id = createId();
        //         _product.image = 'product-placeholder.svg';
        //         _products.push(_product);
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
        //     }

        //     // setProducts(_products);
        //     // setProduct(emptyProduct);
        // }
        await axios.post(process.env.REACT_APP_API_URL, { method: "walletTabs", submethod: "update", key: apikey, id: updateTabId, tabname: updateTabName }).then((res) => {
            if (res?.data?.error) {
                toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
            } else {
                setUpdateTabId("");
                setUpdateTabName("");
                setUpdateProductDialog(false);
                getTabList();
                toast.current.show({ severity: "success", summary: "Success", detail: res.data.success, life: 3000 });
            }
        });
    };

    const editProduct = (product) => {
        //setProduct({ ...product });
        setUpdateTabId(product._id);
        setUpdateTabName(product.name);
        setUpdateProductDialog(true);
    };

    const confirmDeleteProduct = (product) => {
        setTabId(product._id);
        setProduct(product);
        setDeleteProductDialog(true);
    };

    const deleteProduct = async () => {
        // let _products = products.filter(val => val.id !== product.id);
        // setProducts(_products);
        // setProduct(emptyProduct);
        // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
        await axios.post(process.env.REACT_APP_API_URL, { method: "walletTabs", submethod: "delete", key: apikey, id: tabId }).then((res) => {
            if (res?.data?.error) {
                toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
            } else {
                getTabList();
                setTabId("");
                setTabName("");
                setDeleteProductDialog(false);
                toast.current.show({ severity: "success", summary: "Success", detail: res.data.success, life: 3000 });
            }
        });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    };

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        );
    };

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    };

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    };

    const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                <Rating value={rowData.rating} readonly cancel={false} />
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Add Tab" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Tabs List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                {csvAllData ? (
                    <CSVLink className="p-button p-component p-button-secondary p-2 m-1" {...csvReport}>
                        .CSV
                    </CSVLink>
                ) : (
                    ""
                )}
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const updateProductDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={updateHideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return !checkRoles || checkRoles?.filter((item) => item.id === "0002_007").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <div className="grid WalletTabsList-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                        <DataTable
                            ref={dt}
                            value={products}
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={globalFilter}
                            emptyMessage={loading ? <LoadingSpinner /> : "data not found."}
                            header={header}
                            responsiveLayout="scroll"
                        >
                            <Column field="id" header="#" sortable body={codeBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="tab_name" header="Tab Name" sortable body={nameBodyTemplate} headerStyle={{ width: "14%", minWidth: "40rem" }}></Column>

                            <Column field="action" header="Action" body={actionBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        </DataTable>

                        <Dialog visible={productDialog} style={{ width: "450px" }} header="Tabs" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            <div className="field">
                                <label htmlFor="tabname">Tab Name *</label>
                                <InputText
                                    id="tabname"
                                    type="text"
                                    value={tabName}
                                    required
                                    onChange={(e) => {
                                        setTabName(e.target.value);
                                    }}
                                />
                            </div>
                        </Dialog>

                        <Dialog visible={updateProductDialog} style={{ width: "450px" }} header="Edit Tabs" modal className="p-fluid" footer={updateProductDialogFooter} onHide={updateHideDialog}>
                            <div className="field">
                                <label htmlFor="tabname">Tab Name *</label>
                                <InputText
                                    id="tabname"
                                    type="text"
                                    value={updateTabName}
                                    required
                                    onChange={(e) => {
                                        setUpdateTabName(e.target.value);
                                    }}
                                />
                            </div>
                        </Dialog>

                        <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {product && <span>Are you sure you want to delete?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(WalletTabsList, comparisonFn);
