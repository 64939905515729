import React from 'react'

const LoadingSpinner = () => {
  return (
    <>
        <div className='loader-container'>
            <div className='data-loader'></div>
        </div>
    </>
  )
}

export default LoadingSpinner