import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { CodeHighlight } from "./components/CodeHighlight";

import { Button } from "primereact/button";

import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { validateEmail, validateUrl } from "./service/rejexValidateion";

import axios from "axios";

const apikey = process.env.REACT_APP_KEY;

const BlockViewer = (props) => {
    const [tokenFile, setTokenFile] = useState("");
    const [imageurl, setImageUrl] = useState("");

    const [editId, setEditID] = useState("");
    const [iamgeLink, setImageLink] = useState("");
    const [website, setWebsite] = useState("");
    const [whitepaper, setWhitepaper] = useState("");
    const [coinMarketCap, setCoinMarketCap] = useState("");
    const [coinGecko, setCoinGecko] = useState("");
    const [requesterName, setRequesterName] = useState("");
    const [email, setEmail] = useState("");
    const [github, setGithub] = useState("");
    const [tokenCreator, setTokenCreator] = useState("");
    const [message, setMessage] = useState("");
    const [requesterEmailAddress, setRequesterEmailAddress] = useState("");
    const [projectSector, setProjectSector] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [twitter, setTwitter] = useState("");
    const [facebook, setFacebook] = useState("");
    const [telegram, setTelegram] = useState("");
    const [reddit, setReddit] = useState("");
    const [medium, setMedium] = useState("");

    // error states
    const [errwhitepaper, setErrWhitepaper] = useState("");
    const [errcoinMarketCap, setErrCoinMarketCap] = useState("");
    const [errcoinGecko, setErrCoinGecko] = useState("");
    const [errwebsite, setErrWebsite] = useState("");
    const [erremail, setErrEmail] = useState("");
    const [errgithub, setErrGithub] = useState("");
    const [errrequesterEmailAddress, setErrRequesterEmailAddress] = useState("");
    const [errtwitter, setErrTwitter] = useState("");
    const [errfacebook, setErrFacebook] = useState("");
    const [errtelegram, setErrTelegram] = useState("");
    const [errreddit, setErrReddit] = useState("");
    const [errmedium, setErrMedium] = useState("");

    const [blockView, setBlockView] = useState("PREVIEW");

    const [productDialog, setProductDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [dropdownValue, setDropdownValue] = useState(null);

    const [products, setProducts] = useState(null);

    const toast = useRef(null);

    const handleUploadChange = (e) => {
        setTokenFile(e.files[0]);
    };

    const clearStates = () => {
        setWebsite("");
        setWhitepaper("");
        setCoinMarketCap("");
        setCoinGecko("");
        setRequesterName("");
        setEmail("");
        setGithub("");
        setTokenCreator("");
        setMessage("");
        setRequesterEmailAddress("");
        setProjectSector("");
        setProjectDescription("");
        setTwitter("");
        setFacebook("");
        setTelegram("");
        setReddit("");
        setMedium("");

        //error states clear
        setErrEmail("");
        setErrGithub("");
        setErrRequesterEmailAddress("");
        setErrFacebook("");
        setErrTelegram("");
        setErrTwitter("");
        setErrMedium("");
        setErrReddit("");
        setErrWebsite("");
    };

    const setInputData = () => {
        setEditID(props.data[0]._id);
        setImageUrl(props.data[0].verificationData.logo);
        setImageLink(props.data[0].verificationData.logo);
        setWebsite(props.data[0].verificationData.website);
        setWhitepaper(props.data[0].verificationData.whitepaper);
        setCoinMarketCap(props.data[0].verificationData.coinmarketcap);
        setCoinGecko(props.data[0].verificationData.coingecko);
        setRequesterName(props.data[0].verificationData.requesterName);
        setEmail(props.data[0].verificationData.email);
        setGithub(props.data[0].verificationData.github);
        setTokenCreator(props.data[0].tokenCreater);
        setMessage(props.data[0].verificationData.message);
        setRequesterEmailAddress(props.data[0].verificationData.requestEmail);
        setProjectSector(props.data[0].verificationData.projectSector);
        setProjectDescription(props.data[0].verificationData.projectDescription);
        setTwitter(props.data[0].verificationData.twitter);
        setFacebook(props.data[0].verificationData.facebook);
        setTelegram(props.data[0].verificationData.telegram);
        setReddit(props.data[0].verificationData.reddit);
        setMedium(props.data[0].verificationData.medium);
    };

    const dropdownValues = [
        { name: "Yes", code: true },
        { name: "No", code: false },
    ];

    const hideDialog = () => {
        clearStates();
        setSubmitted(false);
        setProductDialog(false);
    };

    const updateProduct = async () => {
        let temp = 1;
        if (!validateUrl(website)) {
            setErrWebsite("invalid website.");
            temp = 0;
        } else {
            setErrWebsite("");
        }
        if (whitepaper && !validateUrl(whitepaper)) {
            setErrWhitepaper("invalid whitepaper url.");
            temp = 0;
        } else {
            setErrWhitepaper("");
        }
        if (coinMarketCap && !validateUrl(coinMarketCap)) {
            setErrCoinMarketCap("invalid Coin Market Cap url.");
            temp = 0;
        } else {
            setErrCoinMarketCap("");
        }
        if (coinGecko && !validateUrl(coinGecko)) {
            setErrCoinGecko("invalid coin Gecko url.");
            temp = 0;
        } else {
            setErrCoinGecko("");
        }
        if (!validateEmail(email)) {
            setErrEmail("invalid email address.");
            temp = 0;
        } else {
            setErrEmail("");
        }
        if (github && !validateUrl(github)) {
            setErrGithub("invalid github url.");
            temp = 0;
        } else {
            setErrGithub("");
        }
        if (!validateEmail(requesterEmailAddress)) {
            setErrRequesterEmailAddress("invalid requester email address.");
            temp = 0;
        } else {
            setErrRequesterEmailAddress("");
        }
        if (twitter && !validateUrl(twitter)) {
            setErrTwitter("invalid twitter url.");
            temp = 0;
        } else {
            setErrTwitter("");
        }
        if (facebook && !validateUrl(facebook)) {
            setErrFacebook("invalid facebook url.");
            temp = 0;
        } else {
            setErrFacebook("");
        }
        if (telegram && !validateUrl(telegram)) {
            setErrTelegram("invalid telegram url.");
            temp = 0;
        } else {
            setErrTelegram("");
        }
        if (reddit && !validateUrl(reddit)) {
            setErrReddit("invalid reddit url.");
            temp = 0;
        } else {
            setErrReddit("");
        }
        if (medium && !validateUrl(medium)) {
            setErrMedium("invalid medium url.");
            temp = 0;
        } else {
            setErrMedium("");
        }

        if (temp === 1) {
            const formData = new FormData();

            formData.append("method", "explorerToken");
            formData.append("submethod", "update");
            formData.append("key", apikey);
            formData.append("id", editId);
            formData.append("file", tokenFile ? tokenFile : iamgeLink);
            formData.append("website", website);
            formData.append("whitepaper", whitepaper);
            formData.append("coinMarketCap", coinMarketCap);
            formData.append("coinGecko", coinGecko);
            formData.append("requesterName", requesterName);
            formData.append("email", email);
            formData.append("github", github);
            formData.append("tokenCreator", tokenCreator);
            formData.append("message", message);
            formData.append("requesterEmail", requesterEmailAddress);
            formData.append("sector", projectSector);
            formData.append("description", projectDescription);
            formData.append("twitter", twitter);
            formData.append("facebook", facebook);
            formData.append("telegram", telegram);
            formData.append("reddit", reddit);
            formData.append("medium", medium);

            await axios.post(process.env.REACT_APP_API_URL, formData).then((res) => {
                if (res.data.error) {
                    toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                } else {
                    toast.current.show({ severity: "success", summary: "Success", detail: res.data.success, life: 3000 });
                    props.setReload(!props.reload);
                    clearStates();
                    setProductDialog(false);
                }
            });
        }
    };

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateProduct} />
        </>
    );

    const editProduct = () => {
        setInputData();
        setProductDialog(true);
    };

    const copyCode = async (event) => {
        await navigator.clipboard.writeText(props.code);
        event.preventDefault();
    };

    return (
        <div className="block-viewer">
            <Toast ref={toast} />
            <div className="block-section">
                <div className="block-header">
                    <span className="block-title">
                        <span>{props.header}</span>
                        {props.new && <span className="badge-new">New</span>}
                    </span>
                    <div className="block-actions">
                        {!props.btnhide ? (
                            <button tabIndex="0" className={classNames("p-link", { "block-action-active": blockView === "PREVIEW" })} onClick={() => editProduct()}>
                                <span className="p-button-icon p-c p-button-icon-left pi pi-pencil"></span>&nbsp;<span>Edit</span>
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className="block-content">
                    {blockView === "PREVIEW" && (
                        <div className={props.containerClassName} style={props.previewStyle}>
                            {props.children}
                        </div>
                    )}

                    {blockView === "CODE" && <CodeHighlight>{props.code}</CodeHighlight>}
                </div>

                <Dialog visible={productDialog} style={{ width: "450px" }} header="Edit Token Details" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    {/* <div className="field">
                        <label htmlFor="image">Logo *</label>
                        <div className="card">
                            <FileUpload mode="basic" name="image" customUpload={true} url="./assets" auto="true" accept="image/*" maxFileSize={2000000}
                                uploadHandler={handleUploadChange}
                            />
                        </div>
                    </div> */}
                    <div className="field">
                        <label htmlFor="website">Logo *</label>
                        <InputText
                            id="website"
                            type="text"
                            value={process.env.REACT_APP_IMAGE_TOKENLOGO + iamgeLink}
                            required
                            onChange={(e) => {
                                setImageLink(e.target.value);
                            }}
                            disabled="true"
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="website">Website *</label>
                        <InputText
                            id="website"
                            type="text"
                            value={website}
                            required
                            onChange={(e) => {
                                setWebsite(e.target.value);
                            }}
                        />
                        {errwebsite && <p className="p-1 err-mess">{errwebsite}</p>}
                    </div>
                    <div className="field">
                        <label htmlFor="whitepaper">Whitepaper *</label>
                        <InputText
                            id="whitepaper"
                            type="text"
                            value={whitepaper}
                            required
                            onChange={(e) => {
                                setWhitepaper(e.target.value);
                            }}
                        />
                        {errwhitepaper && <p className="p-1 err-mess">{errwhitepaper}</p>}
                    </div>
                    <div className="field">
                        <label htmlFor="coinmarketcap">CoinMarketCap *</label>
                        <InputText
                            id="coinmarketcap"
                            type="text"
                            value={coinMarketCap}
                            required
                            onChange={(e) => {
                                setCoinMarketCap(e.target.value);
                            }}
                        />
                        {errcoinMarketCap && <p className="p-1 err-mess">{errcoinMarketCap}</p>}
                    </div>
                    <div className="field">
                        <label htmlFor="coingecko">CoinGecko *</label>
                        <InputText
                            id="coingecko"
                            type="text"
                            value={coinGecko}
                            required
                            onChange={(e) => {
                                setCoinGecko(e.target.value);
                            }}
                        />
                        {errcoinGecko && <p className="p-1 err-mess">{errcoinGecko}</p>}
                    </div>
                    <div className="field">
                        <label htmlFor="requestername">Requester Name *</label>
                        <InputText
                            id="requestername"
                            type="text"
                            value={requesterName}
                            required
                            onChange={(e) => {
                                setRequesterName(e.target.value);
                            }}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="email">Email *</label>
                        <InputText
                            id="email"
                            type="email"
                            value={email}
                            required
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                        {erremail && <p className="p-1 err-mess">{erremail}</p>}
                    </div>
                    <div className="field">
                        <label htmlFor="github">Github *</label>
                        <InputText
                            id="github"
                            type="text"
                            value={github}
                            required
                            onChange={(e) => {
                                setGithub(e.target.value);
                            }}
                        />
                        {errgithub && <p className="p-1 err-mess">{errgithub}</p>}
                    </div>
                    <div className="field">
                        <label htmlFor="contractcreator">Token Creator *</label>
                        <InputText
                            id="contractcreator"
                            type="text"
                            value={tokenCreator}
                            required
                            onChange={(e) => {
                                setTokenCreator(e.target.value);
                            }}
                        />
                    </div>
                    {/* <div className="field">
                        <label htmlFor="hash">Hash # *</label>
                        <InputText id="hash" type="text" defaultValue="gdfggffgfgfgfggfggfdgfdggdfgrrttryy" required />
                    </div>
                    <div className="field">
                        <label htmlFor="decimal">Decimal *</label>
                        <InputText id="decimal" type="text" defaultValue="10" required />
                    </div>
                    <div className="field">
                        <label htmlFor="soliditycompilerversion">Solidity Compiler Version *</label>
                        <InputText id="soliditycompilerversion" type="text" defaultValue="10.10.10" required />
                    </div> */}
                    <div className="field">
                        <label htmlFor="comment">Message *</label>
                        <InputText
                            id="comment"
                            type="text"
                            value={message}
                            required
                            onChange={(e) => {
                                setMessage(e.target.value);
                            }}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="requesteremailaddress">Requester Email Address *</label>
                        <InputText
                            id="requesteremailaddress"
                            type="text"
                            value={requesterEmailAddress}
                            required
                            onChange={(e) => {
                                setRequesterEmailAddress(e.target.value);
                            }}
                        />
                        {errrequesterEmailAddress && <p className="p-1 err-mess">{errrequesterEmailAddress}</p>}
                    </div>
                    <div className="field">
                        <label htmlFor="projectsector">Project Sector *</label>
                        <InputText
                            id="projectsector"
                            type="text"
                            value={projectSector}
                            required
                            onChange={(e) => {
                                setProjectSector(e.target.value);
                            }}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="projectdescription">Project Description *</label>
                        <InputText
                            id="projectdescription"
                            type="text"
                            value={projectDescription}
                            required
                            onChange={(e) => {
                                setProjectDescription(e.target.value);
                            }}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="twitter">Twitter *</label>
                        <InputText
                            id="twitter"
                            type="text"
                            value={twitter}
                            required
                            onChange={(e) => {
                                setTwitter(e.target.value);
                            }}
                        />
                        {errtwitter && <p className="p-1 err-mess">{errtwitter}</p>}
                    </div>
                    <div className="field">
                        <label htmlFor="facebook">Facebook *</label>
                        <InputText
                            id="facebook"
                            type="text"
                            value={facebook}
                            required
                            onChange={(e) => {
                                setFacebook(e.target.value);
                            }}
                        />
                        {errfacebook && <p className="p-1 err-mess">{errfacebook}</p>}
                    </div>
                    <div className="field">
                        <label htmlFor="telegram">Telegram *</label>
                        <InputText
                            id="telegram"
                            type="text"
                            value={telegram}
                            required
                            onChange={(e) => {
                                setTelegram(e.target.value);
                            }}
                        />
                        {errtelegram && <p className="p-1 err-mess">{errtelegram}</p>}
                    </div>
                    <div className="field">
                        <label htmlFor="reddit">Reddit *</label>
                        <InputText
                            id="reddit"
                            type="text"
                            value={reddit}
                            required
                            onChange={(e) => {
                                setReddit(e.target.value);
                            }}
                        />
                        {errreddit && <p className="p-1 err-mess">{errreddit}</p>}
                    </div>
                    <div className="field">
                        <label htmlFor="medium">Medium *</label>
                        <InputText
                            id="medium"
                            type="text"
                            value={medium}
                            required
                            onChange={(e) => {
                                setMedium(e.target.value);
                            }}
                        />
                        {errmedium && <p className="p-1 err-mess">{errmedium}</p>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default BlockViewer;
