import React, { useState, useEffect, useRef, useContext } from "react";
import classNames from "classnames";
import { Route, useLocation, Redirect, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";

import PageNotFound from "./components/PageNotFound";
import Home from "./components/Home";
import Profile from "./components/Profile";
import Dashboard from "./components/Dashboard";
import PublicNameTagList from "./components/PublicNameTagList";
import UsersList from "./components/UsersList";
import AdvertisementList from "./components/AdvertisementList";
import NodesList from "./components/NodesList";
// import TokenList from './components/TokenList';
import TokenList from "./components/TokenList";
import TokenDetails from "./components/TokenDetails";
import WalletUsersList from "./components/WalletUsersList";
import SocialMediaList from "./components/SocialMediaList";
import WalletChainTokenList from "./components/WalletChainTokenList";
import WalletTabsList from "./components/WalletTabsList";
import NewsList from "./components/NewsList";
import ChainList from "./components/ChainList";
import WalletTokenList from "./components/WalletTokenList";
import WalletList from "./components/WalletList";
import BackupWalletNoticeList from "./components/BackupWalletNoticeList";
import AdminList from "./components/AdminList";
import APIKeyList from "./components/APIKeyList";
import Roles from "./components/Roles";
import CryptoJs from "crypto-js";

import Validator from "./components/Validator";
import Withdrawal from "./components/Withdrawal";
import StakeList from "./components/StakeList";
import UnstakeList from "./components/UnstakeList";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";

import AuthContext from "./AuthProvider";

import AdminMenu from "./AdminMenu.json";

import axios from "axios";
import EditAdminUser from "./components/EditAdminUser";
import WalletDashboard from "./components/WalletDashboard";
import BlockAddress from "./components/BlockAddress";
import BlockchainUpdate from "./components/BlockchainUpdate";
import Maintenance from "./components/Maintenance";
import TokenDetailsRequested from "./components/TokenDetailsRequested";
import CryptoJS from "crypto-js";
import AddNameTag from "./components/AddNameTag";

const apikey = process.env.REACT_APP_KEY;

// const UserRoles = createContext(null);

const App = () => {
    const history = useHistory();

    const { setCheckRoles, roles, setRoles, adminRoles, setAdminRoles, verifyAndGetUser, stringWihtDot, logout } = useContext(AuthContext);
    const { encuserpass } = verifyAndGetUser();
    const [issuperadmin, setIssuperadmin] = useState(false);
    const [menuItems, setmenuItems] = useState([]);

    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    var curl = window.location.href.split("/#");

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    const getRoles = async () => {
        const { encuserpass } = verifyAndGetUser();
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/getroles`, {
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getRoles : ", res.data.error);
                } else {
                    var roling = res?.data;
                    var explorer = roling?.filter((item) => item.id.split("_")[0] === "0001");
                    var blockchain = roling?.filter((item) => item.id.split("_")[0] === "0005");
                    let wallet = roling?.filter((item) => item.id.split("_")[0] === "0002");
                    let admin = roling?.filter((item) => item.id.split("_")[0] === "0003");
                    let apiKesy = roling?.filter((item) => item.id.split("_")[0] === "0004");
                    // var tokenCount = 0;
                    // for (let index = 0; index < explorer.length; index++) {
                    //     if (tokenCount === 1) {
                    //         explorer.pop(index)
                    //     }
                    //     if (explorer[index]?.label === 'Tokens') {
                    //         tokenCount = tokenCount + 1
                    //     }
                    // }
                    const menu = [];
                    if (explorer && explorer?.length != 0) {
                        menu.push({ label: "Explorer", items: [...explorer[0].items] });
                    }
                    if (blockchain && blockchain?.length != 0) {
                        menu.push({ label: "Blockchain", items: [...blockchain[0].items] });
                    }
                    if (wallet && wallet?.length != 0) {
                        menu.push({ label: "Wallet", items: [...wallet[0].items] });
                    }
                    if (admin && admin?.length != 0) {
                        menu.push({ label: "Admin", items: [...admin[0].items] });
                    }
                    if (apiKesy && apiKesy?.length != 0) {
                        menu.push({ label: "Api Key", items: [...apiKesy[0].items] });
                    }
                    setRoles(res.data);
                }
            });
    };

    useEffect(() => {
        getRoles();
    }, []);

    const verifyAdmin = async () => {
        const { username, password } = verifyAndGetUser();
        var encbody = CryptoJS.AES.encrypt(JSON.stringify({ username: username, password: password }), process.env.REACT_APP_PASSWORD).toString();
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}admin/signin`, { token: encbody }).then((res) => {
                if (res?.data?.error) {
                    console.log("error in verifyAdmin : ", res.data.error);
                    logout();
                } else {
                    var roling = [];
                    if (res.data.superadmin) {
                        setAdminRoles([...roles]);
                        setCheckRoles(res.data.roles);
                    } else {
                        var roling = res?.data.roles;
                        var explorer = roling?.filter((item) => item.id.split("_")[0] === "0001");
                        var blockchain = roling?.filter((item) => item.id.split("_")[0] === "0005");
                        let wallet = roling?.filter((item) => item.id.split("_")[0] === "0002");
                        let admin = roling?.filter((item) => item.id.split("_")[0] === "0003");
                        let apiKesy = roling?.filter((item) => item.id.split("_")[0] === "0004");
                        // var tokenCount = 0;
                        // for (let index = 0; index < explorer.length; index++) {
                        //     if (tokenCount === 1) {
                        //         explorer.pop(index)
                        //     }
                        //     if (explorer[index]?.label === 'Tokens') {
                        //         tokenCount = tokenCount + 1
                        //     }
                        // }
                        const menu = [];
                        if (explorer && explorer?.length != 0) {
                            menu.push({ label: "Explorer", items: [...explorer] });
                        }
                        if (blockchain && blockchain?.length != 0) {
                            menu.push({ label: "Blockchain", items: [...blockchain] });
                        }
                        if (wallet && wallet?.length != 0) {
                            menu.push({ label: "Wallet", items: [...wallet] });
                        }
                        if (admin && admin?.length != 0) {
                            menu.push({ label: "Admin", items: [...admin] });
                        }
                        if (apiKesy && apiKesy?.length != 0) {
                            menu.push({ label: "Api Key", items: [...apiKesy] });
                        }
                        setCheckRoles(res.data.roles);
                        setAdminRoles(menu);
                    }
                }
            });
        } catch (err) {
            console.log("error in handleAdminLogin() (catch)", err);
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, [roles]);

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };
    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    // let explorer = roles?.filter(item => item.id.split("_")[0] === "0001");

    // let explorer = roles?.filter(item => item.id.split("_")[0] === "0001");
    // let wallet = roles?.filter(item => item.id.split("_")[0] === "0002");
    // let admin = roles?.filter(item => item.id.split("_")[0] === "0003");
    // let apiKey = roles?.filter(item => item.id.split("_")[0] === "0004");

    // const menu = [];

    // if (explorer && explorer?.length != 0) {
    //     menu.push({ label: 'Explorer', items: [...explorer] })
    // }
    // if (wallet && wallet?.length != 0) {
    //     menu.push({ label: 'Wallet', items: [...wallet] })
    // }
    // if (admin && admin?.length != 0) {
    //     menu.push({ label: 'Admin', items: [...admin] })
    // }
    // if (apiKey && apiKey?.length != 0) {
    //     menu.push({ label: 'Api Key', items: [...apiKey] })
    // }

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });
    return (
        <>
            {curl[1] === "/" ? (
                <div className={wrapperClass} onClick={onWrapperClick}>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                    {/* <div className="layout-main-container">
                        <div className="layout-main"> */}
                    <Route path="/" exact render={() => <Home colorMode={layoutColorMode} location={location} />} />
                    {/* </div>
                    </div> */}

                    <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />
                </div>
            ) : (
                <div className={wrapperClass} onClick={onWrapperClick}>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                    <AppTopbar
                        onToggleMenuClick={onToggleMenuClick}
                        rippleEffect={ripple}
                        onRippleEffect={onRipple}
                        inputStyle={inputStyle}
                        onInputStyleChange={onInputStyleChange}
                        layoutMode={layoutMode}
                        onLayoutModeChange={onLayoutModeChange}
                        layoutColorMode={layoutColorMode}
                        onColorModeChange={onColorModeChange}
                        mobileTopbarMenuActive={mobileTopbarMenuActive}
                        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
                    />

                    <div className="layout-sidebar" onClick={onSidebarClick}>
                        <AppMenu model={issuperadmin ? adminRoles : adminRoles} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                        {/* <AppMenu model={AdminMenu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} /> */}
                    </div>

                    <div className="layout-main-container">
                        <div className="layout-main">
                            <Route path="/" exact element={<Home />} />
                            <Route path="/edit-admin/:id" exact component={EditAdminUser} />
                            <Route path="/dashboard" component={Dashboard} />
                            <Route path="/profile" exact component={Profile} />
                            <Route path="/public-name-tag-list" exact component={PublicNameTagList} />
                            <Route path="/users-list" exact component={UsersList} />
                            {/* <Route path="/advertisement-list" exact component={AdvertisementList} /> */}
                            {/* <Route path="/nodes-list" exact component={NodesList} /> */}
                            <Route path="/tokens-list" exact component={TokenList} />
                            <Route path="/token-details/:id" exact component={TokenDetails} />
                            <Route path="/token-requested-details/:id" exact component={TokenDetailsRequested} />
                            {/* <Route path="/wallet-dashboard" exact component={WalletDashboard} />
                            <Route path="/wallet-users-list" exact component={WalletUsersList} />
                            <Route path="/social-media-list" exact component={SocialMediaList} />
                            <Route path="/wallet-chain-token-list" exact component={WalletChainTokenList} />
                            <Route path="/wallet-tabs-list" exact component={WalletTabsList} />
                            <Route path="/news-list" exact component={NewsList} />
                            <Route path="/chain-list" exact component={ChainList} />
                            <Route path="/wallet-list" exact component={WalletList} />
                            <Route path="/wallet-token-list" exact component={WalletTokenList} /> */}

                            {/* <Route path="/backup-wallet-notice-list" exact component={BackupWalletNoticeList} /> */}
                            <Route path="/admin-list" exact component={AdminList} />
                            {/* <Route path="/api-key-list" exact component={APIKeyList} /> */}
                            <Route path="/roles" exact component={Roles} />
                            {/* <Route path="/BlockedAddress" exact component={BlockAddress} />
                            <Route path="/BlockchainUpdate" exact component={BlockchainUpdate} /> */}
                            <Route path="/addnametag" exact component={AddNameTag} />
                            {/* <Route path="/validators" exact component={Validator} />
                            <Route path="/withdrawals" exact component={Withdrawal} /> */}

                            {/* <Route path="/staking" exact component={StakeList} />
                            <Route path="/unstaking" exact component={UnstakeList} /> */}

                            {/* <Route path="/maintenance" exact component={Maintenance} /> */}
                            <Route path="/pagenotfound" exact component={PageNotFound} />
                        </div>

                        <AppFooter layoutColorMode={layoutColorMode} />
                    </div>

                    <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                        <div className="layout-mask p-component-overlay"></div>
                    </CSSTransition>
                </div>
            )}
        </>
    );
};

export default App;
// export {UserRoles};
