import React, { useState, useEffect, useRef, useContext } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import AuthContext from "../AuthProvider";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProductService } from "../service/ProductService";
import axios from "axios";
import LoadingSpinner from "../service/LoadingSpinner";
import { CSVLink } from "react-csv";

const apikey = process.env.REACT_APP_KEY;

const WalletUsersList = () => {
    document.title = "Wallet Users";

    const { checkRoles, roles } = useContext(AuthContext);

    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };

    const [products, setProducts] = useState(null);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductDialog2, setdeleteProductDialog2] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [walletUserID, setWallteUsreID] = useState("");
    const [walletUserAddress, setWallteUsreAddress] = useState("");
    const [walletUserStatus, setWallteUsreStatus] = useState("");

    const [loading, setLoading] = useState(true);

    const [csvAllData, setCsvAllData] = useState(null);

    // useEffect(() => {
    //     const productService = new ProductService();
    //     productService.getProducts().then(data => setProducts(data));
    // }, []);

    useEffect(() => {
        // const productService = new ProductService();
        // productService.getProducts().then(data => setProducts(data));

        getWalletUserList();
    }, []);

    const getWalletUserList = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "usersList", submethod: "get", key: apikey }).then((res) => {
            if (res?.data?.error) {
                // toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.error, life: 3000 });
            } else {
                // console.log(res.data);
                setProducts(res.data);
                setLoading(false);
                // toast.current.show({ severity: 'success', summary: 'Successful', detail: res.data.success, life: 3000 });
                var csvData = new Array();
                for (let i = 0; i < res.data.length; i++) {
                    csvData.push({ ind: res.data[i].id, address: res.data[i].address, createdat: res.data[i].createdat, lastseen: res.data[i].lastseen, status: res.data[i].status });
                }
                setCsvAllData(csvData);
            }
        });
    };

    const csvReport = {
        filename: "Wallet-Users-list.csv",
        data: csvAllData,
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setdeleteProductDialog2(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = () => {
        setSubmitted(true);

        if (product.name.trim()) {
            let _products = [...products];
            let _product = { ...product };
            if (product.id) {
                const index = findIndexById(product.id);

                _products[index] = _product;
                toast.current.show({ severity: "success", summary: "Successful", detail: "Product Updated", life: 3000 });
            } else {
                _product.id = createId();
                _product.image = "product-placeholder.svg";
                _products.push(_product);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Product Created", life: 3000 });
            }

            setProducts(_products);
            setProduct(emptyProduct);
        }
    };

    const deleteProduct = async () => {
        // let _products = products.filter(val => val.id !== product.id);
        // setProducts(_products);
        // setBlockStatus(1);
        await axios.post(process.env.REACT_APP_API_URL, { method: "usersList", submethod: "updateStatus", key: apikey, address: walletUserAddress, status: walletUserStatus }).then((res) => {
            if (res?.data?.error) {
                toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
            } else {
                toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
                // console.log(res.data);
                setWallteUsreAddress("");
                setWallteUsreID("");
                setDeleteProductDialog(false);
                getWalletUserList();
            }
        });
    };

    const deleteProduct2 = () => {
        let _products = products.filter((val) => val.id !== product.id);
        setProducts(_products);
        setdeleteProductDialog2(false);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    };

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.id}
            </>
        );
    };

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.address}
            </>
        );
    };

    const createdAtBodyTemplate = (rowData) => {
        // console.log(rowData)
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.createdat}
            </>
        );
    };
    const lastSeenBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.lastseen}
            </>
        );
    };

    const confirmDeleteProduct = (product) => {
        setWallteUsreID(product._id);
        setWallteUsreAddress(product.address);
        setWallteUsreStatus(product.status);
        setProduct(product);
        setDeleteProductDialog(true);
    };

    const confirmDeleteProduct2 = (product) => {
        setProduct(product);
        setdeleteProductDialog2(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.status === false ? (
                    <Button icon="pi pi-check" type="button" className="p-button-success" label="Unblock" onClick={() => confirmDeleteProduct(rowData)}></Button>
                ) : (
                    <Button icon="pi pi-times" type="button" className="p-button-danger" label="Block" onClick={() => confirmDeleteProduct(rowData)}></Button>
                )}
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Wallet Users List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                {csvAllData ? (
                    <CSVLink className="p-button p-component p-button-secondary p-2 m-1" {...csvReport}>
                        .CSV
                    </CSVLink>
                ) : (
                    ""
                )}
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteProductDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            {walletUserStatus === true ? <Button label="Block" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} /> : <Button label="Unblock" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />}
            {/* <Button label="Block" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} /> */}
        </>
    );
    const deleteProductDialogFooter2 = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="UnBlock" icon="pi pi-check" className="p-button-text" onClick={deleteProduct2} />
        </>
    );

    return !checkRoles || checkRoles?.filter((item) => item.id === "0002_001").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <div className="grid WalletUsersList-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        <DataTable
                            ref={dt}
                            value={products}
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={globalFilter}
                            emptyMessage={loading ? <LoadingSpinner /> : "data not found."}
                            header={header}
                            responsiveLayout="scroll"
                        >
                            <Column field="id" header="#" sortable body={codeBodyTemplate} headerStyle={{ width: "14%", minWidth: "15rem" }}></Column>
                            <Column field="address" header="Address" sortable body={addressBodyTemplate} headerStyle={{ width: "14%", minWidth: "20rem" }}></Column>
                            <Column field="created_at" header="Created At" sortable body={createdAtBodyTemplate} headerStyle={{ width: "14%", minWidth: "15rem" }}></Column>
                            <Column field="last_seen" header="Last seen" sortable body={lastSeenBodyTemplate} headerStyle={{ width: "14%", minWidth: "15rem" }}></Column>

                            <Column field="Status" header="Status" body={actionBodyTemplate} headerStyle={{ width: "100%", minWidth: "10rem" }}></Column>
                        </DataTable>

                        <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Warning" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {product && <span>Are you sure want to {walletUserStatus === true ? "Block" : "Unblock"} ?</span>}
                            </div>
                        </Dialog>

                        {/* <Dialog visible={deleteProductDialog2} style={{ width: '450px' }} header="Warning" modal footer={deleteProductDialogFooter2} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {product && <span>Are you sure want to UnBlock ?</span>}
                            </div>
                        </Dialog> */}
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(WalletUsersList, comparisonFn);
