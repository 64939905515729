import React, { useState, useEffect, useRef, useContext } from "react";

import { Button } from "primereact/button";

import { InputText } from "primereact/inputtext";
import { ProductService } from "../service/ProductService";
import { Toast } from "primereact/toast";

import AdminMenu from "../AdminMenu.json";

import { PickList } from "primereact/picklist";

import { validateEmail } from "../service/rejexValidateion";
import AuthContext from "../AuthProvider";

import axios from "axios";
import { useHistory, useParams, useLocation } from "react-router-dom";
import LoadingSpinner from "../service/LoadingSpinner";
const apikey = process.env.REACT_APP_KEY;

const EditAdminUser = () => {
    const { id } = useParams();

    const location = useLocation();

    const history = useHistory();

    const { checkRoles, roles, verifyAndGetUser, stringWihtDot } = useContext(AuthContext);
    const { encuserpass } = verifyAndGetUser();

    const toast = useRef(null);

    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [errRoles, setErrRoles] = useState("");
    const [errPassword, setErrPassword] = useState("");

    const [errEmail, setErrEmail] = useState("");

    const [listRole, setListRole] = useState([]);
    const [adminRoles, setAdminRoles] = useState([]);

    useEffect(() => {
        if (id) {
            getAdminUserData();
        } else {
            history.push("/admin-list");
        }
    }, []);

    const getAdminUserData = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/adminuserdetails`, {
                params: {
                    adminuser: id,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setEmail(res?.data.email);
                    setUsername(res?.data.username);
                    setAdminRoles(res?.data.roles);
                }
            });
    };

    const menulist = [];
    const roleslist = [];

    roles.map((mainitems) => {
        mainitems.items.map((item) => {
            // if(!item.items){
            if (!adminRoles?.some((role) => role.id == item.id)) {
                // menulist.push({name: item.label, label: item.label, id: item.id, icon: item.icon, to: item.to});
                if (item.id === "0001_006") {
                    menulist.push({ name: "Tokens(Explorer)", label: item.label, id: item.id, icon: item.icon, to: item.to });
                } else if (item.id === "0002_004") {
                    menulist.push({ name: "Tokens(Wallet)", label: item.label, id: item.id, icon: item.icon, to: item.to });
                } else if (item.id === "0001_003") {
                    menulist.push({ name: "Users(Explorer)", label: item.label, id: item.id, icon: item.icon, to: item.to });
                } else if (item.id === "0002_001") {
                    menulist.push({ name: "Users(Wallet)", label: item.label, id: item.id, icon: item.icon, to: item.to });
                } else if (item.id === "0001_001") {
                    menulist.push({ name: "Dashboard(Explorer)", label: item.label, id: item.id, icon: item.icon, to: item.to });
                } else if (item.id === "0002_008") {
                    menulist.push({ name: "Dashboard(Wallet)", label: item.label, id: item.id, icon: item.icon, to: item.to });
                } else {
                    menulist.push({ name: item.label, label: item.label, id: item.id, icon: item.icon, to: item.to });
                }
            }
            // }
            // item.items && item.items.map((subitem) => {
            //     if(!adminRoles?.some(role => role.id == subitem.id)){
            //         menulist.push({name: subitem.label, label: "Tokens", id: subitem.id, icon: subitem.icon, to: "/tokens-list"});
            //     }
            // });
        });
    });

    const updateAdmin = async () => {
        // let roles = [];

        // picklistTargetValue.map((item) => {
        //     const id = item.id.split("_");
        //     const newid = id[0];
        //     roles.push({name: item.name, id: item.id})
        //     if(newid === "0001"){
        //         if(!roles.find(item => item.id === "0001")){
        //             roles.push({name: "Explorer", id: newid});
        //         }
        //     } else if(newid === "0002"){
        //         if(!roles.find(item => item.id === "0002")){
        //             roles.push({name: "Wallet", id: newid});
        //         }
        //     } else if(newid === "0003"){
        //         if(!roles.find(item => item.id === "0003")){
        //             roles.push({name: "Admin", id: newid});
        //         }
        //     } else if(newid === "0004"){
        //         if(!roles.find(item => item.id === "0004")){
        //             roles.push({name: "Api key", id: newid});
        //         }
        //     }
        // })

        // console.log("new roles :", roles);

        let temp = 1;
        if (!validateEmail(email)) {
            temp = 0;
            setErrEmail("Invalid email address.");
        } else {
            setErrEmail("");
        }
        if (password) {
            if (password.length < 8) {
                temp = 0;
                setErrPassword("Enter atleast 8 charachter in confirm password.");
            } else {
                setErrPassword("");
            }
        }
        if (adminRoles.length == 0) {
            temp = 0;
            setErrRoles("select atleast one role.");
        } else {
            setErrRoles("");
        }
        if (temp === 1) {
            try {
                await axios
                    .post(
                        `${process.env.REACT_APP_API_URL}admin/editadmin`,
                        {
                            uname: username,
                            pass: password,
                            email: email,
                            roles: adminRoles,
                        },
                        {
                            params: {
                                action: "edit",
                            },
                            headers: {
                                etoken: encuserpass,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data.error) {
                            toast.current.show({ severity: "error", summary: "Error!", detail: res.data.error, life: 3000 });
                            console.log("error in updating status : ", res.data.error);
                        } else {
                            toast.current.show({ severity: "success", summary: "Success!", detail: "Admin user update successfuly.", life: 3000 });
                        }
                    });
            } catch (err) {
                console.log("error in updating status (catch) :", err);
            }
        }
    };

    const [picklistSourceValue, setPicklistSourceValue] = useState(menulist);
    // const [picklistTargetValue, setPicklistTargetValue] = useState(adminRoles);

    return !checkRoles ? (
        <LoadingSpinner />
    ) : checkRoles?.filter((item) => item.id === "0003_002").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <Toast ref={toast} />
            <div className="card mdb">
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card p-fluid">
                            <h5>
                                <b>Edit Admin</b>
                            </h5>
                            <div className="field">
                                <label htmlFor="username">Username *</label>
                                <InputText id="username" value={username} type="text" required onChange={(e) => setUsername(e.target.value)} disabled />
                            </div>
                            <div className="field">
                                <label htmlFor="email">Email *</label>
                                <InputText id="email" value={email} type="text" required onChange={(e) => setEmail(e.target.value)} />
                                {errEmail && <p className="p-1 err-mess">{errEmail}</p>}
                            </div>
                            <div className="field">
                                <label htmlFor="password">Password *</label>
                                <InputText id="password" placeholder="********" value={password} type="password" required onChange={(e) => setPassword(e.target.value)} />
                                <small>Enter passwor if you wnat to update.</small>
                                {errPassword && <p className="p-1 err-mess">{errPassword}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <h5>Roles</h5>
                            <PickList
                                source={menulist}
                                target={adminRoles}
                                sourceHeader="From"
                                targetHeader="To"
                                itemTemplate={(item) => <div>{item.name}</div>}
                                onChange={(e) => {
                                    setPicklistSourceValue(e.source);
                                    setAdminRoles(e.target);
                                }}
                                sourceStyle={{ height: "200px" }}
                                targetStyle={{ height: "200px" }}
                            ></PickList>
                        </div>
                        {errRoles && <p className="p-1 err-mess">{errRoles}</p>}
                        <div className="field grid">
                            <div className="col-12 md:col-12 text-right">
                                <Button type="button" label="Update Admin" onClick={updateAdmin}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EditAdminUser, comparisonFn);
