import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AuthContext from "../AuthProvider";
import { CSVLink } from "react-csv";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import EmptyDataMessage from "./common/EmptyDataMessage";
import LoadingSpinner from "../service/LoadingSpinner";

import axios from "axios";

const apikey = process.env.REACT_APP_KEY;

const TokenList = () => {
    document.title = "Explorer Tokens";

    const [activeIndex, setActiveIndex] = useState(0);

    const { checkRoles, verifyAndGetUser } = useContext(AuthContext);
    const { encuserpass } = verifyAndGetUser();

    const [spinner, setSpinner] = useState(true);

    const [verificationId, setVerificationId] = useState("");
    const [verificationStatus, setVerificationStatus] = useState("");

    const [deleteImg, setDeleteImg] = useState("");

    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };

    const [verifiedTokens, setVerifiedTokens] = useState("");
    const [verifiedCurrentPage, setVerifiedCurrentPage] = useState(1);
    const [verifiedPageSize, setVerifiedPageSize] = useState(10);
    const [verifiedPages, setVerifiedPages] = useState(0);

    const [unverifiedTokens, setUnverifiedTokens] = useState("");
    const [unverifiedCurrentPage, setUnverifiedCurrentPage] = useState(1);
    const [unverifiedPageSize, setUnverifiedPageSize] = useState(10);
    const [unverifiedPages, setUnverifiedPages] = useState(0);

    const [requestedTokens, setRequestedTokens] = useState("");
    const [requestedCurrentPage, setRequestedCurrentPage] = useState(1);
    const [requestedPageSize, setRequestedPageSize] = useState(10);
    const [requestedPages, setRequestedPages] = useState(0);

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [verifyAndDeleteProductDialog, setVerifyAndDeleteProductDialog] = useState(false);
    const [deleteTokenDialog, setDeleteTokenDialog] = useState(false);
    const [deleteRequesterTokenDialog, setDeleteRequesterTokenDialog] = useState(false);
    const [deleteProductDialog2, setDeleteProductsDialog2] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [loading, setLoading] = useState(true);

    const toast = useRef(null);
    const dt = useRef(null);

    const [VerifyStatus, setVerifyStatus] = useState(0);

    const [verifiedTokeCsv, setVerifiedTokenCsv] = useState(null);
    const [unverifiedTokeCsv, setUnverifiedTokenCsv] = useState(null);

    const [contractAddress, setContractAddress] = useState("");

    const [deleteId, setDeleteId] = useState("");

    const [deleteRequesterImg, setDeleteRequesterImg] = useState("");

    // useEffect(() => {
    //     const productService = new ProductService();
    //     productService.getProducts().then(data => setProducts(data));
    // }, []);

    useEffect(() => {
        getVerifiedToken();
        getUnverifiedToken();
        getRequestedTokenUpdateDetails();
    }, [verifiedCurrentPage, verifiedPageSize, unverifiedCurrentPage, unverifiedPageSize, requestedCurrentPage, requestedPageSize]);

    const getVerifiedToken = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/tokens?status=true`, {
                params: {
                    limit: verifiedPageSize,
                    page: verifiedCurrentPage,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setVerifiedTokens(res.data.items);
                    setVerifiedPages(res.data.pages);
                    setSpinner(false);
                }
            });
    };
    const getUnverifiedToken = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/tokens?status=false`, {
                params: {
                    limit: unverifiedPageSize,
                    page: unverifiedCurrentPage,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setUnverifiedTokens(res.data.items);
                    setUnverifiedPages(res.data.pages);
                    setSpinner(false);
                }
            });
    };

    const getRequestedTokenUpdateDetails = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/tokenupdaterequest`, {
                params: {
                    limit: requestedPageSize,
                    page: requestedCurrentPage,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setRequestedTokens(res.data.items);
                    setRequestedPages(res.data.pages);
                    setSpinner(false);
                }
            });
    };

    const verifiedTokeCsvReport = {
        filename: "Verified-Tokens-list.csv",
        data: verifiedTokeCsv,
    };

    const unverifiedTokeCsvReport = {
        filename: "Unverified-Tokens-list.csv",
        data: unverifiedTokeCsv,
    };

    const onUpload = () => {
        toast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded", life: 3000 });
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setDeleteProductsDialog2(false);
    };

    const hideVerifyAndDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setVerifyAndDeleteProductDialog(false);
    };

    const hideDeleteTokenDialog = () => {
        setDeleteId("");
        setDeleteTokenDialog(false);
    };

    const hideDeleteRequesterTokenDialog = () => {
        setDeleteId("");
        setDeleteTokenDialog(false);
        setDeleteRequesterImg("");
        setDeleteRequesterTokenDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = () => {
        setSubmitted(true);

        if (product.name.trim()) {
            let _products = [...products];
            let _product = { ...product };
            if (product.id) {
                const index = findIndexById(product.id);
                _products[index] = _product;
                toast.current.show({ severity: "success", summary: "Successful", detail: "Product Updated", life: 3000 });
            } else {
                _product.id = createId();
                _product.image = "product-placeholder.svg";
                _products.push(_product);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Product Created", life: 3000 });
            }

            setProducts(_products);
            setProductDialog(false);
            setProduct(emptyProduct);
        }
    };

    const editProduct = (product) => {
        //setProduct({ ...product });
        setProductDialog(true);
    };

    const confirmDeleteProduct = (product) => {
        // setProduct(product);
        setVerificationId(product._id);
        setVerificationStatus(product.isVerified);
        setDeleteProductDialog(true);
    };
    const confirmVerifyAndDeleteProduct = (product) => {
        // setProduct(product);
        setVerificationId(product._id);
        setVerificationStatus(product.isVerified);
        setContractAddress(product.contractaddress);
        setVerifyAndDeleteProductDialog(true);
    };
    const setRequestedDeleteTokenId = (id, image) => {
        // setProduct(product);
        setDeleteId(id);
        setDeleteRequesterImg(image);
        setDeleteRequesterTokenDialog(true);
    };
    const confirmDeleteProduct2 = (product) => {
        setProduct(product);
        setDeleteProductsDialog2(true);
    };

    const deleteProduct = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/tokens/changestatus/${verificationId}`, {
                params: {
                    status: verificationStatus ? "false" : "true",
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setVerificationId("");
                    setDeleteProductDialog(false);
                    getVerifiedToken();
                    getUnverifiedToken();
                    toast.current.show({ severity: "success", summary: "Success", detail: "Token status updated.", life: 3000 });
                }
            });
    };

    const VerifyAndDeleteProduct = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/updatetokeninfo/${verificationId}`, {
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setVerifyAndDeleteProductDialog(false);
                    setVerificationId("");
                    getRequestedTokenUpdateDetails();
                    getVerifiedToken();
                    toast.current.show({ severity: "success", summary: "Success", detail: "Token info updated.", life: 3000 });
                }
            });
    };

    const deleteToken = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "explorerToken", submethod: "delete", key: apikey, id: deleteId, deleteimg: deleteImg }).then((res) => {
            if (res.data.error) {
                toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
            } else {
                setDeleteId("");
                toast.current.show({ severity: "success", summary: "Success", detail: res.data.success, life: 3000 });
                setDeleteTokenDialog(false);
                getVerifiedToken();
                getUnverifiedToken();
            }
        });
    };

    const deleteRequesterToken = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/deletetokenrequest/${deleteId}`, {
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setDeleteRequesterTokenDialog(false);
                    setDeleteId("");
                    getRequestedTokenUpdateDetails();
                    toast.current.show({ severity: "success", summary: "Success", detail: "Token request deleted.", life: 3000 });
                }
            });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    };

    const logoImageBodyTemplate = (rowData) => {
        return <img src={rowData?.logo ? process.env.REACT_APP_IMAGE_TOKENLOGO + rowData.logo : "assets/layout/images/48.png"} height={32} width={32} />;
    };
    const verifyActionsBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    label="Unverify"
                    className="p-button-danger mr-2"
                    onClick={() => {
                        setDeleteProductDialog(true);
                        setVerificationStatus(true);
                        setVerificationId(rowData.address);
                    }}
                />
                <Link to={`/token-details/${rowData.address}`}>
                    <Button label="Details" className="p-button-info mr-2" />
                </Link>
            </div>
        );
    };

    const unverifyActionsBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    label="Verify"
                    className="p-button-success mr-2"
                    onClick={() => {
                        setDeleteProductDialog(true);
                        setVerificationStatus(false);
                        setVerificationId(rowData.address);
                    }}
                />
                <Link to={`/token-details/${rowData.address}`}>
                    <Button label="Details" className="p-button-info mr-2" onClick={openNew} />
                </Link>
            </div>
        );
    };

    const requestedActionsBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    label="Verify"
                    className="p-button-success mr-2"
                    onClick={() => {
                        setVerifyAndDeleteProductDialog(true);
                        setVerificationId(rowData.address);
                    }}
                />
                <Button
                    label="Delete"
                    className="p-button-danger mr-2"
                    onClick={() => {
                        setDeleteId(rowData.address);
                        setDeleteRequesterTokenDialog(true);
                    }}
                />
                <Link to={`/token-requested-details/${rowData.address}`}>
                    <Button label="Detalils" className="p-button-info mr-2" onClick={openNew} />
                </Link>
            </div>
        );
    };

    const deleteProductDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            {/* <Button label="Unverify" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} /> */}
            {verificationStatus ? <Button label="Unerify" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} /> : <Button label="Verify" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />}
        </>
    );
    const verifyAndDeleteProductDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideVerifyAndDeleteProductDialog} />
            {verificationStatus ? <Button label="Unerify" icon="pi pi-check" className="p-button-text" onClick={VerifyAndDeleteProduct} /> : <Button label="Verify" icon="pi pi-check" className="p-button-text" onClick={VerifyAndDeleteProduct} />}
        </>
    );

    const deleteTokenDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTokenDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteToken} />
        </>
    );

    const deleteRequesterTokenDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRequesterTokenDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteRequesterToken} />
        </>
    );

    return !checkRoles ? (
        <LoadingSpinner />
    ) : checkRoles?.filter((item) => item.id === "0001_006").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <Toast ref={toast} />
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel className="p-0" header="Verified Tokens">
                    <div className="grid TokenList-demo">
                        <div className="col-12">
                            <div className="card">
                                {/* <div className="table-container">
                                    <table className="table" cellPadding={0} cellSpacing={0}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Logo</th>
                                                <th>Name</th>
                                                <th>TotalSupply</th>
                                                <th>Decimal</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {verifiedTokens && verifiedTokens?.length
                                                ? verifiedTokens?.map((item, index) => {
                                                    const ind = (verifiedCurrentPage * verifiedPageSize) - verifiedPageSize + (index + 1);
                                                    return (
                                                        <tr>
                                                            <td>{ind}</td>
                                                            <td>
                                                                <img src={process.env.REACT_APP_IMAGE_TOKENLOGO + item.logo} height={32} width={32} />
                                                            </td>
                                                            <td>{item.name}</td>
                                                            <td>{item.totalSupply}</td>
                                                            <td>{item.decimals}</td>
                                                            <td>
                                                                <Button
                                                                    label="Unverify"
                                                                    className="p-button-danger mr-2"
                                                                    onClick={() => {
                                                                        setDeleteProductDialog(true);
                                                                        setVerificationStatus(true);
                                                                        setVerificationId(item.address);
                                                                    }}
                                                                />
                                                                <Link to={`/token-details/${item.address}`}>
                                                                    <Button label="Details" className="p-button-info mr-2" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : ""}
                                        </tbody>
                                    </table>
                                    {spinner ? <LoadingSpinner /> : verifiedTokens && verifiedTokens?.length ? "" : <EmptyDataMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />}
                                </div> */}
                                <PageHeader title="Tokens" />
                                <DataTable value={verifiedTokens} dataKey="id" loading={spinner} className="datatable-responsive" emptyMessage="No data found." responsiveLayout="scroll">
                                    <Column field="ind" header="#" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="logo" header="Logo" body={logoImageBodyTemplate} headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="name" header="Name" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="totalSupply" header="TotalSupply" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="decimals" header="Decimal" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column header="Actions" body={verifyActionsBodyTemplate} headerStyle={{ minWidth: "10rem" }}></Column>
                                </DataTable>
                                <Pagination currentPage={verifiedCurrentPage} setCurrentPage={setVerifiedCurrentPage} pageSize={verifiedPageSize} setPageSize={setVerifiedPageSize} pages={verifiedPages} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel className="p-0" header="Unverified Tokens">
                    <div className="grid TokenList-demo">
                        <div className="col-12">
                            <div className="card">
                                {/* <div className="table-container">
                                    <table className="table" cellPadding={0} cellSpacing={0}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Logo</th>
                                                <th>Name</th>
                                                <th>TotalSupply</th>
                                                <th>Decimal</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {unverifiedTokens && unverifiedTokens?.length
                                                ? unverifiedTokens?.map((item, index) => {
                                                    const ind = (unverifiedCurrentPage * unverifiedPageSize) - unverifiedPageSize + (index + 1);
                                                    return (
                                                        <tr>
                                                            <td>{ind}</td>
                                                            <td>
                                                                <img src={process.env.REACT_APP_IMAGE_TOKENLOGO + item.logo} height={32} width={32} />
                                                            </td>
                                                            <td>{item.name}</td>
                                                            <td>{item.totalSupply}</td>
                                                            <td>{item.decimals}</td>
                                                            <td>
                                                                <Button
                                                                    label="Verify"
                                                                    className="p-button-success mr-2"
                                                                    onClick={() => {
                                                                        setDeleteProductDialog(true);
                                                                        setVerificationStatus(false);
                                                                        setVerificationId(item.address);
                                                                    }}
                                                                />
                                                                <Link to={`/token-details/${item.address}`}>
                                                                    <Button label="Details" className="p-button-info mr-2" onClick={openNew} />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : ""}
                                        </tbody>
                                    </table>
                                    {spinner ? <LoadingSpinner /> : unverifiedTokens && unverifiedTokens?.length ? "" : <EmptyDataMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />}
                                </div> */}
                                <PageHeader title="Tokens" />
                                <DataTable value={unverifiedTokens} dataKey="id" loading={spinner} className="datatable-responsive" emptyMessage="No data found." responsiveLayout="scroll">
                                    <Column field="ind" header="#" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="logo" header="Logo" body={logoImageBodyTemplate} headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="name" header="Name" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="totalSupply" header="TotalSupply" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="decimals" header="Decimal" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column header="Actions" body={unverifyActionsBodyTemplate} headerStyle={{ minWidth: "10rem" }}></Column>
                                </DataTable>

                                <Pagination currentPage={unverifiedCurrentPage} setCurrentPage={setUnverifiedCurrentPage} pageSize={unverifiedPageSize} setPageSize={setVerifiedPageSize} pages={verifiedPages} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel className="p-0" header="Requests">
                    <div className="grid TokenList-demo">
                        <div className="col-12">
                            <div className="card">
                                {/* <div className="table-container">
                                    <table className="table" cellPadding={0} cellSpacing={0}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Logo</th>
                                                <th>Name</th>
                                                <th>TotalSupply</th>
                                                <th>Decimal</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {requestedTokens && requestedTokens?.length
                                                ? requestedTokens?.map((item, index) => {
                                                    const ind = (requestedCurrentPage * requestedPageSize) - requestedPageSize + (index + 1);
                                                    return (
                                                        <tr>
                                                            <td>{ind}</td>
                                                            <td>
                                                                <img src={process.env.REACT_APP_IMAGE_TOKENLOGO + item.logo} height={32} width={32} />
                                                            </td>
                                                            <td>{item.name}</td>
                                                            <td>{item.totalSupply}</td>
                                                            <td>{item.decimals}</td>
                                                            <td>
                                                                <Button
                                                                    label="Verify"
                                                                    className="p-button-success mr-2"
                                                                    onClick={() => {
                                                                        setVerifyAndDeleteProductDialog(true);
                                                                        setVerificationId(item.address);
                                                                    }}
                                                                />
                                                                <Button
                                                                    label="Delete"
                                                                    className="p-button-danger mr-2"
                                                                    onClick={() => {
                                                                        setDeleteId(item.address);
                                                                        setDeleteRequesterTokenDialog(true);
                                                                    }}
                                                                />
                                                                <Link to={`/token-requested-details/${item.address}`}>
                                                                    <Button label="Detalils" className="p-button-info mr-2" onClick={openNew} />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : ""}
                                        </tbody>
                                    </table>
                                    {spinner ? <LoadingSpinner /> : requestedTokens && requestedTokens?.length ? "" : <EmptyDataMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />}
                                </div> */}
                                <PageHeader title="Tokens" />
                                <DataTable value={requestedTokens} dataKey="id" loading={spinner} className="datatable-responsive" emptyMessage="No data found." responsiveLayout="scroll">
                                    <Column field="ind" header="#" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="logo" header="Logo" body={logoImageBodyTemplate} headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="name" header="Name" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="totalSupply" header="TotalSupply" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column field="decimals" header="Decimal" headerStyle={{ minWidth: "8rem" }}></Column>
                                    <Column header="Actions" body={requestedActionsBodyTemplate} headerStyle={{ minWidth: "10rem" }}></Column>
                                </DataTable>
                                <Pagination currentPage={requestedCurrentPage} setCurrentPage={setRequestedCurrentPage} pageSize={requestedPageSize} setPageSize={setRequestedPageSize} pages={requestedPages} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </TabView>

            <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Warning" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {product && <span>Are you sure want to {verificationStatus ? "Unverify" : "Verify"}?</span>}
                </div>
            </Dialog>
            <Dialog visible={verifyAndDeleteProductDialog} style={{ width: "450px" }} header="Warning" modal footer={verifyAndDeleteProductDialogFooter} onHide={hideVerifyAndDeleteProductDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {product && <span>Are you sure want to Verify?</span>}
                </div>
            </Dialog>

            <Dialog visible={deleteRequesterTokenDialog} style={{ width: "450px" }} header="Warning" modal footer={deleteRequesterTokenDialogFooter} onHide={hideDeleteRequesterTokenDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {product && <span>Are you sure want to Delete?</span>}
                </div>
            </Dialog>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TokenList, comparisonFn);
