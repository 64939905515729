import React, { useState, useEffect, useContext, useRef } from 'react';
import AuthContext from "../AuthProvider";
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProductService } from '../service/ProductService';
import PageHeader from "./common/PageHeader";
import Pagination from './common/Pagination';
import { Paginator } from 'primereact/paginator';
import { Image } from "primereact/image";
import axios from "axios";

const WalletList = () => {

    document.title = "Wallets List";
    const { checkRoles, verifyAndGetUser, formatDate, formatHash, stringWihtDot } = useContext(AuthContext);
    const { encuserpass } = verifyAndGetUser();

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);

    const [first, setFirst] = useState(0);
    // const [rows, setRows] = useState(10);

    const onPageChange = (event) => {
        setFirst(event.first);
        // setRows(event.rows);
        setPageSize(event.rows)
        setCurrentPage(event.page + 1);


    };

    const [walletsData, setWalletsData] = useState([]);
    const [spinner, setSpinner] = useState(true);


    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);


    const getWalletsList = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}admin/allwallet`, {
                params: {
                    limit: pageSize,
                    page: currentPage,
                },
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getChainList : ", res.data.error);
                } else {
                    setWalletsData(res.data.items);
                    setPages(res.data.pages);
                    setTotalRecords(res.data.total);
                    setSpinner(false);
                }
            });
    };

    useEffect(() => {
        getWalletsList();
    }, [currentPage, pageSize]);

    useEffect(() => {
    }, []);



    const lastloginBodyTemplate = (rowData) => {

        return formatDate(rowData.lastlogin)
    }

    const walletAddressBodyTemplate = (rowData) => {
        return <a href={`${process.env.REACT_APP_EXPLORER_URL}address/${rowData.address}`} target="_blank"> {rowData.address}</a>
    }



    return (
        <div className="grid crud-demo">

            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <PageHeader title="Wallets List" />
                    <DataTable value={walletsData} dataKey="id" loading={spinner} className="datatable-responsive"
                        globalFilter={globalFilter} emptyMessage="No data found." responsiveLayout="scroll">

                        <Column field="ind" header="No." headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>

                        <Column field="address" header="Address" body={walletAddressBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                        <Column field="lastlogin" header="Last Login" body={lastloginBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>

                    </DataTable>
                    <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} pages={pages} />
                    {/* <Paginator first={first} rows={pageSize} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} /> */}

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(WalletList, comparisonFn);