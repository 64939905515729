import React, { useState, useEffect, useRef, useContext } from "react";

import { Button } from "primereact/button";

import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { PickList } from "primereact/picklist";

import { validateEmail } from "../service/rejexValidateion";
import AuthContext from "../AuthProvider";

import axios from "axios";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../service/LoadingSpinner";

const Roles = () => {
    document.title = "Roles";

    const history = useHistory();

    const { checkRoles, roles, genTokenWithTstemp } = useContext(AuthContext);

    const toast = useRef(null);
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [conPassword, setConPassword] = useState("");

    const [errRoles, setErrRoles] = useState("");
    const [errPassword, setErrPassword] = useState("");
    const [errMatchPassword, setErrMatchPassword] = useState("");
    const [errEmail, setErrEmail] = useState("");

    const menulist = [];
    useEffect(() => {
        roles.map((mainitems) => {
            mainitems.items.map((item) => {
                if (item.id === "0001_006") {
                    menulist.push({ name: "Tokens(Explorer)", label: item.label, id: item.id, icon: item.icon, to: item.to });
                } else if (item.id === "0002_004") {
                    menulist.push({ name: "Tokens(Wallet)", label: item.label, id: item.id, icon: item.icon, to: item.to });
                } else if (item.id === "0001_003") {
                    menulist.push({ name: "Users(Explorer)", label: item.label, id: item.id, icon: item.icon, to: item.to });
                } else if (item.id === "0002_001") {
                    menulist.push({ name: "Users(Wallet)", label: item.label, id: item.id, icon: item.icon, to: item.to });
                } else if (item.id === "0001_001") {
                    menulist.push({ name: "Dashboard(Explorer)", label: item.label, id: item.id, icon: item.icon, to: item.to });
                } else if (item.id === "0002_008") {
                    menulist.push({ name: "Dashboard(Wallet)", label: item.label, id: item.id, icon: item.icon, to: item.to });
                } else {
                    menulist.push({ name: item.label, label: item.label, id: item.id, icon: item.icon, to: item.to });
                }
            });
        });
        setPicklistSourceValue(menulist);
    }, [roles]);

    // roles.map((mainitems) => {
    //     mainitems.items.map((item) => {
    //         if (item.id === "0001_006") {
    //             menulist.push({ name: "Tokens(Explorer)", label: item.label, id: item.id, icon: item.icon, to: item.to });
    //         } else if (item.id === "0002_004") {
    //             menulist.push({ name: "Tokens(Wallet)", label: item.label, id: item.id, icon: item.icon, to: item.to });
    //         } else if (item.id === "0001_003") {
    //             menulist.push({ name: "Users(Explorer)", label: item.label, id: item.id, icon: item.icon, to: item.to });
    //         } else if (item.id === "0002_001") {
    //             menulist.push({ name: "Users(Wallet)", label: item.label, id: item.id, icon: item.icon, to: item.to });
    //         } else if (item.id === "0001_001") {
    //             menulist.push({ name: "Dashboard(Explorer)", label: item.label, id: item.id, icon: item.icon, to: item.to });
    //         } else if (item.id === "0002_008") {
    //             menulist.push({ name: "Dashboard(Wallet)", label: item.label, id: item.id, icon: item.icon, to: item.to });
    //         } else {
    //             menulist.push({ name: item.label, label: item.label, id: item.id, icon: item.icon, to: item.to });
    //         }
    //     });
    // });

    const createUser = async () => {
        let temp = 1;
        if (!validateEmail(email)) {
            temp = 0;
            setErrEmail("Invalid email address.");
        } else {
            setErrEmail("");
        }
        if (picklistTargetValue.length == 0) {
            temp = 0;
            setErrRoles("select atleast one role.");
        } else {
            setErrRoles("");
        }
        if (password.length < 8) {
            temp = 0;
            setErrPassword("Enter atleast 8 charachter in password.");
        } else {
            setErrPassword("");
        }
        if (conPassword.length < 8) {
            temp = 0;
            setErrMatchPassword("Enter atleast 8 charachter in confirm password.");
        } else {
            if (password !== conPassword) {
                temp = 0;
                setErrMatchPassword("Pasword and Confirm Password are not match.");
            } else {
                setErrMatchPassword("");
            }
        }
        if (temp === 1) {
            const encbody = genTokenWithTstemp({ username: username, password: password, email: email, roles: picklistTargetValue, superadmin: false });
            await axios.post(`${process.env.REACT_APP_API_URL}admin/signup`, { token: encbody }).then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                    toast.current.show({ severity: "error", summary: "Error!", detail: res.data.error, life: 3000 });
                } else {
                    toast.current.show({ severity: "success", summary: "Success!", detail: "Admin user create successfuly.", life: 3000 });
                }
            });
            // await axios.post(process.env.REACT_APP_API_URL, { method: "admin", submethod: "insert", key: apikey, email: email, username: username, password: password, roles: picklistTargetValue }).then((res) => {
            //     if (res.data) {
            //         if (res.data.success) {
            //             toast.current.show({ severity: "success", summary: "Successful", detail: "Name Tab saved", life: 3000 });
            //             setEmail("");
            //             setUsername("");
            //             setPassword("");
            //             setConPassword("");
            //             history.push("/admin-list");
            //         } else if (res.data.error) {
            //             toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
            //         }
            //     }
            // });
        }
    };

    const [picklistSourceValue, setPicklistSourceValue] = useState([]);
    const [picklistTargetValue, setPicklistTargetValue] = useState([]);

    return !checkRoles ? (
        <LoadingSpinner />
    ) : checkRoles?.filter((item) => item.id === "0003_002").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <Toast ref={toast} />
            <div className="card mdb">
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card p-fluid">
                            <h5>
                                <b>Create Admin</b>
                            </h5>
                            <div className="field">
                                <label htmlFor="email">Email *</label>
                                <InputText id="email" value={email} type="text" required onChange={(e) => setEmail(e.target.value)} />
                                {errEmail && <p className="p-1 err-mess">{errEmail}</p>}
                            </div>
                            <div className="field">
                                <label htmlFor="username">Username *</label>
                                <InputText id="username" value={username} type="text" required onChange={(e) => setUsername(e.target.value)} />
                            </div>
                            <div className="field">
                                <label htmlFor="password">Password *</label>
                                <InputText id="password" value={password} type="password" required onChange={(e) => setPassword(e.target.value)} />
                                {errPassword && <p className="p-1 err-mess">{errPassword}</p>}
                            </div>
                            <div className="field">
                                <label htmlFor="conpassword">Confirm Password *</label>
                                <InputText id="conpassword" value={conPassword} type="password" required onChange={(e) => setConPassword(e.target.value)} />
                                {errMatchPassword && <p className="p-1 err-mess">{errMatchPassword}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <h5>Roles</h5>
                            <PickList
                                source={picklistSourceValue}
                                target={picklistTargetValue}
                                sourceHeader="From"
                                targetHeader="To"
                                itemTemplate={(item) => <div>{item.name}</div>}
                                onChange={(e) => {
                                    setPicklistSourceValue(e.source);
                                    setPicklistTargetValue(e.target);
                                }}
                                sourceStyle={{ height: "200px" }}
                                targetStyle={{ height: "200px" }}
                            ></PickList>
                        </div>
                        {errRoles && <p className="p-1 err-mess">{errRoles}</p>}
                        <div className="field grid">
                            <div className="col-12 md:col-12 text-right">
                                <Button type="button" label="Create User" onClick={createUser}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Roles, comparisonFn);
