import React, { useState, useEffect, useRef, useContext } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProductService } from "../service/ProductService";

import { useHistory } from "react-router-dom";
// import { UserRoles } from '../App';
import { validateEmail } from "../service/rejexValidateion";

import { Calendar } from "primereact/calendar";

import { FileUpload } from "primereact/fileupload";

import { validateUrl } from "../service/rejexValidateion";
import LoadingSpinner from "../service/LoadingSpinner";
import AuthContext from "../AuthProvider";
import { CSVLink } from "react-csv";

import axios from "axios";
const apikey = process.env.REACT_APP_KEY;

const AdvertisementList = () => {
    const { checkRoles, roles } = useContext(AuthContext);

    const history = useHistory();
    // const roles = useContext(UserRoles);

    // if(!roles || roles?.filter(item => item.id === "0001_004").length === 0){
    //     history.push("/unauthorized");
    // }

    const [file, setFile] = useState("");

    const [name, setName] = useState("");
    const [startdate, setStartDate] = useState("");
    const [enddate, setEndDate] = useState("");
    const [website, setWebsite] = useState("");

    const [updateFile, setUpdateFile] = useState("");

    const [updateId, setUpdateId] = useState("");
    const [updateName, setUpdateName] = useState("");
    const [updateStartdate, setUpdateStartDate] = useState("");
    const [updateEnddate, setUpdateEndDate] = useState("");
    const [updateWebsite, setUpdateWebsite] = useState("");

    // error states
    const [errWebsite, setErrWebsite] = useState("");
    const [updateErrWebsite, setUpdateErrWebsite] = useState("");

    const [loading, setLoading] = useState(true);

    const [deleteId, setDeleteId] = useState("");

    const [csvAllData, setCsvAllData] = useState(null);

    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [updateProductDialog, setUpdateProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        // const productService = new ProductService();
        // productService.getProducts().then(data => setProducts(data));
        getAdvertisementsList();
    }, []);

    const getAdvertisementsList = async () => {
        await axios.post(process.env.REACT_APP_API_URL, { method: "advertisement", submethod: "get", key: apikey }).then((res) => {
            if (res?.data.error) {
                // toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.error, life: 3000 });
                // console.log(res.data);
            } else {
                // toast.current.show({ severity: 'success', summary: 'Successful', detail: res.data.success, life: 3000 });
                // console.log(res.data);
                setProducts(res.data);
                setLoading(false);
                var csvData = new Array();
                for (let i = 0; i < res.data.length; i++) {
                    csvData.push({ ind: res.data[i].id, name: res.data[i].name, startdate: res.data[i].strdate, enddate: res.data[i].enddate, image: res.data[i].image, website: res.data[i].website });
                }
                setCsvAllData(csvData);
            }
        });
    };

    const csvReport = {
        filename: "advertisements-list.csv",
        data: csvAllData,
    };

    const handleUploadChange = (e) => {
        setFile(e.files[0]);
    };
    const handleUpdateUploadChange = (e) => {
        setUpdateFile(e.files[0]);
    };

    const onUpload = () => {
        toast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded", life: 3000 });
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };

    const hideDialog = () => {
        clearStates();
        setSubmitted(false);
        setProductDialog(false);
    };

    const updateHideDialog = () => {
        // setSubmitted(false);
        setUpdateProductDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const clearStates = () => {
        setName("");
        setStartDate("");
        setEndDate("");
        setWebsite("");
        setFile("");

        setUpdateId("");
        setUpdateName("");
        setUpdateStartDate("");
        setUpdateEndDate("");
        setUpdateWebsite("");
        setUpdateFile("");
    };

    const saveProduct = async () => {
        // setSubmitted(true);

        // if (product.name.trim()) {
        //     let _products = [...products];
        //     let _product = { ...product };
        //     if (product.id) {
        //         const index = findIndexById(product.id);

        //         _products[index] = _product;
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
        //     }
        //     else {
        //         _product.id = createId();
        //         _product.image = 'product-placeholder.svg';
        //         _products.push(_product);
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
        //     }

        //     setProducts(_products);
        //     setProductDialog(false);
        //     setProduct(emptyProduct);
        // }

        const formData = new FormData();

        //this will pass as file data
        formData.append("file", file);

        formData.append("method", "advertisement");
        formData.append("submethod", "insert");
        formData.append("key", apikey);
        formData.append("name", name);
        formData.append("strdate", startdate);
        formData.append("enddate", enddate);
        formData.append("website", website);

        let temp = 1;
        if (!validateUrl(website)) {
            setErrWebsite("invalid url.");
            temp = 0;
        } else {
            setErrWebsite("");
        }
        if (temp === 1) {
            await axios
                .post(process.env.REACT_APP_API_URL, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    if (res?.data?.error) {
                        toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                    } else if (res?.data) {
                        clearStates();
                        setProductDialog(false);
                        toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
                        getAdvertisementsList();
                    }
                });
        }
    };
    const updateProduct = async () => {
        // setSubmitted(true);

        // if (product.name.trim()) {
        //     let _products = [...products];
        //     let _product = { ...product };
        //     if (product.id) {
        //         const index = findIndexById(product.id);

        //         _products[index] = _product;
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
        //     }
        //     else {
        //         _product.id = createId();
        //         _product.image = 'product-placeholder.svg';
        //         _products.push(_product);
        //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
        //     }

        //     setProducts(_products);
        //     setProductDialog(false);
        //     setProduct(emptyProduct);
        // }
        const formData = new FormData();

        //this will pass as file data
        formData.append("file", updateFile);

        formData.append("method", "advertisement");
        formData.append("submethod", "update");
        formData.append("key", apikey);
        formData.append("file", updateFile);
        formData.append("name", updateName);
        formData.append("id", updateId);
        formData.append("strdate", updateStartdate);
        formData.append("enddate", updateEnddate);
        formData.append("website", updateWebsite);

        let temp = 1;
        if (!validateUrl(updateWebsite)) {
            setUpdateErrWebsite("invalid url.");
            temp = 0;
        } else {
            setUpdateErrWebsite("");
        }
        if (temp === 1) {
            await axios
                .post(process.env.REACT_APP_API_URL, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    if (res?.data?.error) {
                        toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                    } else if (res?.data) {
                        clearStates();
                        setUpdateProductDialog(false);
                        toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
                        getAdvertisementsList();
                    }
                });
        }
    };

    const editProduct = (product) => {
        //setProduct({ ...product });
        setUpdateId(product._id);
        setUpdateName(product.name);
        setUpdateStartDate(product.strdate);
        setUpdateEndDate(product.enddate);
        setUpdateWebsite(product.website);
        setUpdateProductDialog(true);
    };

    const confirmDeleteProduct = (product) => {
        setDeleteId(product._id);
        setProduct(product);
        setDeleteProductDialog(true);
    };

    const deleteProduct = async () => {
        // let _products = products.filter(val => val.id !== product.id);
        // setProducts(_products);
        // setDeleteProductDialog(false);
        // setProduct(emptyProduct);
        // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });

        await axios.post(process.env.REACT_APP_API_URL, { method: "advertisement", submethod: "delete", key: apikey, id: deleteId }).then((res) => {
            if (res.data) {
                if (res?.data?.error) {
                    toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                } else if (res?.data?.error) {
                    toast.current.show({ severity: "error", summary: "Error", detail: res.data.error, life: 3000 });
                } else {
                    setDeleteId("");
                    setDeleteProductDialog(false);
                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.success, life: 3000 });
                    getAdvertisementsList();
                }
            }
        });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Add Advertisement" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    };
    const startDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {moment(rowData.strdate).format("DD-MM-YYYY, hh:mm:ss a")}
                {/* {rowData.strdate} */}
            </>
        );
    };
    const endDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {moment(rowData.enddate).format("DD-MM-YYYY, hh:mm:ss a")}
                {/* {rowData.enddate} */}
            </>
        );
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={rowData.image ? process.env.REACT_APP_ADVERTISEMENT_IMAGE + rowData.image : "public/images/users/user-6.jpg"} alt={rowData.image} className="shadow-2 imground" width="50" />
            </>
        );
    };
    const websiteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                <a href={rowData.website} target="_blank">
                    {rowData.website}
                </a>
            </>
        );
    };

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    };

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    };

    const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                <Rating value={rowData.rating} readonly cancel={false} />
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Advertisement List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                {csvAllData ? (
                    <CSVLink className="p-button p-component p-button-secondary p-2 m-1" {...csvReport}>
                        .CSV
                    </CSVLink>
                ) : (
                    ""
                )}
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const productUpdateDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={updateHideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return !checkRoles || checkRoles?.filter((item) => item.id === "0001_004").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <>
            <div className="grid AdvertisementList-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                        <DataTable
                            ref={dt}
                            value={products}
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={globalFilter}
                            emptyMessage={loading ? <LoadingSpinner /> : "data not found."}
                            header={header}
                            responsiveLayout="scroll"
                        >
                            <Column field="id" header="Sr No." sortable body={codeBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="name" header="Name" sortable body={nameBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="start_date" header="Start Date" sortable body={startDateBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="end_date" header="End Date" sortable body={endDateBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column header="Image" body={imageBodyTemplate} headerStyle={{ width: "14%", minWidth: "7rem" }}></Column>
                            <Column field="website" header="Website" body={websiteBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>
                            <Column field="action" header="Action" body={actionBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        </DataTable>

                        <Dialog visible={productDialog} style={{ width: "450px" }} header="Advertisement" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            <div className="field">
                                <label htmlFor="name">Name *</label>
                                <InputText
                                    id="name"
                                    type="name"
                                    value={name}
                                    required
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="startdate">Start Date *</label>
                                <Calendar inputId="calendar" name="startdate" value={startdate} onChange={(e) => setStartDate(e.value)} required showIcon></Calendar>
                            </div>
                            <div className="field">
                                <label htmlFor="enddate">End Date *</label>
                                <Calendar inputId="calendar" name="enddate" value={enddate} onChange={(e) => setEndDate(e.value)} required showIcon></Calendar>
                            </div>
                            <div className="field">
                                <label htmlFor="image">Select Image *</label>
                                <div className="card">
                                    <FileUpload mode="basic" name="image" customUpload={true} url="./assets" auto="true" accept="image/*" maxFileSize={2000000} uploadHandler={handleUploadChange} />
                                    {/* <FileUpload mode="basic" name="image" accept="image/*" maxFileSize={2000000} onUpload={onUpload} /> */}
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="website">Website *</label>
                                <InputText id="website" value={website} type="text" required onChange={(e) => setWebsite(e.target.value)} />
                                {errWebsite && <p className="p-1 err-mess">{errWebsite}</p>}
                            </div>
                        </Dialog>

                        {/* edit product dialog */}
                        <Dialog visible={updateProductDialog} style={{ width: "450px" }} header="Edit Advertisement" modal className="p-fluid" footer={productUpdateDialogFooter} onHide={updateHideDialog}>
                            <div className="field">
                                <label htmlFor="name">Name *</label>
                                <InputText
                                    id="name"
                                    type="name"
                                    value={updateName}
                                    required
                                    onChange={(e) => {
                                        setUpdateName(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="startdate">Start Date *</label>
                                <Calendar inputId="calendar" name="startdate" value={updateStartdate} onChange={(e) => setUpdateStartDate(e.target.value)} required showIcon></Calendar>
                            </div>
                            <div className="field">
                                <label htmlFor="enddate">End Date *</label>
                                <Calendar inputId="calendar" name="enddate" value={updateEnddate} onChange={(e) => setUpdateEndDate(e.value)} required showIcon></Calendar>
                            </div>
                            <div className="field">
                                <label htmlFor="image">Select Image *</label>
                                <div className="card">
                                    <FileUpload mode="basic" name="image" customUpload={true} url="./assets" auto="true" accept="image/*" maxFileSize={2000000} uploadHandler={handleUpdateUploadChange} />
                                    {/* <FileUpload mode="basic" name="image" accept="image/*" maxFileSize={2000000} onUpload={onUpload} /> */}
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="website">Website *</label>
                                <InputText id="website" value={updateWebsite} type="text" required onChange={(e) => setUpdateWebsite(e.target.value)} />
                                {updateErrWebsite && <p className="p-1 err-mess">{updateErrWebsite}</p>}
                            </div>
                        </Dialog>

                        <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {product && <span>Are you sure you want to delete?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AdvertisementList, comparisonFn);
