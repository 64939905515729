import React, { useContext, useState, useEffect, useRef } from "react";

import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Timeline } from "primereact/timeline";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import AuthContext from "../AuthProvider";

import axios from "axios";

const apikey = process.env.REACT_APP_KEY;

const BlockchainUpdate = () => {
    document.title = "Blockchain Timeline";

    const { checkRoles, genTokenWithTstemp, verifyAndGetUser } = useContext(AuthContext);
    const { encuserpass } = verifyAndGetUser();
    const toast = useRef(null);

    const [productDialog, setProductDialog] = useState(false);
    const [updateProductDialog, setUpdateProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);

    const [deleteid, setDeleteId] = useState("");
    const [updateid, setUpdateId] = useState("");

    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [errTitle, setErrTitle] = useState("");
    const [errText, setErrText] = useState("");

    const [updateTitle, setUpdateTitle] = useState("");
    const [updateText, setUpdateText] = useState("");

    const [errUpdateTitle, setErrUpdateTitle] = useState("");
    const [errUpdateText, setErrUpdateText] = useState("");

    const [blockchainData, setBlockchainData] = useState(null);

    const openAddTimelineDialog = () => {
        setProductDialog(true);
    };

    const customEvents = [
        { status: "Ordered", date: "15/10/2020 10:30", icon: "pi pi-shopping-cart", color: "#9C27B0", image: "game-controller.jpg" },
        { status: "Processing", date: "15/10/2020 14:00", icon: "pi pi-cog", color: "#673AB7" },
        { status: "Shipped", date: "15/10/2020 16:15", icon: "pi pi-shopping-cart", color: "#FF9800" },
        { status: "Delivered", date: "16/10/2020 10:00", icon: "pi pi-check", color: "#607D8B" },
    ];

    useEffect(() => {
        getBlockchainUpdate();
    }, []);

    const getBlockchainUpdate = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}/admin/blockchain`, {
                headers: {
                    etoken: encuserpass,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getUserList : ", res.data.error);
                } else {
                    setBlockchainData(res.data);
                }
            });
    };

    const saveProduct = async () => {
        try {
            let temp = 1;
            if (!title) {
                setErrTitle("Title is require");
                temp = 0;
            } else {
                setErrTitle("");
            }
            if (!text) {
                setErrText("Text is require");
                temp = 0;
            } else {
                setErrText("");
            }
            if (temp && updateid) {
                const encbody = genTokenWithTstemp({ title: title, text: text });
                await axios
                    .post(
                        `${process.env.REACT_APP_API_URL}admin/blockchain/insert`,
                        { token: encbody },
                        {
                            headers: {
                                etoken: encuserpass,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data.error) {
                            console.log("error in updateMaintenanceData (catch) : ", res.data.error);
                            toast.current.show({ severity: "error", summary: "Error!", detail: res.data.error, life: 3000 });
                        } else {
                            toast.current.show({ severity: "success", summary: "Success!", detail: res.data.message, life: 3000 });
                            hideDialog();
                            getBlockchainUpdate();
                        }
                    })
                    .catch((err) => console.log("error in updateMaintenanceData (catch) : ", err));
            }
        } catch (err) {
            console.log("error : ", err);
        }
    };

    const editProduct = (id, title, text) => {
        setUpdateId(id);
        setUpdateTitle(title);
        setUpdateText(text);
        setUpdateProductDialog(true);
    };

    const handelDelete = (id) => {
        setDeleteId(id);
        setDeleteProductDialog(true);
    };

    const deleteProduct = async () => {
        try {
            if (deleteid) {
                const encbody = genTokenWithTstemp({ id: deleteid });
                await axios
                    .post(
                        `${process.env.REACT_APP_API_URL}admin/blockchain/delete`,
                        { token: encbody },
                        {
                            headers: {
                                etoken: encuserpass,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data.error) {
                            console.log("error in updateMaintenanceData (catch) : ", res.data.error);
                            toast.current.show({ severity: "error", summary: "Error!", detail: res.data.error, life: 3000 });
                        } else {
                            toast.current.show({ severity: "success", summary: "Success!", detail: res.data.message, life: 3000 });
                            hideDeleteProductDialog();
                            getBlockchainUpdate();
                        }
                    })
                    .catch((err) => console.log("error in updateMaintenanceData (catch) : ", err));
            } else {
                toast.current.show({ severity: "error", summary: "Error!", detail: "Delete address not found.", life: 3000 });
            }
        } catch (err) {
            console.log("error", err);
        }
    };
    const updateProduct = async () => {
        try {
            let temp = 1;
            if (!updateTitle) {
                setErrUpdateTitle("Title is require");
                temp = 0;
            } else {
                setErrUpdateTitle("");
            }
            if (!updateText) {
                setErrUpdateText("Text is require");
                temp = 0;
            } else {
                setErrUpdateText("");
            }
            if (temp) {
                const encbody = genTokenWithTstemp({ id: updateid, title: updateTitle, text: updateText });
                await axios
                    .post(
                        `${process.env.REACT_APP_API_URL}admin/blockchain/update`,
                        { token: encbody },
                        {
                            headers: {
                                etoken: encuserpass,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data.error) {
                            console.log("error in updateMaintenanceData (catch) : ", res.data.error);
                            toast.current.show({ severity: "error", summary: "Error!", detail: res.data.error, life: 3000 });
                        } else {
                            toast.current.show({ severity: "success", summary: "Success!", detail: res.data.message, life: 3000 });
                            updateHideDialog();
                            getBlockchainUpdate();
                        }
                    })
                    .catch((err) => console.log("error in updateMaintenanceData (catch) : ", err));
            }
        } catch (err) {
            console.log("error : ", err);
        }
    };

    const hideDialog = () => {
        setText("");
        setTitle("");
        setErrText("");
        setErrTitle("");
        setProductDialog(false);
    };
    const updateHideDialog = () => {
        setUpdateId("");
        setUpdateText("");
        setUpdateTitle("");
        setErrUpdateText("");
        setErrUpdateTitle("");
        setUpdateProductDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const customizedContent = (item) => {
        return (
            <Card title={item.title} subTitle={new Date(item.createdAt).toLocaleString()}>
                <p>{item.text}</p>
                <Button label="Edit" className="p-button p-component p-button-raised p-button-info p-button-text mx-2" onClick={() => editProduct(item._id, item.title, item.text)}></Button>
                <Button label="Delete" className="p-button p-component p-button-raised p-button-danger p-button-text" onClick={() => handelDelete(item._id)}></Button>
            </Card>
        );
    };

    const customizedMarker = (item) => {
        return (
            <span className="d-flex align-items-center justify-content-center text-white border-circle z-1 shadow-2" style={{ backgroundColor: "#00ecc7", borderRadius: "50%", padding: "5px" }}>
                <span className="flex w-1rem h-1rem align-items-center justify-content-center" style={{ backgroundColor: "#ffffff", border: "none", borderRadius: "50%" }}>
                    {" "}
                </span>
            </span>
        );
    };

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const updateProductDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={updateHideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateProduct} />
        </>
    );

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );

    return !checkRoles || checkRoles?.filter((item) => item.id === "0005_002").length === 0 ? (
        <h4>You don't have access to this resource.</h4>
    ) : (
        <div className="grid timeline-demo">
            <div className="col-12">
                <Toast ref={toast} />
                <div className="card">
                    <div className="flex justify-content-between mb-3">
                        <h4>Timeline</h4>
                        <Button label="Add Timeline" icon="pi pi-plus" className="p-button-success" onClick={openAddTimelineDialog} />
                    </div>
                    {/* <h5>Custom Timeline</h5> */}
                    <Timeline value={blockchainData} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />

                    <Dialog visible={productDialog} style={{ width: "450px" }} header="Add Timeline" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="title">Title</label>
                            <InputText id="title" value={title} type="text" onChange={(e) => setTitle(e.target.value)} />
                            {errTitle && <p className="p-1 err-mess">{errTitle}</p>}
                        </div>
                        <div className="test">
                            <label htmlFor="text">Text</label>
                            <InputTextarea id="text" value={text} rows="4" onChange={(e) => setText(e.target.value)} />
                            {errText && <p className="p-1 err-mess">{errText}</p>}
                        </div>
                    </Dialog>
                    <Dialog visible={updateProductDialog} style={{ width: "450px" }} header="Edit Timeline" modal className="p-fluid" footer={updateProductDialogFooter} onHide={updateHideDialog}>
                        <div className="field">
                            <label htmlFor="uptitle">Title</label>
                            <InputText id="uptitle" value={updateTitle} type="text" onChange={(e) => setUpdateTitle(e.target.value)} />
                            {errUpdateTitle && <p className="p-1 err-mess">{errUpdateTitle}</p>}
                        </div>
                        <div className="field">
                            <label htmlFor="uptext">Text</label>
                            <InputTextarea id="uptext" value={updateText} rows="4" onChange={(e) => setUpdateText(e.target.value)} />
                            {errUpdateText && <p className="p-1 err-mess">{errUpdateText}</p>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {<span>Are you sure you want to delete?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BlockchainUpdate, comparisonFn);
